<template>
  <div id="app">
    <div class="main-wrapper">
      <Loader :isVisible="isLoading" />
      <AlertPopup :callsData="calls" :importData="isMessage.message || {}" />
      <ImportLoader :isMessage="Object.values(isMessage).length ? isMessage : isImport ? isImport : {}" />
      <router-view />
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Loader from '../src/views/layouts/index-loader.vue';
import AlertPopup from '../src/views/layouts/AlertPopup.vue';
import ImportLoader from '../src/views/layouts/Loader.vue';
import { connectSocket, disconnectSocket, joinRoom, onCallAlert, onImportCompleted } from './socket';
export default {
  components: { Loader, AlertPopup, ImportLoader },
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.isLoading);
    const isImport = ref(JSON.parse(localStorage.getItem('import')));
    const isMessage = computed(() => store.state.importMessage);
    const calls = ref([]);
    const token = computed(() => store.getters['auth/isAuthenticated']);

    watch(token, (newToken, oldToken) => {
      if (newToken) {
        connectSocket();
        joinRoom();

        onCallAlert((callData) => {
          // await store.dispatch('importMessage',{status:false,message:null})
          calls.value = callData;
        });
      }
    }, { immediate: true });

    onUnmounted(() => {
      disconnectSocket();
    });

    return { isLoading, calls, isMessage, isImport };
  }
}
</script>
