<template>
  <div class="col-xl-4">
    <!-- Settings Sidebar -->
    <div class="card">
      <div class="card-body">
        <div class="settings-sidebar">
          <h4>All Dropdowns</h4>
          <ul class="dropdown-container">
            <li v-for="(value, key) in dropDowns" :key="key">
              <button class="btn btn-link"  :class="{'text-danger': dropdownKey === key}"  :data-name="key" :data-action="value" @click="fetchDropdownFields(key,value)">
                {{ value }}
              </button>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <!-- /Settings Sidebar -->
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default {
  props: {
    fetchDropdownFields: {
      type: Function,
      default: false,
    },
    dropdownKey:{
      type:String,
      default:false
    }
  },
  data() {
    return {
      title: "Dashboard",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const dropDowns = ref()
    const fetchDropdownData = async () => {
      const response = await store.dispatch('auth/fetchDropdowns');
      if (response.status) {
        dropDowns.value = response.data;
      }
    }

    onMounted(async () => {
      await fetchDropdownData()
    })

    return {
      dropDowns,
      fetchDropdownData
    }


  }
};
</script>

<style scoped>
.dropdown-container {
  max-height: 100%;
  overflow-y: auto;
  padding: 0.5rem;
  border-radius: 4px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link:active {
  color: red; 
}
</style>