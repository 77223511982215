<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <!-- Page Header -->
                    <page-header :title="title" />
                    <!-- /Page Header -->

                    <!-- Settings Menu -->
                    <settings-tabs></settings-tabs>
                    <!-- /Settings Menu -->

                    <div class="row">
                        <dropdown-sidebar :fetchDropdownFields="fetchDropdownFields"
                            :dropdownKey="dropdownKey"></dropdown-sidebar>

                        <div class="col-xl-8">
                            <!-- Settings Info -->
                            <div class="card">
                                <div class="card-body py-0">
                                    <div class="settings-form">
                                        <Form @submit="saveProfile">
                                            <div class="profile-details">
                                                <div class="col-md-12 ">
                                                    <div class="my-3 px-1" style="font-size: 16px; font-weight: 600;">
                                                        {{ dropdownValue }}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div v-for="(field, index) in formConfig" :key="index"
                                                        class="col-md-12">
                                                        <div class="form-wrap row">
                                                            <!-- <div class="col-md-4">
                                                                <label :for="field.value" class="col-form-label">
                                                                    {{ field.value }}
                                                                </label>
                                                            </div> -->
                                                            <div class="col-md-7">
                                                                <Field :name="field.value" as="input" type="text"
                                                                    v-model="formData[field.value]"
                                                                    class="form-control" />
                                                                <ErrorMessage :name="field.value" class="text-danger" />
                                                                <span class="text-danger"
                                                                    v-if="isSubmit && !formData[field.value]">{{
                                                                    errorMessageRequired }} </span>
                                                                <span class="text-danger"
                                                                    v-if="isSubmit && duplicateError.includes(formData[field.value])">{{
                                                                    errorMessageDuplicate }} </span>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <a @click="deleteRow(index)"><i
                                                                        class="btn btn-light ion-close-round text-danger"
                                                                        data-bs-toggle="tooltip"
                                                                        title="Delete Row"></i></a>
                                                            </div>
                                                            <div v-if="index === formConfig.length - 1"
                                                                class="col-md-2">
                                                                <a @click="AddRow(index)"><i
                                                                        class="btn btn-light text-success"
                                                                        data-bs-toggle="tooltip" title="Add Row">Add
                                                                        Dropdown</i></a>
                                                            </div>
                                                            <!-- ion-plus text-success -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit-button text-end mb-2">
                                                <button type="submit" class="btn btn-primary">Save Changes</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <!-- /Settings Info -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import * as Yup from "yup";
import apiClient from "@/axios";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            title: "Settings",
        };
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const dropDownList = computed(() => store.getters['auth/dropdowns'] || []);


        const dropdownKey = ref("")
        const dropdownValue = ref("")

        // Dynamic form data object
        const formData = ref({});

        const formConfig = ref({});

        const isSubmit = ref(false)

        const errorMessageRequired = ref("")
        const errorMessageDuplicate = ref("")

        const duplicateError = ref([])

        const saveProfile = async () => {
            try {
                isSubmit.value = true;

                // Check for empty values
                const values = Object.values(formData.value);
                if (values.includes('')) {
                    errorMessageRequired.value = "Please Enter Value"
                    return false;
                }

                // Check for duplicate values
                const seen = new Set();
                const duplicates = new Set();

                for (const value of values) {
                    if (seen.has(value)) {
                        duplicates.add(value);
                    } else {
                        seen.add(value);
                    }
                }

                if (duplicates.size > 0) {
                    duplicateError.value = [...duplicates];
                    errorMessageDuplicate.value = "Please enter unique values"
                    return false;
                }

                duplicateError.value =[]

                const data = { ...formData.value };
                const updatedData = Object.entries(formData.value).map(([key, value], index) => {
                    if (key === `value_${index}`) {
                        data[value] = value
                        delete data[key]
                    }
                });
                // Dispatch the updated data
                await store.dispatch('auth/updateDropdowns', {
                    ...data,
                    dropdownKey: dropdownKey.value,
                });

                // Re-fetch the dropdown fields to reflect changes
                await fetchDropdownFields(dropdownKey.value, dropdownValue.value);
            } catch (error) {
                console.error("Error saving profile:", error);
            }
        }


        const fetchDropdownFields = async (key, value) => {
            if (key === undefined || value === undefined) {
                key = 'org_account_status'
                dropdownKey.value = 'org_account_status'
                dropdownValue.value = 'Organisation Account Status'
            }
            else {
                dropdownKey.value = key
                dropdownValue.value = value
            }
            const response = await store.dispatch('auth/fetchFields', key);

            formConfig.value = response.data;

            setTimeout(() => {
                formData.value =
                    dropDownList.value.reduce((acc, field) => {
                        acc[field.value || 'value'] = field.text || "text";
                        return acc;
                    }, {})
            }, 100);

            window.scrollTo({ top: 0, behavior: "smooth" });

        };


        const AddRow = () => {
            isSubmit.value = false
            const newField = { text: "text", value: `value_${formConfig.value.length}` };
            formConfig.value = [...formConfig.value, newField];

            // Dynamically add the new field to formData
            formData.value = {
                ...formData.value,
                [newField.value]: ""
            };

        };

        const deleteRow = (index) => {
            const fieldToRemove = formConfig.value[index].value;

            // Remove from formConfig
            formConfig.value.splice(index, 1);

            // Remove from formData
            const { [fieldToRemove]: removed, ...rest } = formData.value;
            formData.value = rest;
        };

        onMounted(async () => {
            await fetchDropdownFields();

        });

        return {
            fetchDropdownFields,
            saveProfile,
            formData,
            formConfig,
            dropdownKey,
            dropDownList,
            AddRow,
            deleteRow,
            dropdownValue,
            isSubmit,
            errorMessageRequired,
            duplicateError,
            errorMessageDuplicate
        };
    },
};
</script>
<style>
.nav-tabs .nav-link {
    border: none !important;
}

.nav-tabs .nav-link.active {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 3px solid red !important;
    color: red !important;
}
</style>