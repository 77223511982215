// src/store/modules/org.js
import apiClient from '@/axios';
import { message } from 'ant-design-vue';

const state = {
  reportData: {},
  reportLists: [],
  reportDropdowns: [],
  reportDetails:{
  },
  generateReport:[],
  moduleData:[]
};

const getters = {
  getReportData: (state) => state.reportData,
  getReportLists: (state) => state.reportLists,
  getReportDropdowns:(state) => state.reportDropdowns,
  getReportDetails:(state)=> state.reportDetails,
  getGenerateReport:(state)=> state.generateReport,
  getModuleData:(state)=>state.moduleData
};

const mutations = {
  SET_REPORT_LISTS(state, data) {
    state.reportLists = data;
  },

  SET_DROPDOWN_OPTIONS(state, data) {
    state.reportDropdowns = data;
  },

  SET_REPORT_DATA(state, data) {
    state.reportData = data;
  },

  SET_REPORT_DETAILS(state, data) {
    state.reportDetails = data;
  },
  SET_GENERATE_DETAILS(state, data) {
    state.generateReport = data;
  },
  SET_MODULE_DATA(state,data){
    state.moduleData = data
  }
};

const actions = {
  async fetchReportLists({ commit }, params) {
    try {
      const response = await apiClient.get('/report/list', {params});
      commit('SET_REPORT_LISTS', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching report list:', error);
    }
  },

  async fetchReportData({ commit }, id) {
    try {
      const response = await apiClient.get(`/report/view/${id}`);
      commit('SET_REPORT_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  },

  async fetchModuleData({ commit }, id) {
    try {
      const response = await apiClient.get(`/report/module/${id}/fields`);
      commit('SET_MODULE_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  },

  async fetchDropdownOptions({ commit }) {
    try {
      const response = await apiClient.get('report/settings');
      if(response){
        commit('SET_DROPDOWN_OPTIONS', response.data.data);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching types data:', error);
    }
  },


  async saveReportData({ commit }, formData) {
    try {
        
      let response;
      const { module_fields, ...restData } = formData;
      if (formData.id) {
        response = await apiClient.put(
          `/report/update/${formData.id}`,
          restData, {
        }
        );
      } else {
        response = await apiClient.post(
          '/report/create',
          formData
        );
      }
      message.success(response.data.message);
      return response.data;
    } catch (error) {
      message.error(error.response.data.message);
      console.error('Error saving reports data:', error);
      return { success: false, errors: error };
    }
  },

  async deleteReportData({ commit }, id) {
    try {
      const response = await apiClient.delete(`/report/delete/${id}`);
      return response.data
    } catch (error) {
      console.error('Error deleting report data:', error);
    }
  },

  async saveReportDetails({ commit }, data) {
    try {
      commit('SET_REPORT_DETAILS', data);
      return data;
    } catch (error) {
      console.error('Error in saving report details:', error);
    }
  },

  async generateReport({ commit }, data) {
    try {
      const response = await apiClient.post(`/report/${data.id}/generate`,{filters:data.modifiedData});
      commit('SET_GENERATE_DETAILS', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error  in downloading report:', error);
    }
  },

  async downloadReport({ commit }, data) {
    try {
      const response = await apiClient.post(`/report/${data.reportId}/download`,{columns:data.columns, filters:data.modifiedData});
      return response.data;
    } catch (error) {
      console.error('Error  in downloading report:', error);
      return error.response.data;
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
