<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <!-- Page Header -->
                    <page-header :title="title" />
                    <!-- /Page Header -->

                    <!-- Settings Menu -->
                    <settings-tabs></settings-tabs>
                    <!-- /Settings Menu -->

                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="contact-head">
                                <div class="row align-items-center">
                                    <div class="col-sm-6">
                                        <ul class="contact-breadcrumb">
                                            <li>
                                                <router-link to="/supplier/supplier-list"><i
                                                        class="ti ti-arrow-narrow-left"></i>List</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- Company Settings -->
                            <div class="card">
                                <div class="card-body settings-form">
                                    <div class="row">
                                        <div class="col-md-5 col-sm-4">
                                            <h4>{{ isEditing ? 'Edit Supplier' : 'Create Supplier' }}</h4>
                                        </div>
                                        <div class="col-md-7 col-sm-8">
                                        </div>
                                    </div>
                                    <Form @submit="registerSupplier">
                                        <div class="settings-sub-header">
                                        </div>
                                        <div class="profile-details">
                                            <div class="row" style="align-items: center">
                                                <!-- Supplier Name -->
                                                <div class="col-md-6">
                                                    <div class="form-wrap">
                                                        <label class="col-form-label">Supplier Name <span
                                                                class="text-danger">*</span></label>
                                                        <Field name="name" as="input" type="text" class="form-control"
                                                            v-model="form.name" />
                                                        <!-- <ErrorMessage name="name" class="text-danger" /> -->
                                                        <p class="text-danger" v-if="isSubmit?.name">
                                                            {{ isSubmit?.name }}
                                                        </p>
                                                        <span class="text-danger" v-if="
                                                            apiErrors &&
                                                            apiErrors['name'] &&
                                                            apiErrors['name'].length
                                                        ">
                                                            {{ apiErrors["name"][0] }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <!-- Gas Checkbox -->
                                                <div class="col-md-3">
                                                    <div class="form-wrap">
                                                        <input type="checkbox" class="form-check-input me-2" id="gas"
                                                            :true-value="1" :false-value="0" v-model="form.gas" />
                                                        <label class="form-check-label" for="gas">Gas</label>
                                                        <p class="text-danger" v-if="isSubmit?.gasElec">
                                                            {{ isSubmit?.gasElec }}
                                                        </p>
                                                    </div>
                                                </div>

                                                <!-- Electricity Checkbox -->
                                                <div class="col-md-3">
                                                    <div class="form-wrap">
                                                        <input type="checkbox" class="form-check-input me-2"
                                                            id="electricity" :true-value="1" :false-value="0"
                                                            v-model="form.electricity" />
                                                        <label class="form-check-label"
                                                            for="electricity">Electricity</label>
                                                        <p class="text-danger" v-if="isSubmit?.gasElec">
                                                            {{ isSubmit?.gasElec }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="submit-button text-end">
                                            <router-link class="btn btn-light" to="/supplier/supplier-list">
                                                Back</router-link>
                                            <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' :
                                                'Create' }}</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <!-- /Company Settings -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    onMounted,
} from "vue";
import apiClient from "@/axios";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import {
    Form,
    Field,
    ErrorMessage,
} from "vee-validate";
import * as Yup from "yup";
import {
    message
} from "ant-design-vue";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        Multiselect
    },
    data() {
        return {
            title: "Settings",
        };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const isEditing = ref(false);
        if (route.query.userId) {
            isEditing.value = true;
        }
        const apiErrors = ref(null);

        const schema = Yup.object().shape({
            name: Yup.string()
                .required("Supplier name is required"),
        });

        const form = ref({
            id: null,
            name: '',
            gas: 0,
            electricity: 0
        });

        const isSubmit = ref({})

        const validateForm = () => {
            // Reset isSubmit.value to an empty object
            isSubmit.value = {};

            // Validate "name"
            if (!form.value.name.trim()) {
                isSubmit.value = { ...isSubmit.value,isValid:false,name: 'Name is required.' };
            }

            // Validate "gas" and "electricity"
            if (!form.value.gas && !form.value.electricity) {
                isSubmit.value = { ...isSubmit.value, gasElec: 'At least one of Gas or Electricity is required.' };
            }
        };

        const fetchSupplierData = async (id) => {
            try {
                const response = await apiClient.get(`/suppliers/view/${id}`);
                Object.assign(form.value, response.data.data);
            } catch (error) {
                console.error('Error fetching Supplier data:', error);
            }
        };
        const registerSupplier = async () => {
            validateForm()
            if (Object.values(isSubmit.value).length > 0) {
                return;
            }

            const data = await store.dispatch("users/createSupplier", form.value);
            if (data.status === 200) {
                isSubmit.value = true;
                message.success(data.data.message)
                router.push('/supplier/supplier-list');
            } else if (!data.status)
                apiErrors.value = data.errors;
        };

        onMounted(async () => {
            // await store.dispatch('opportunity/fetchOpportunityOptions');
            const userId = route.query.userId;
            if (userId) {
                fetchSupplierData(userId);
            }
            // fetchResponsibles();
        });

        return {
            schema,
            isEditing,
            form,
            registerSupplier,
            apiErrors,
            isSubmit
        };
    },
};
</script>

<style>
.profile-upload-img {
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-selectors {
    display: flex;
    gap: 10px;
}

.color-field {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    transition: border-color 0.3s;
}

.color-field:hover {
    border-color: #ccc;
}

.color-field .checkmark {
    font-size: 20px;
    color: white;
}

.color-field.selected {
    border-color: #000;
}
</style>