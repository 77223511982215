<template>
    <div class="row" v-if="user.roles[0] === 'company_admin'">
        <div class="col d-flex">
            <ul class="d-flex" v-if="visibleUsers.length > 0">
                <!-- Display the first 4 initials with names -->
                <li class="selected-initial me-1" v-for="user in visibleUsers" :key="user.id">
                    {{ user.initials.slice(0, 3).toUpperCase() }}
                </li>
                <li class="remaining-users-count me-1" v-if="remainingUsers.length > 0" @mouseover="showTooltip = true"
                    @mouseleave="showTooltip = false">
                    <span class="">
                        +{{ remainingUsers.length }}
                        <div v-if="showTooltip" class="dropdown-content">
                            <ul class="user_list p-0">
                                <li class="dropdown-item border-bottom d-flex" v-for="user in remainingUsers"
                                    :key="user.id">
                                    <span class="selected-initial label-initial me-2">{{
                                        user.initials.slice(0, 3).toUpperCase()
                                    }}</span> ({{
                                            user.first_name }} {{ user.last_name }})
                                </li>
                            </ul>
                        </div>
                    </span>
                </li>
            </ul>
            <div v-if="userData.length > 0" class="dropdown ms-1">
                <button class="dropdown-toggle user_drop_btn" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    +<i class="ion-person"></i>
                </button>
                <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                    <div class="border-bottom p-2">
                        <input type="text" class="form-control" placeholder="Search..." v-model="searchUser" />
                    </div>
                    <ul class="user_list">
                        <!-- Select All Checkbox -->
                        <li class="dropdown-item border-bottom d-flex">
                            <input type="checkbox" id="select-all" :checked="isAllSelected" @change="toggleSelectAll" />
                            <label class="ms-2 text-capitalize d-flex" for="select-all"><span
                                    class="selected-initial label-initial me-2">AL</span>
                                Select All</label>
                        </li>
                        <!-- User List -->
                        <li v-for="user in filteredUsers" :key="user.id" class="dropdown-item border-bottom d-flex">
                            <input type="checkbox" :value="user.id" :id="user.id" v-model="checkedIds"
                                @change="handleCheckboxClick()" />
                            <label class="ms-2 text-capitalize d-flex" :for="user.id">
                                <span class="selected-initial label-initial me-2">{{
                                    user.initials.slice(0, 3).toUpperCase() }}</span>
                                {{ user.first_name }} {{ user.last_name }}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    onMounted,
    computed
} from 'vue';
import {
    useStore
} from "vuex";

export default {
    props: {
        from: {
            type: String,
        },
    },
    setup(props, {emit}) {
        const store = useStore();
        const searchUser = ref("");
        const userData = ref([]);
        const checkedIds = ref([]);
        const visibleUsers = computed(() => selectedUsers.value.slice(0, 4));
        const remainingUsers = computed(() => selectedUsers.value.slice(4));
        const user = computed(() => store.getters['auth/user']);
        const showTooltip = ref(false);
        const isAllSelected = computed(() => checkedIds.value.length === filteredUsers.value.length);
        const selectedUsers = computed(() => {
            return checkedIds.value
                .map((id) => {
                    return userData.value.find((u) => u.id === id);
                })
                .filter((user) => user !== undefined);
        });
        const filteredUsers = computed(() => {
            if (!searchUser.value) return userData.value;
            return userData.value.filter((user) =>
                user.first_name?.toLowerCase().includes(searchUser.value.toLowerCase()) ||
                user.last_name?.toLowerCase().includes(searchUser.value.toLowerCase())
            );
        });

        const fetchUsers = async () => {
            try {
                const params = {
                    allUsers: 1
                }
                const response = await store.dispatch('users/fetchUsers', params);
                if (response.status) {
                    userData.value = response.data.data;
                    const staticOption = {
                        id: 'none', // Ensure a unique ID for the static option
                        initials: 'No',
                        first_name: 'None',
                        last_name: '',
                    };
                    userData.value.unshift(staticOption);
                } else {
                    console.error("Failed to fetch users:", response);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        const toggleSelectAll = async () => {
            if (isAllSelected.value) {
                checkedIds.value = [];
            } else {
                checkedIds.value = filteredUsers.value.map((user) => user.id);
            }
            await handleCheckboxClick();
        };
        const handleCheckboxClick = async () => {
            // const type = props.from;
            const response = await store.dispatch('users/getUserFilterData', {
                responsibles: checkedIds.value,  // Pass the checked IDs as 'params'
                type: props.from,                 // Pass the type
            });
            if (response.status) {
                await getUsersFilters()
            }
        };
        const getUsersFilters = async () => {
            // const type = 'organisation'
            const getUsers = await store.dispatch('users/getUsersFilters', props.from);
            if (getUsers.status) {
                checkedIds.value = getUsers.data.responsibles
                emit('filterData')
            }
        }
        onMounted(async () => {
            await store.dispatch('auth/loadUserFromLocalStorage');
            await fetchUsers();
            await getUsersFilters();
        });
        return {
            fetchUsers,
            getUsersFilters,
            searchUser,
            visibleUsers,
            remainingUsers,
            selectedUsers,
            checkedIds,
            filteredUsers,
            user,
            userData,
            showTooltip,
            isAllSelected,
            toggleSelectAll,
            handleCheckboxClick
        }
    }
};
</script>
<style scoped lang="scss">
.remaining-users-count {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    height: 35px;
    width: 35px;
    background-color: lightgrey;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: gray;
}

.user_drop_btn {
    border: 1px solid #02a8b5;
    height: 35px;
    width: 35px;
    background-color: #02a8b5;
    border-radius: 50%;
    color: #fff;
}

.label-initial {
    height: 20px !important;
    width: 20px !important;
    font-size: 10px;
}

.selected-initial {
    height: 35px;
    width: 35px;
    background-color: #02a8b521;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #02a8b5;
    font-weight: bold;
}

.dropdown-menu.show {
    width: 250px;
}

ul.user_list {
    max-height: 295px;
    overflow-x: hidden;

}

.dropdown-content {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    white-space: nowrap;
    width: 250px;
}

.dropdown .dropdown-toggle:after {
    content: '';
}
</style>