import { createStore } from 'vuex';
import auth from './modules/auth';
import organisation from './modules/organisation';
import users from './modules/users';
import person from './modules/person';
import opportunity from './modules/opportunity';
import contract from './modules/contract';
import activities from './modules/activities';
import dataimport from './modules/dataimport';
import dashboard from './modules/dashboard';
import price from './modules/price';
import report from './modules/report';
import leads from './modules/leads';

export default createStore({
  modules: {
    auth,
    organisation,
    users,
    person,
    opportunity,
    contract,
    activities,
    dataimport,
    dashboard,
    price,
    report,
    leads

  },

  state: {
    isLoading: false,
    importMessage:{}
  },

  mutations: {
    SHOW_LOADER(state) {
      state.isLoading = true;
    },
    HIDE_LOADER(state) {
      state.isLoading = false;
    },
    IMPORT_MESSAGE(state,data){
      state.importMessage = data;
    }
  },

  actions: {
    showLoader({ commit }) {
      commit('SHOW_LOADER');
    },
    hideLoader({ commit }) {
      commit('HIDE_LOADER');
    },
    logout({ commit }) {
      localStorage.removeItem('authToken');
      // Optionally, you can clear other user-related data here
    },
    importMessage({ commit }, message) {
      commit('IMPORT_MESSAGE',message);
    },
  },
});
