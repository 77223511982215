<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <!-- Prefixes -->
              <div class="card">
                <div class="card-body settings-form">
                  <div class="settings-header">
                    <website-sidebar :file_id="file_id" :mapping_id="mapping_id"></website-sidebar>
                  </div>
                  <form @submit.prevent="validateRequiredFields" v-if="Object.keys(moduleArrays).length > 0">
                    <div class="profile-details">
                      <!-- Loop through each module in moduleArrays -->
                      <div class="mb-3" v-for="(moduleFields, moduleName) in moduleArrays" :key="moduleName">
                        <div class="settings-sub-header mb-0">
                          <div class="row align-items-center bg-teal">
                            <div class="col-4 py-1">
                              <h6 class="text-white text-capitalize">
                                {{ moduleName }}
                              </h6>
                              <p class="text-white">
                                Provide the basic information below
                              </p>
                            </div>
                            <div class="col-4 py-1">
                              <h6 class="text-white">Select File Columns</h6>
                            </div>
                            <div class="col-4 py-1">
                              <h6 class="text-white">
                                Set this value for all items
                              </h6>
                            </div>
                          </div>
                        </div>

                        <!-- Loop through fields in the module -->
                        <div class="row align-items-center border-bottom py-2 mx-0"
                          v-for="(orgs, fieldIndex) in moduleFields" :key="orgs.id">
                          <!-- Column AA -->
                          <div class="col-md-4">
                            <div class="setting-title mb-0">
                              <div class="row">
                                <div class="col-9">
                                  <h6>
                                    {{ `${moduleName.charAt(0).toUpperCase() + moduleName.slice(1).toLowerCase()} / ${orgs.field_name}` }}
                                    <span v-if="orgs.mandatory === 1" class="text-danger">*</span>
                                  </h6>
                                </div>
                                <div class="col-3">
                                  <!-- Show 'Required' if empty on submit -->
                                  <p v-if="
                                    showRequiredError &&
                                    orgs.mandatory === 1 &&
                                    !sameValuesMapping[moduleName][
                                    fieldIndex
                                    ] &&
                                    !fileColumnsMapping[moduleName][
                                    fieldIndex
                                    ]
                                  " class="text-danger">
                                    Required
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Column BB -->
                          <div class="col-md-4">
                            <div class="form-wrap mb-0">
                              <vue-select :options="formattedValues" :key="formattedValues.id" :id="formattedValues.id"
                                v-model="fileColumnsMapping[moduleName][fieldIndex]" placeholder="Choose field ....."
                                @select="myChangeEvent($event, moduleName, fieldIndex, 'column')" />
                            </div>
                          </div>

                          <!-- Column CC -->
                          <div class="col-md-4">
                            <div class="form-wrap mb-0">
                              <div v-if="
                                orgs.field_type === 'dropdown' &&
                                orgs.dropdown_options
                              ">
                                <select class="form-select" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  " v-if="
                                    orgs.field_name === 'Responsible' ||
                                    orgs.field_name === 'Assign To' ||
                                    orgs.field_name === 'Sales Responsible' ||
                                    orgs.field_name === 'Referred By' ||
                                    orgs.field_name === 'Updated By' ||
                                    orgs.field_name === 'Created By' ||
                                    orgs.field_name === 'CM Responsible'
                                  ">
                                  <option value="">-----Select-----</option>
                                  <option v-for="option in resUsers" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </option>
                                </select>
                                <select class="form-select" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  " v-else-if="orgs.field_name === 'NIC' || orgs.field_name === 'COT'">
                                  <option value="">-----Select-----</option>
                                  <option v-for="option in nicOptions" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </option>
                                </select>
                                <select class="form-select" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  " v-else-if="orgs.field_name === 'Safe To Telephone'">
                                  <option value="">-----Select-----</option>
                                  <option v-for="option in personSafeTelephone" :key="option.value"
                                    :value="option.value">
                                    {{ option.text }}
                                  </option>
                                </select>
                                <vue-select v-else-if="orgs.field_name === 'Current Supplier'"
                                  :options="formattedOptions[0]" v-model="sameValuesMapping[moduleName][fieldIndex]"
                                  placeholder="-----Select-----"
                                  @select="myChangeEvent($event, moduleName, fieldIndex, orgs.field_name)" />
                                <vue-select v-else-if="orgs.field_name === 'New Supplier'"
                                  :options="formattedOptions[1]" v-model="sameValuesMapping[moduleName][fieldIndex]"
                                  placeholder="-----Select-----"
                                  @select="myChangeEvent($event, moduleName, fieldIndex, orgs.field_name)" />
                                <select class="form-select" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  " v-else>
                                  <option value="">-----Select-----</option>
                                  <option v-for="option in orgs.dropdown_options" :key="option.value || option.id"
                                    :value="option.value || option.id">
                                    {{
                                      option.text ||
                                      option.name ||
                                      option.country_name
                                    }}
                                  </option>
                                </select>
                              </div>
                              <div v-else-if="orgs.field_type === 'text'">
                                <input type="text" class="form-control" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  " />
                              </div>

                              <div v-else-if="orgs.field_type === 'checkbox'">
                                <input type="checkbox" class="form-check" :true-value="1" :false-value="0" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  " />
                              </div>

                              <div v-else-if="orgs.field_type === 'textarea'">
                                <textarea class="form-control" v-model="sameValuesMapping[moduleName][fieldIndex]
                                  "></textarea>
                              </div>
                              <div v-else-if="orgs.field_type === 'datepicker'">
                                <div class="icon-form">
                                  <date-picker textInput placeholder="" v-model="sameValuesMapping[moduleName][fieldIndex]
                                    " class="datetimepicker form-control" :editable="true" :clearable="true"
                                    :input-format="dateFormat" />
                                  <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                </div>
                              </div>
                              <div v-else-if="
                                orgs.field_type === 'none' &&
                                orgs.field_name === 'Organisation Name'
                              ">
                                <h6 class="text-danger">Mandatory</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Submit Button -->
                    <div class="submit-button">
                      <div class="row">
                        <div class="col-md-6">
                          <!-- <a href="javascript:;" class="btn btn-light">Cancel</a> -->
                          <button type="submit" class="btn btn-primary">
                            Save Changes
                          </button>
                        </div>
                        <div class="col-md-6 text-end">
                          <!-- <button type="btn" v-if="isMapped" @click="beginImport" class="btn btn-teal">Assign Data</button> -->
                          <router-link v-if="isMapped" class="btn btn-teal" :to="{
                            path: '/data-import/assign-data',
                            query: { fileId: file_id },
                          }">
                            Assign Data</router-link>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="row" v-else>
                    <div class="col"></div>
                    <div class="col-md-7 py-3 bg-light">
                      <p class="text-center">
                        <i class="ion-information-circled"></i> Please upload
                        and validate a file
                      </p>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
              </div>
              <!-- /Prefixes -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import router from "@/router";
import {
  message
} from 'ant-design-vue';
import { changeFormate } from '../../../../util'
export default {
  data() {
    return {
      title: "Settings",
      dateFormat: "dd/MM/yyyy",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const showRequiredError = ref(false);
    const hasErrors = ref(false);
    const formattedValues = ref([])
    const formattedOptions = ref([])

    const fileColumns = ref([]);
    const mappedData = ref([]);
    const file_id = ref(route.query.fileId || localStorage.getItem('fileId') || null);

    const moduleArrays = ref({});
    const mapping_id = ref(route.query.useMapping || null);
    const isMapped = ref(false)
    const nicOptions = ref([{
      value: "YES",
      text: "YES"
    }, {
      value: "NO",
      text: "NO"
    }])
    const personSafeTelephone = ref([{
      value: "Y",
      text: "Y"
    }, {
      value: "N",
      text: "N"
    }])
    const resUsers = computed(() => store.getters['dataimport/getresUsers']);

    // Separate mappings for each module
    const fileColumnsMapping = ref({});
    const sameValuesMapping = ref({});

    const fetchMappingData = async (id) => {
      try {
        const data = await store.dispatch("dataimport/fetchMappingData", id);
        const foundObject = data.find(item => item.file_columns);

        // Extract the file_columns
        fileColumns.value = foundObject ? foundObject.file_columns : [];

        formattedValues.value = [
          { id: 'null', text: 'Choose field ....', original: null },
          ...fileColumns.value.map(item => ({
            id: item.id,
            text: item.column_name,
            original: item,
          }))
        ];

        formattedOptions.value = data[0]?.modules[1]?.module_fields
          .filter((field) => field && (field.field_name === 'Current Supplier' || field.field_name === 'New Supplier'))
          .map((field) => [
            { id: 'null', text: '-----Select-----', original: null },
            ...field.dropdown_options.map(item => ({
              id: item.id,
              text: item.name,
            })),
          ])
        // Initialize mappings for each module
        data.forEach(item => {
          if (item.modules && Array.isArray(item.modules)) {
            item.modules.forEach(module => {
              moduleArrays.value[module.name] = module.module_fields;

              // Initialize mappings with default values
              fileColumnsMapping.value[module.name] = Array(module.module_fields.length).fill(null);
              sameValuesMapping.value[module.name] = Array(module.module_fields.length).fill(null);
              if (item.mappings.length > 0 && Array.isArray(item.mappings)) {
                item.mappings.forEach(mapping => {
                  const fieldIndex = module.module_fields.findIndex(field => field.id === mapping.import_module_field_id);
                  if (fieldIndex !== -1) {
                    // Populate file column mapping
                    fileColumnsMapping.value[module.name][fieldIndex] = mapping.import_file_column_id;
                    // Populate same value mapping
                    const sameValue = mapping.same_value;
                    sameValuesMapping.value[module.name][fieldIndex] =
                      (sameValue && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(sameValue))
                        ? new Date(sameValue)
                        : sameValue || null;
                  }
                });
              } else {
                if (mappedData.value.length > 0 && Array.isArray(mappedData.value)) {
                  mappedData.value.forEach(mapping => {
                    // Find the index of the field in module.module_fields
                    const fieldIndex = module.module_fields.findIndex(
                      field => field.id === mapping.import_module_field_id
                    );
                    if (fieldIndex !== -1) {
                      // Find the corresponding ID for file_column_name from formattedValues
                      const matchedValue = formattedValues.value.find(
                        formatted => formatted.text === mapping.file_column_name
                      );
                      // Populate file column mapping with matched ID or null if not found
                      fileColumnsMapping.value[module.name][fieldIndex] = matchedValue ? matchedValue.id : null;

                      // Populate same value mapping
                      const sameValue = mapping.same_value;
                      sameValuesMapping.value[module.name][fieldIndex] =
                        sameValue && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(sameValue)
                          ? new Date(sameValue)
                          : sameValue || null;
                    }
                  });
                }
              }
            });
          }
        });
      } catch (error) {
        console.error("Error fetching dataimport data:", error);
      }
    };


    const fetchUsedMapping = async (id) => {
      const data = await store.dispatch("dataimport/fetchMappedData", id);
      mappedData.value = data;

    };
    // Function to validate required fields
    const validateRequiredFields = () => {
      hasErrors.value = false;
      showRequiredError.value = true;

      hasErrors.value = Object.entries(moduleArrays.value).some(([moduleName, moduleFields]) => {
        return moduleFields.some((field, index) => {
          const isMandatory = field.mandatory === 1;
          const isFileMapped = fileColumnsMapping.value[moduleName][index] !== undefined && fileColumnsMapping.value[moduleName][index] !== null;
          const isSameValueMapped = sameValuesMapping.value[moduleName][index] !== undefined && sameValuesMapping.value[moduleName][index] !== null;

          const isEitherColumnFilled = isFileMapped || isSameValueMapped;
          return isMandatory && !isEitherColumnFilled;
        });
      });

      if (hasErrors.value) {
        message.error('Please map at least one column for each required field.');
      } else {
        handleSubmit();
      }
    };

    const handleSubmit = async () => {
      const mappingData = [];

      Object.entries(moduleArrays.value).forEach(([moduleName, moduleFields]) => {
        moduleFields.forEach((org, index) => {
          const fileColumnId = fileColumnsMapping.value[moduleName][index];
          let sameValue = sameValuesMapping.value[moduleName][index];

          if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(sameValue)) {
            sameValue = changeFormate(sameValue);
          }

          if (fileColumnId || sameValue) {
            mappingData.push({
              module_field_id: org.id,
              file_column_id: fileColumnId || null,
              same_value_for_all: sameValue ? true : false,
              same_value: sameValue || null,
            });
          }
        });
      });

      const payload = {
        file_id: file_id.value,
        mapping_data: mappingData,
      };

      const data = await store.dispatch("dataimport/postMappingData", payload);
      if (data === 201) {
        isMapped.value = true;
      }
    };
    const beginImport = async () => {
      const data = await store.dispatch("dataimport/beginImportData", file_id.value);
      if (data.status === 200) {
        router.push('/data-import/import-data-status')
        isMapped.value = false;
      }
    }

    const myChangeEvent = (event, moduleName, fieldIndex, field_name) => {
      if (field_name === 'Current Supplier' || field_name === 'New Supplier') {
        if (event.id === 'null') {
          sameValuesMapping.value[moduleName][fieldIndex] = null;
        }
      }
      else {
        if (event.id === 'null') {
          fileColumnsMapping.value[moduleName][fieldIndex] = null;
        }
      }
    }

    onMounted(async () => {
      if (mapping_id.value) {
        fetchUsedMapping(mapping_id.value);
      }
      if (file_id.value) {
        fetchMappingData(file_id.value);
      }
      await store.dispatch('dataimport/fetchResponsibleUsers');
    });

    return {
      moduleArrays,
      fetchUsedMapping,
      mappedData,
      fileColumns,
      fileColumnsMapping,
      sameValuesMapping,
      handleSubmit,
      isMapped,
      beginImport,
      file_id,
      mapping_id,
      resUsers,
      nicOptions,
      showRequiredError,
      validateRequiredFields,
      formattedValues,
      personSafeTelephone,
      myChangeEvent,
      formattedOptions
    };
  },
};
</script>
<style scoped></style>
