<template>
    <div class="row organisation-detail mx-0 mt-2">
        <div class="col-md-12 ">
            <h4 class="pb-4 text-center">OWNER DETAILS</h4>
            <div class="row">
                <div v-if="isEditing && ownerData.length > 0" class="col-md-12 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Partner
                            List</label>
                        <Field as="select" name="partner_list" id="partner_list" v-model="ownerformData.id"
                            class="form-select" @change="onOwnerChange">
                            <option v-for="res in ownerData" :key="res.id" :value="res.id">
                                {{ res.first_name }}
                            </option>
                        </Field>
                        <ErrorMessage name="partner_list" class="text-danger" />
                    </div>
                </div>

                <div class="col-md-12 company-field">
                    <div class="form-wrap mt-4 pt-2 d-flex align-items-center">
                        <Field as="input" name="primary_partner" type="checkbox" class="form-check-input me-2"
                            id="primary_partner" :value="1" :unchecked-value="0"
                            v-model="ownerformData.primary_partner" />
                        <label class="form-check-label mb-0" for="primary_partner">Primary
                            Partner</label>
                    </div>
                    <ErrorMessage name="primary_partner" class="text-danger mt-1" />
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Name</label>
                        <Field type="text" name="owner_name" class="form-control"
                            v-model="ownerformData.first_name">
                        </Field>
                        <ErrorMessage name="owner_name" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">DOB</label>
                        <div class="icon-form">
                            <Field name="dob" v-model="dob">
                                <date-picker text-input placeholder="" class="datetimepicker form-control"
                                    v-model="ownerformData.dob" :editable="true" :clearable="true"
                                    :input-format="dateFormat" @update:modelValue="clearDate" />

                                <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                            </Field>
                            <ErrorMessage name="dob" class="text-danger" />
                        </div>

                    </div>
                </div>

                <div class="col-md-12 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">View Details
                            For</label>
                        <Field as="select" name="default_address" id="default_address"
                            v-model="ownerformData.default_address" @change="clearField" class="form-select">
                            <option v-if="!isEditing" value="">
                                -----Select-----
                            </option>
                            <option key="current_address" value="current">
                                {{ "Current Address" }}
                            </option>
                            <option key="previous_address" value="previous">
                                {{ "Previous Address" }}
                            </option>
                        </Field>
                        <ErrorMessage name="default_address" class="text-danger" />
                    </div>
                </div>
                <div v-if="ownerformData.default_address === 'previous'" class="col-md-12 company-field ">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Year At Previous
                            Address</label>
                        <Field type="text" name="years_at_current_address" class="form-control"
                            v-model="ownerformData.years_at_previous_address">
                        </Field>
                        <ErrorMessage name="years_at_current_address" class="text-danger" />
                    </div>
                </div>
                <div v-else class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Year At Current
                            Address</label>
                        <Field type="text" name="years_at_current_address" class="form-control"
                            v-model="ownerformData.years_at_current_address">
                        </Field>
                        <ErrorMessage name="years_at_current_address" class="text-danger" />
                    </div>
                </div>
                <div v-if="ownerformData.default_address === 'previous'" class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Previous Address
                            Line
                            1</label>
                        <Field type="text" name="current_address_line_1" class="form-control"
                            v-model="ownerformData.previous_address_line_1">
                        </Field>
                        <ErrorMessage name="current_address_line_1" class="text-danger" />
                    </div>
                </div>
                <div v-else class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Current Address
                            Line
                            1</label>
                        <Field type="text" name="current_address_line_1" class="form-control"
                            v-model="ownerformData.current_address_line_1">
                        </Field>
                        <ErrorMessage name="current_address_line_1" class="text-danger" />
                    </div>
                </div>
                <div v-if="ownerformData.default_address === 'previous'" class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Previous Address
                            Line
                            2</label>
                        <Field type="text" name="current_address_line_2" class="form-control"
                            v-model="ownerformData.previous_address_line_2">
                        </Field>
                        <ErrorMessage name="current_address_line_2" class="text-danger" />
                    </div>
                </div>
                <div v-else class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Current Address
                            Line
                            2</label>
                        <Field type="text" name="current_address_line_2" class="form-control"
                            v-model="ownerformData.current_address_line_2">
                        </Field>
                        <ErrorMessage name="current_address_line_2" class="text-danger" />
                    </div>
                </div>
                <div v-if="ownerformData.default_address === 'previous'" class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Previous Address
                            Line
                            3</label>
                        <Field type="text" name="current_address_line_3" class="form-control"
                            v-model="ownerformData.previous_address_line_3">
                        </Field>
                        <ErrorMessage name="current_address_line_3" class="text-danger" />
                    </div>
                </div>
                <div v-else class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Current Address
                            Line
                            3</label>
                        <Field type="text" name="current_address_line_3" class="form-control"
                            v-model="ownerformData.current_address_line_3">
                        </Field>
                        <ErrorMessage name="current_address_line_3" class="text-danger" />
                    </div>
                </div>
                <div v-if="ownerformData.default_address === 'previous'" class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Previous Address
                            Line
                            4</label>
                        <Field type="text" name="current_address_line_4" class="form-control"
                            v-model="ownerformData.previous_address_line_4">
                        </Field>
                        <ErrorMessage name="current_address_line_4" class="text-danger" />
                    </div>
                </div>
                <div v-else class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Current Address
                            Line
                            4</label>
                        <Field type="text" name="current_address_line_4" class="form-control"
                            v-model="ownerformData.current_address_line_4">
                        </Field>
                        <ErrorMessage name="current_address_line_4" class="text-danger" />
                    </div>
                </div>
                <div v-if="ownerformData.default_address === 'previous'" class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Previous
                            Postcode</label>
                        <Field type="text" name="current_postcode" class="form-control"
                            v-model="ownerformData.previous_postcode">
                        </Field>
                        <ErrorMessage name="current_postcode" class="text-danger" />
                    </div>
                </div>
                <div v-else class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Current
                            Postcode</label>
                        <Field type="text" name="current_postcode" class="form-control"
                            v-model="ownerformData.current_postcode">
                        </Field>
                        <ErrorMessage name="current_postcode" class="text-danger" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref
} from "vue";
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
        };
    },
    props: {
        settingOptions: {
            type: Object,
            default: () => { },
        },
        ownerformData: {
            type: Object,
            default: () => { },
        },
        isEditing: {
            type: Boolean,
        },
        ownerData: {
            type: Object,
            default: () => [],
        },
    },
    setup(props) {
        const dob = ref(null)
        const clearDate = () => {
            dob.value = null;
        }
        const onOwnerChange = () => {
            const selectedOwner = ownerData.value.find(
                (owner) => owner.id === ownerformData.id
            );

            if (selectedOwner) {
                Object.assign(ownerformData, selectedOwner)
                ownerformData.dob = selectedOwner.dob ? new Date(selectedOwner.dob) : null
            } else {
                Object.assign(ownerformData, {
                    id: "",
                    first_name: "",
                    dob: null,
                    primary_partner: 0,
                    default_address: "",
                    years_at_current_address: "",
                    current_address_line_1: "",
                    current_address_line_2: "",
                    current_address_line_3: "",
                    current_address_line_4: "",
                    current_postcode: "",
                    years_at_previous_address: "",
                    previous_address_line_1: "",
                    previous_address_line_2: "",
                    previous_address_line_3: "",
                    previous_address_line_4: "",
                    previous_postcode: "",
                });
            }
        };
        return {
            clearDate,
            onOwnerChange,
            dob
        };
    },
};
</script>
