<template>
  <a-table
    class="stripped table-hover"
    :columns="columns"
    :data-source="data"
    :row-selection="rowSelection"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Star'">
        <div class="d-flex align-items-center justify-content-center">
          <div class="set-star rating-select">
            <i class="fa fa-star"></i>
          </div>
        </div>
      </template>
      <template v-else-if="column.key === 'Name'">
        <h2 class="table-avatar d-flex align-items-center">
          <router-link to="/crm/report-details" class="avatar me-2"
            ><img
              class="avatar-img"
              :src="require(`@/assets/img/profiles/${record.Customer_Image}`)"
              alt="User Image"
          /></router-link>
          <router-link to="/crm/report-details" class="profile-split d-flex flex-column"
            >{{ record.Customer_Name }}
            <span>{{ record.Customer_No }} </span></router-link
          >
        </h2>
      </template>
      <template v-else-if="column.key === 'Tag'">
        <span :class="record.TagClass">{{ record.Tag }}</span>
      </template>
      <template v-else-if="column.key === 'Rating'">
        <div class="set-star">
          <span><i class="fa fa-star filled"></i> {{ record.Rating }}</span>
        </div>
      </template>
      <template v-else-if="column.key === 'Contact'">
        <ul class="social-links d-flex align-items-center">
          <li>
            <a href="javascript:;"><i class="ti ti-mail"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-phone"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-message-circle-share"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-brand-skype"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-brand-facebook"></i></a>
          </li>
        </ul>
      </template>
      <template v-else-if="column.key === 'Status'">
        <span :class="record.StatusClass">{{ record.Status }}</span>
      </template>
      <template v-else-if="column.key === 'action'">
        <div class="icon-field">
            <router-link :to="{ path: '/crm/report-edit', query: {reportId: record.key, moduleId: record.module_id }}" class="btn table_action_btn"><i class="ti ti-edit"></i> Edit</router-link>
            <a
              class="btn table_action_btn mx-2"
              href="javascript:void(0);"
              @click="deleteRecord(record.key)"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            >

            <a
              class="btn table_action_btn"
              href="javascript:void(0);"
              @click.prevent="reportView(record.key,record?.module_id,record?.dataType,record?.name)"
            >
              <i class="ti ti-eye text-blue-light"></i> Preview</a
            >
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";
import { useRouter } from "vue-router";
import apiClient from "@/axios";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import moment from "moment";

const columns = [
  {
    title: "Data Type",
    dataIndex: "dataType",
    key: "dataType",
    sorter: {
      compare: (a, b) => {
        a = a.dataType.toLowerCase();
        b = b.dataType.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Updated",
    dataIndex: "updated",
    key: "updated",
    sorter: {
      compare: (a, b) => {
        // Convert the custom date format into a valid date
        const dateA = a.updated
          ? moment(a.updated, "DD/MM/YYYY HH:mm").toDate().getTime()
          : 0;
        const dateB = b.updated
          ? moment(b.updated, "DD/MM/YYYY HH:mm").toDate().getTime()
          : 0;
        return dateA - dateB; // Ascending order
      },
    },
  },

  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
  },
];

export default {
  props: {
    searchQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const router = useRouter();
    const store = useStore();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const { proxy } = getCurrentInstance();

    const fetchReports = async () => {
      try {
        const response = await apiClient.get("/report/list", {
          params: {
            search: props.searchQuery,
            type: props.filters.type,
            name: props.filters.name,
            page: pagination.value.current,
            pageSize: pagination.value.pageSize,
          },
        });
        if (response.data.status) {
          data.value = response.data.data.reports.map((opp) => ({
            key: opp.id,
            name: opp.name,
            dataType: opp.type,
            updated: opp.updated ? formatDate(opp.updated) : null,
            module_id: opp.module_id
          }));
          pagination.value.total = response.data.data.total;
        } else {
          console.error("Failed to fetch opportunities:", response);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    };
    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchReports();
    };

    const openEditModal = (id, moduleId) => {
      router.push({
        path: "/crm/report-edit",
        query: {
          reportId: id,
          moduleId: moduleId
        },
      });
    };

    const deleteRecord = async (id) => {
      const response = await store.dispatch("report/deleteReportData", id);
      if (response.status) {
        proxy.$toast.open({
          message: response.message,
          type: "success",
          duration: 5000,
        });
        fetchReports();
      } else {
        proxy.$toast.open({
          message: response.message,
          type: "error",
          duration: 5000,
        });
      }

      router.push("/crm/reports-list");
    };

    const reportView = (id,moduleId,dataType,name) => {
      router.push({
        path: "/crm/overview/report",
        query: {
          modN:dataType,
          repN:name,
          reportId: id,
          moduleId:moduleId,
        },
      });
    };
   // Function to convert date format
    const formatDate = (dateTime) => {
      if(dateTime){
        return dateTime.replace(/-/g, '/');
      }
    };
    onMounted(() => {
      fetchReports();
    });

    return {
      data,
      formatDate,
      pagination,
      fetchReports,
      handleTableChange,
      openEditModal,
      deleteRecord,
      reportView,
    };
  },

  mounted() {
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopups = document.getElementsByClassName("edit-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close1");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
