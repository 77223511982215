<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->

        <!-- Settings Menu -->
        <settings-tabs></settings-tabs>
        <!-- /Settings Menu -->

        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <!-- Company Settings -->
            <div class="card">
              <div class="card-body settings-form">
                <div class="settings-header">
                  <website-sidebar :file_id="file_id" :mapping_id="mapping_id"></website-sidebar>
                </div>
                <form @submit.prevent="submitFile">
                  <div class="profile-details">
                    <div class="row align-items-center py-4">
                      <div class="col"></div>
                      <div class="col-md-7">
                        <div class="form-wrap">
                          <div class="profile-upload">
                            <div class="profile-upload-content w-100">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row align-items-center">
                                    <div class="col-md-3">
                                      <p>Replace current uploaded file</p>
                                    </div>
                                    <div class="col-md-5">
                                      <input type="text" class="form-control" placeholder="Max upload file size is less than 10 mb" v-model="fileName" readonly />
                                    </div>
                                    <div class="col-md-2">
                                      <label class="profile-upload-btn mb-0">
                                        <i class="ti ti-file-broken"></i> Browse File
                                        <input type="file" class="input-img" ref="fileInput" accept=".csv" @change="handleFileChange" />
                                      </label>
                                    </div>
                                    <div class="col-md-2">
                                      <button type="submit" class="btn btn-primary" :disabled="!formData.import_file">Upload</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- /Company Settings -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      title: "Settings",
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const fileName = ref(""); // To store file name
    const fileInput = ref(null); // Reference to file input
    const uploaded_fileId = ref(localStorage.getItem('fileId'));
    const file_id = ref(route.query.fileId || localStorage.getItem('fileId'));
    const mapping_id = ref(route.query.useMapping || null);
    const formData = ref({
      import_file: null,
      pending_file_id: uploaded_fileId || null,
    });

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if(selectedFile?.size > 10485760){
          message.error("Please upload file less than 10 mb")
          return null;
        }
        const fileType = selectedFile.name.split(".").pop().toLowerCase();
        if (fileType === "csv") {
          fileName.value = selectedFile.name;
          formData.value.import_file = selectedFile;
        } else {
          alert("Please upload a valid CSV file.");
          fileName.value = "";
          formData.value.import_file = null;
          fileInput.value.value = ""; // Clear file input
        }
      }
    };
    const submitFile = async () => {
      const response = await store.dispatch('dataimport/uploadImportFile', formData.value);
      if (response?.status) {
        file_id.value = response.data.file_id
        router.push({
          path: "/data-import/validate",
          query: {
            useMapping: route.query.useMapping,
            fileId: response.data.file_id
          }
        });
      } 
      // else {
      //   apiErrors.value = response.errors
      // }
    };
    onMounted(async () => {
      // fetchValidData();
      // store.dispatch('dataimport/fetchValidateData');
    });

    return {
      file_id,
      handleFileChange,
      fileName,
      fileInput,
      formData,
      submitFile,
      uploaded_fileId,
      mapping_id
    }
  }
};
</script>
