<template>
    <div class="modal fade" :class="{ show: isVisible }" tabindex="-1" aria-labelledby="exampleModalLabel"
        style="display: block" aria-modal="true" role="dialog" v-if="isVisible">
        <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="row w-100">
                        <div class="col-6">
                            <h3>Send Document</h3>
                        </div>
                        <div class="col-6 text-end">
                            <button class="btn-close" @click="closeModal">
                                <i class="ti ti-x"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <Form @submit="sendDocument" :validation-schema="schema">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-wrap">
                                        <label class="col-form-label">Email <span class="text-danger">*</span></label>
                                        <Field id="email" name="email" as="input" type="email" class="form-control"
                                            v-model="recipient_email" />
                                        <ErrorMessage name="email" class="text-danger" />
                                        <span class="text-danger"
                                            v-if="apiErrors && apiErrors['recipient_email'] && apiErrors['recipient_email'].length">{{
                                            apiErrors['recipient_email'][0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-wrap">
                                        <label class="col-form-label">Subject <span class="text-danger">*</span></label>
                                        <Field name="subject" as="input" type="text" class="form-control"
                                            v-model="form.subject" />
                                        <ErrorMessage name="subject" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!supplierTrue">
                                <div class="col-md-12">
                                    <div class="form-wrap">
                                        <label class="col-form-label">Document Type <span
                                                class="text-danger">*</span></label>
                                        <Field as="select" name="doc_type" class="form-select" v-model="form.doc_type"
                                            :disabled="supplierTrue">
                                            <option v-for="res in docTypes" :key="res.value" :value="res.value">
                                                {{ res.text }}
                                            </option>
                                        </Field>
                                        <ErrorMessage name="doc_type" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-wrap">
                                        <label class="col-form-label">Description <span
                                                class="text-danger">*</span></label>
                                        <!-- <textarea class="form-control" rows="3" v-model="form.body"></textarea> -->
                                        <Field as="textarea" name="body" class="form-control" rows="3"
                                            v-model="form.body" />
                                        <ErrorMessage name="body" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6"></div>
                                <div class="col-md-6 text-end">
                                    <button type="submit" class="btn btn-primary">Send</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    watch,
    onUnmounted,
    computed
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
import { message } from 'ant-design-vue';

export default {
    props: {
        isVisible: {
            type: Boolean,
        },
        supplierTrue: {
            type: Boolean,
        },
        suppliersData: {
            type: Object,
        },
        suppliersExcelData: {
            type: Object,
        },
        currentSupplierData: {
            type: Object,
        },
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    setup(props, {
        emit
    }) {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const apiErrors = ref(null);
        const isDropdownVisible = ref(false);
        const docTypes = ref([{
            value: "pdf",
            text: "PDF",
        },
        {
            value: "excel",
            text: "Excel",
        },
        ]);
        const recipient_email = ref('');
        const form = ref({
            subject: "",
            doc_type: "pdf",
            body: "",
        });
        const schema = Yup.object().shape({
            email: Yup.string().required("Recipient email is required").email("The Recipient email field must be a valid email address."),
            subject: Yup.string().required("Subject is required"),
            body: Yup.string().required("Description is required"),
        });
        const sendDocument = async () => {
            const pdfParams = {
                ...(form.value.doc_type === "pdf" ?
                    props.suppliersData :
                    form.value.doc_type === "excel" ?
                        props.suppliersExcelData :
                        {}),
                recipient_email: recipient_email.value || props.suppliersData.email, // Assumes `recipient_email` is already defined elsewhere
                subject: form.value.subject,
                body: form.value.body,
                doc_type: form.value.doc_type,
            };
            if (props.supplierTrue) {
                const response = await store.dispatch("price/sendSmtpPdf", pdfParams);
                if (response.success) {
                    form.value.subject = "",
                        form.value.doc_type = "pdf",
                        form.value.body = "",
                        apiErrors.value = null,
                        emit("close");
                } else {
                    apiErrors.value = response.errors;
                    message.error(response.errors);
                    emit("close");
                }
            } else {
                const response = await store.dispatch("price/sendSmtpDocument", pdfParams);
                if (response.status) {
                    form.value.subject = "",
                        form.value.doc_type = "pdf",
                        form.value.body = "",
                        apiErrors.value = null,
                        emit("close");
                } else {
                    apiErrors.value = response.errors;
                }
            }
        };
        const closeModal = () => {
            form.value.subject = "",
                form.value.doc_type = "pdf",
                form.value.body = "",
                apiErrors.value = null,
                emit("close"); // Now correctly using emit
        };

        watch(
            () => props.isVisible,
            (newValue) => {
                if (newValue) {
                    document.body.classList.add("modal-open");
                    const backdrop = document.createElement("div");
                    backdrop.className = "modal-backdrop fade show";
                    document.body.appendChild(backdrop);
                } else {
                    document.body.classList.remove("modal-open");
                    const backdrop = document.querySelector(".modal-backdrop");
                    if (backdrop) {
                        backdrop.remove();
                    }
                }
            }
        );
        // Cleanup on component unmount
        onUnmounted(() => {
            document.body.classList.remove("modal-open");
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) {
                backdrop.remove();
            }
        });
        watch(
            () => props.isVisible, // Watching the `isVisible` prop
            (newValue) => {
                if (newValue) {
                    recipient_email.value =
                        props.suppliersData.email ||
                        props.currentSupplierData.quoteDetails.Email;
                }
            }
        );
        return {
            schema,
            apiErrors,
            closeModal,
            form,
            sendDocument,
            recipient_email,
            docTypes,
        };
    },
};
</script>

<style scoped>
.modal {
    display: block;
}

.table_height {
    max-height: 428px;
}
</style>
