<template>
<index-loader></index-loader>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <dashboard-header :title="title" @filter-toggle="handleFilterToggle" />
        <div class="contact-head" v-if="opp_Id">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                      <router-link :to="{ path: '/crm/meter-opportunities-edit', query: { ter_Id: territory_id,
                        org_Id: org_Id,
                        opp_Id: opp_Id } }"> <i class="ti ti-arrow-narrow-left"></i>  Meter Opportunity</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        <div class="row position-relative m-0">
          <div class="user-accordion-item">
            <a href="javascript:;" class="accordion-wrap" data-bs-toggle="collapse" data-bs-target="#basicDetails"><span><i class="ti ti-details"></i></span>Gas / Electricity
              Details</a>
            <div class="accordion-collapse collapse show" id="basicDetails" data-bs-parent="#list-accord">
              <div class="content-collapse m-0 p-0">
                <div class="col-md-12">
                  <div class="contact-tab-wrap mb-0 px-2">
                    <ul class="contact-nav nav">
                      <li class="col-md-1 me-0">
                        <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#gas" :class="{ active: activeTab === 'gas' }" @click="activeTab = 'gas'">Gas</a>
                      </li>
                      <li class="col-md-1 me-0">
                        <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#electricity" :class="{ active: activeTab === 'electricity' }" @click="activeTab = 'electricity'">Electricity</a>
                      </li>
                    </ul>
                    <button type="button" class="btn btn-primary py-2 m-2" @click="compare">
                      Compare
                    </button>
                  </div>

                  <!-- Tab Content -->
                  <div class="contact-tab-view mb-0 p-2">
                    <div class="tab-content pt-0">
                      <!-- gas -->
                      <div class="tab-pane" :class="{ 'active show': activeTab === 'gas' }" id="gas">
                        <quoting-gas @submit-form="submitFormData" :isNotValid="isNotValid" :validEmail="validEmail" :gasOppId="gasOppId"></quoting-gas>
                      </div>

                      <!-- electricity -->
                      <div class="tab-pane" :class="{ 'active show': activeTab === 'electricity' }" id="electricity">
                        <quoting-electricity @submit-elctric-form="submitFormData" :isElecNotValid="isElecNotValid" :validEmail="validEmail" :elecOppId="elecOppId"></quoting-electricity>
                      </div>
                    </div>
                  </div>
                  <!-- /Tab Content -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 p-0">
            <div class="filter-panel" :class="{ open: isFilterOpen }">
              <button type="btn" class="btn btn-primary filter_btn p-1" @click="handleFilterToggle"><i class="ti ti-arrow-bar-to-right"></i></button>
              <refine-filter :uniqueSuppliers="filteredSuppliers" @update-selected-suppliers="UpdateSuppliers" @update:isYear="updateIsYear" @updateyears="updateSelectedYears" @min-months="handleMinMonths" @max-months="handleMaxMonths"></refine-filter>
            </div>
          </div>
          <div class="col-md-12 p-0 position-relative">
            <!-- v-if="activeTab === 'gas'" -->
            <quoting-data v-if="filteredSuppliersRateList?.length > 0" :suppliersRateList="filteredSuppliersRateList" :isElectric="isElectric" :selectedSuppliers="selectedSuppliers" :isYear="isYear" :selectedYears="selectedYears" :minMonths="minMonths" :maxMonths="maxMonths" :formData="formData" @emailValidation="emailValidation"></quoting-data>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Page Wrapper -->
</template>

<script>
import {
  ref
} from "vue";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  onMounted,
  computed
} from "vue";
import {
  useStore,
  mapGetters
} from "vuex";
import {
  message
} from "ant-design-vue";
export default {
  data() {
    return {
      title: "Quoting",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const gasformData = ref(null);
    const elecformData = ref(null);
    const formData = ref(null);
    const isFilterOpen = ref(false);
    const suppliersRateList = ref([]);
    const elecSuppliersRateList = ref([]);
    const isElectric = ref(false);
    const activeTab = ref("gas");
    const uniqueSuppliers = ref([]);
    const uniqueElectricSuppliers = ref([]);
    const isYear = ref(false);
    const selectedYears = ref([]);
    const selectedSuppliers = ref([]);
    const isNotValid = ref(false);
    const isElecNotValid = ref(false);
    const minMonths = ref(12);
    const maxMonths = ref(60);
    const elecOppId = ref(null);
    const gasOppId = ref(null);
    const validEmail = ref({});
    const territory_id = ref(route.query.ter_Id || null);
    const opp_Id = ref(route.query.opp_Id || null)
    const org_Id = ref(route.query.org_Id || null)
    // Methods to handle emitted events
    const handleMinMonths = (newMin) => {
      minMonths.value = newMin;
    };

    const handleMaxMonths = (newMax) => {
      maxMonths.value = newMax;
    };

    const handleFilterToggle = () => {
      isFilterOpen.value = !isFilterOpen.value;
    };
    const updateSelectedYears = (years) => {
      selectedYears.value = years;
    };
    const updateIsYear = (newValue) => {
      isYear.value = newValue;
    };
    const emailValidation = (data) => {
      if(data.errors){
        validEmail.value = data.errors;
      } else {
        if (activeTab.value === "gas"){
          isNotValid.value = data;
          validEmail.value = {}
        }else{
          isElecNotValid.value = data;
          validEmail.value = {}
        }
      }
    }
    const submitFormData = (data) => {
      if (data.mainProductService === "gas") {
        gasformData.value = data;
      } else if (data.mainProductService === "elec") {
        elecformData.value = data;
      }
    };
    const UpdateSuppliers = (suppliers) => {
      selectedSuppliers.value = suppliers;
    };

    const compare = async () => {
      if (activeTab.value === "gas") {
        formData.value = gasformData.value;
        if (formData.value.quoteDetails.PostCode === '' || formData.value.quoteDetails.GasSupply.Consumption.Amount === 0 || formData.value.quoteDetails.CurrentSupplier === '' || (formData.value.quoteDetails.GasSupply.ContractRenewalDate && (new Date(formData.value.quoteDetails.GasSupply.ContractRenewalDate) <= new Date())) || formData.value.quoteDetails.Settings[1].value === '0' || formData.value.quoteDetails.Uplift === null ) {
          isNotValid.value = true;
          return;
        } else {
          isNotValid.value = false;
          const dateFormate = formData.value.quoteDetails.GasSupply.ContractRenewalDate;
          // const convertedDate = new Date(dateFormate).toISOString().split('T')[0];
          const convertedDate = new Date(dateFormate).toLocaleDateString("en-CA")
          localStorage.setItem('renewDate', convertedDate);
          localStorage.setItem('annual_usage', formData.value.quoteDetails.GasSupply.Consumption.Amount);
          const response = await store.dispatch(
            "price/getSupplierPrice",
            formData.value
          );
          suppliersRateList.value = response;
          uniqueSuppliers.value = [
            ...new Set(response?.map((supplier) => supplier.Supplier)),
          ];
        }
      } else if (activeTab.value === "electricity") {
        formData.value = elecformData.value;
        if (formData.value.quoteDetails.PostCode === '' || formData.value.quoteDetails.ElectricSupply.MPANTop === '' ||
          formData.value.quoteDetails.ElectricSupply.MPANBottom === '' || formData.value.quoteDetails.ElectricSupply.DayConsumption.Amount === 0 || (formData.value.quoteDetails.ElectricSupply.ContractRenewalDate && (new Date(formData.value.quoteDetails.ElectricSupply.ContractRenewalDate) <= new Date())) || formData.value.quoteDetails.CurrentSupplier === '' || formData.value.quoteDetails.Settings[1].value === '0' || formData.value.quoteDetails.Uplift === null ) {
          isElecNotValid.value = true;
          return;
        } else {
          const elecdateFormate = formData.value.quoteDetails.ElectricSupply.ContractRenewalDate;
          // const convertedElecDate = new Date(elecdateFormate).toISOString().split('T')[0];
          const convertedElecDate = new Date(elecdateFormate).toLocaleDateString("en-CA")
          localStorage.setItem('elec_renewDate', convertedElecDate);
          localStorage.setItem('elec_annual_usage', formData.value.quoteDetails.ElectricSupply.DayConsumption.Amount);
          isElecNotValid.value = false;
          if(formData.value.quoteDetails.ElectricSupply.NightConsumption.Amount === ''){
            formData.value.quoteDetails.ElectricSupply.NightConsumption.Amount = 0
          }
          if(formData.value.quoteDetails.ElectricSupply.WendConsumption.Amount === ''){
            formData.value.quoteDetails.ElectricSupply.WendConsumption.Amount = 0
          }
          if(formData.value.quoteDetails.ElectricSupply.KvaConsumption.Amount === ''){
            formData.value.quoteDetails.ElectricSupply.KvaConsumption.Amount = 0
          }
          const response = await store.dispatch(
            "price/getSupplierPrice",
            formData.value
          );
          elecSuppliersRateList.value = response;
          uniqueElectricSuppliers.value = [
            ...new Set(response?.map((supplier) => supplier.Supplier)),
          ];
        }
      }

      if (formData.value.mainProductService === "elec") {
        isElectric.value = true;
      } else {
        isElectric.value = false;
      }
    };

    const filteredSuppliers = computed(() => {
      return activeTab.value === "gas" ? uniqueSuppliers.value : uniqueElectricSuppliers.value;
    });

    const filteredSuppliersRateList = computed(() => {
      return activeTab.value === "gas" ? suppliersRateList.value : elecSuppliersRateList.value;
    });
    onMounted(async () => {
      if (route.query.for) {
        if (route.query.for === 'Gas') {
          gasOppId.value = route.query.opp_Id
          activeTab.value = 'gas';
        } else {
          elecOppId.value = route.query.opp_Id
          activeTab.value = 'electricity';
        }
      }
    });

    return {
      gasOppId,
      territory_id,
      org_Id,
      opp_Id,
      emailValidation,
      elecOppId,
      isNotValid,
      validEmail,
      isElecNotValid,
      handleMinMonths,
      handleMaxMonths,
      minMonths,
      maxMonths,
      updateIsYear,
      selectedYears,
      updateSelectedYears,
      isYear,
      activeTab,
      gasformData,
      elecformData,
      isElectric,
      formData,
      compare,
      isFilterOpen,
      handleFilterToggle,
      submitFormData,
      suppliersRateList,
      elecSuppliersRateList,
      uniqueSuppliers,
      UpdateSuppliers,
      selectedSuppliers,
      uniqueElectricSuppliers,
      filteredSuppliers,
      filteredSuppliersRateList
    };
  },
};
</script>

<style scoped>
.contact-breadcrumb{
  li{
    a{
      font-size: 16px;
      /* color: #4983cf;
      &:hover{
        text-decoration: underline;
      } */
    }
  }
}
.filter-panel {
  position: fixed;
  right: -400px;
  top: 57px;
  width: 290px;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  height: 100%;
}

.filter-panel.open {
  right: 0;
}

.filter_btn {
  position: absolute;
  left: -20px;
}
</style>
