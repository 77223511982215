import apiClient from '@/axios';
import { message } from 'ant-design-vue';
import router from '@/router';

const state = {
  authToken: localStorage.getItem('authToken') || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  company: {},
  smtp: [],
  credentials: [],
  dropdowns:[]
};
const mutations = {
  setAuthToken(state, token) {
    state.authToken = token;
  },

  setUser(state, user) {
    state.user = { ...state.user, ...user };
  },

  SET_COMAPNY_DATA(state, data) {
    state.company = { ...state.data, ...data }
  },
  SET_SMTP(state, data) {
    state.smtp = data
  },
  SET_CRDENTIALS_DATA(state, data) {
    state.credentials = { ...state.data, ...data }
  },
  SET_DROPDOWN_DATA(state, data) {
    state.dropdowns = data
  },
};
const getters = {
  isAuthenticated: state => !!state.authToken,
  authToken: state => state.authToken,
  user: state => state.user,
  company: state => state.company,
  smtp: state => state.smtp,
  credentials: state => state.credentials,
  dropdowns: state => state.dropdowns,
};

const actions = {
  async login({ commit }, loginData) {
    try {
      const response = await apiClient.post('/login', loginData);
      if (response.status) {
        const token = response.data.data.token;
        const user = response.data.data;

        // Store token and user data in localStorage
        localStorage.setItem('authToken', token);
        localStorage.setItem('user', JSON.stringify(user));

        // Commit to Vuex store
        commit('setAuthToken', token);
        commit('setUser', user);

        return response.data;
      } else {
        return Promise.reject(response.data.error);
      }
    } catch (error) {
      return Promise.reject('An error occurred. Please try again.');
    }
  },

  async logout({ commit }) {
    try {
      const response = await apiClient.post('/logout');
      if (response.data.status) {
        localStorage.removeItem('authToken');
        commit('setAuthToken', '');
        localStorage.removeItem('user');
        commit('setUser', {});
        router.push('/')
      } else {
        console.error('Logout failed:', response.data.error);
      }
    } catch (error) {
      localStorage.removeItem('authToken');
      commit('setAuthToken', '');
      localStorage.removeItem('user');
      commit('setUser', {});
      router.push('/')
      console.error('Error during logout:', error);
    }
  },

  async forgotPassword({ commit }, email) {
    try {
      const response = await apiClient.post('/forgot-password', { email });

      if (response.data.status) {
        message.success('Password reset link sent successfully!');
        router.push('/');
      }
      return response.data;
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
      return error.response.data;
    }
  },

  async resetPassword({ commit }, form) {
    try {
      const response = await apiClient.post('/reset-password', form);
      if (response.data.status) {
        message.success('Password reset successfully!');
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateProfile({ commit, state }, form) {
    try {
      const response = await apiClient.post('/auth/update-profile', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status) {
        const updatedUser = response.data.data; // Assuming the API returns the updated user data

        // Merge the updated user data with the current user state
        const mergedUser = { ...state.user, ...updatedUser };

        // Update user in Vuex store
        commit('setUser', mergedUser);

        // Update user in localStorage
        localStorage.setItem('user', JSON.stringify(mergedUser));

        message.success(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateCompany({ commit, state }, form) {
    try {
      const response = await apiClient.post('/company/profile-update', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status) {
        const updatedCompany = response.data.data; // Assuming the API returns the updated user data

        const mergedCompany = { ...state.company, ...updatedCompany };

        commit('SET_COMAPNY_DATA', mergedCompany);

        localStorage.setItem('company', JSON.stringify(mergedCompany));

        message.success(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchUser({ commit }) {
    try {
      const response = await apiClient.get('auth/view-profile');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  },

  async fetchCompany({ commit }) {
    try {
      const response = await apiClient.get('company/profile-view');
      const company = response.data.data;
      localStorage.setItem('company', JSON.stringify(company));
      // commit('SET_COMAPNY_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },
  async updatePlanTypes({ commit, state }, form) {
    try {
      const response = await apiClient.post('/admin/power-price/plans', form);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  loadCompanyFromLocalStorage({ commit }) {
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    if (Object.keys(company).length) {
      commit('SET_COMAPNY_DATA', company);
    }
  },
  loadUserFromLocalStorage({ commit }) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (Object.keys(user).length) {
      commit('setUser', user);
    }
  },

  // async createSmtp({ commit, state }, form) {
  //   try {
  //     const response = await apiClient.post('/smtp-details', form);

  //     if (response.status) {
  //       const smtp = response.data;
  //       commit('SET_SMTP', smtp);
  //       message.success(response.data?.message);
  //     }
  //     return response.data;
  //   } catch (error) {
  //     console.log(error);
  //     message.error(error.response.data.message);
  //   }
  // },

  async getSmtpUser({ commit, state }) {
    try {
      const response = await apiClient.get('/smtp-details/view/user');

      if (response.status) {
        const smtp = response.data;
        commit('SET_SMTP', smtp);
      }

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getSmtpCompany({ commit, state }) {
    try {
      const response = await apiClient.get('/smtp-details/view/company');

      if (response.status) {
        const smtp = response.data;
        commit('SET_SMTP', smtp);
      }

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateSmtp({ commit, state }, form) {
    try {
      const response = await apiClient.post('/smtp-details/update', form);

      if (response.status) {
        const smtp = response.data;
        commit('SET_SMTP', smtp);
        message.success(response.data?.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    }
  },

  async updateCredentials({ commit, state }, form) {
    try {
      let response;
      response = await apiClient.post(`/credentials/save`, form);

      if (response.status) {
        commit('SET_CRDENTIALS_DATA', response.data.data);
        message.success(response.data.message);
      }

      return response.data;
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error);
    }
  },

  async fetchCredentials({ commit }) {
    try {
      const response = await apiClient.get(`/credentials/view`);
      commit('SET_CRDENTIALS_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },

  async fetchDropdowns({ commit }) {
    try {
      const response = await apiClient.get('settings/dropdown-names');
      return response.data;
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },

  async fetchFields({ commit }, key) {
    try {
      const response = await apiClient.get('settings/get-dropdown-data', {
        params: {
          key: key
        }
      })
      commit('SET_DROPDOWN_DATA', response.data.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },

  async updateDropdowns({ commit, state }, form) {
    try {
      const result = {
        data: Object.entries(form)
          .filter(([key]) => key !== "dropdownKey") 
          .map(([key, value]) => ({ value:key, text: value })) 
      };

      const response = await apiClient.post('/settings/update-dropdown', result, {
        params: {
          key: form.dropdownKey
        }
      });
      if (response.data?.status) {
        message.success(response.data.message);
      }

      return response.data;
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
