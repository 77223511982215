<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>

<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <!-- <page-header :title="title" /> -->
        <!-- /Page Header -->
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <router-link to="/crm/organisation-list"><i class="ti ti-arrow-narrow-left"></i>Organisations</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card main-card">
          <div class="card-body">
            <!-- Contact List -->
            <div class="settings-sub-header">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <h4>{{ title }}</h4>
                      </div>
                      <div class="col-6 text-end">
                        <router-link class="btn btn-primary" :to="{ path: '/crm/create-person', query: { org_Id: org_id } }">
                          <i class="ti ti-square-rounded-plus me-1"></i>Add
                          Person
                        </router-link>
                      </div>
                    </div>
                  </div>
            <div class="table-responsive custom-table">
              <persons-table />
            </div>
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="datatable-length"></div>
              </div>
              <div class="col-md-6">
                <div class="datatable-paginate"></div>
              </div>
            </div>
            <!-- /Contact List -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Page Wrapper -->
</template>

<script>
import {
  ref,
  reactive
} from "vue";

export default {
  data() {
    return {
      title: "Persons",
      searchQuery: "",
      org_id: this.$route.query.org_Id,
      filters: {
        status: "",
        vatGroup: "",
        www: "",
        fax: "",
        telephone: ""
      },
      appliedFilters: {} // New object to store the applied filters
    };
  },
  methods: {
    async searchCompanies() {
      this.$refs.companiesTable.fetchOrganisations();
    },
    resetFilters() {
      this.filters = {
        status: "",
        vatGroup: "",
        www: "",
        fax: "",
        telephone: "",
      };
      this.applyFilters();
    },
    applyFilters() {
      this.appliedFilters = {
        ...this.filters
      };
      this.searchCompanies();
    }
  }
};
</script>
