<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <general-sidebar></general-sidebar>

            <div class="col-xl-9 col-lg-12">
              <!-- Settings Info -->
              <div class="card">
                <div class="card-body">
                  <div class="settings-header">
                    <!-- <h4>Company Settings</h4> -->
                    <div>
                      <ul class="nav nav-tabs">
                        <li class="nav-item">
                          <a class="nav-link" :class="{ active: activeTab === 'company' }"
                            @click="setActiveTab('company')">
                            Company Settings
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" :class="{ active: activeTab === 'smtp' }"
                            @click="setActiveTab('smtp')">
                            SMTP
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="settings-form">
                    <Form  v-if="activeTab === 'company'" @submit="saveProfile" :validation-schema="schema">
                      <div class="settings-sub-header">
                        <h6>Company Information</h6>
                        <p>Provide the information below</p>
                      </div>
                      <div class="form-wrap">
                        <div class="profile-upload">
                          <div class="profile-upload-img">
                            <span v-if="!imagePreview"><i class="ti ti-photo"></i></span>
                            <img
                              v-if="imagePreview"
                              :src="imagePreview"
                              class="img-fluid"
                            />
                            <button
                              v-if="imagePreview"
                              type="button"
                              class="profile-remove"
                              @click="removeImage"
                            >
                              <i class="feather-x"></i>
                            </button>
                          </div>
                          <div class="profile-upload-content">
                            <label class="profile-upload-btn">
                              <i class="ti ti-file-broken"></i> Upload Logo
                              <input
                                type="file"
                                class="input-img"
                                @change="handleImageUpload"
                              />
                            </label>
                            <p>JPG, JPEG or PNG. Max size of 2MB.</p>
                          </div>
                        </div>
                      </div>
                      <div class="profile-details">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Company Name <span class="text-danger">*</span>
                              </label>
                              <Field
                                name="name"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.name"
                                disabled
                              />
                              <ErrorMessage name="name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label"> Subscription Days </label>
                              <Field
                                name="subscription_duration_value"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.subscription_duration_value"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label"> Expiry Date </label>
                              <Field
                                name="subscription_end_date"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.subscription_end_date"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label"> Phone Number </label>
                              <Field
                                name="phone"
                                as="input"
                                v-model="form.phone"
                                type="text"
                                class="form-control"
                              />
                              <span
                                class="text-danger"
                                v-if="
                                  apiErrors &&
                                  apiErrors['phone'] &&
                                  apiErrors['phone'].length
                                "
                                >{{ apiErrors["phone"][0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Email</label>
                              <Field
                                name="email"
                                as="input"
                                v-model="form.email"
                                type="email"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">API key</label>
                              <Field
                                name="api_key"
                                as="input"
                                v-model="form.api_key"
                                type="text"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-md-">
                            <div class="form-wrap">
                              <label class="col-form-label"> Address </label>
                              <Field
                                name="address"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.address"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="submit-button text-end">
                        <!-- <a href="javascript:;" class="btn btn-light">Cancel</a> -->
                        <button type="submit" class="btn btn-primary">
                          Save Changes
                        </button>
                      </div>
                    </Form>

                    <Form v-if="activeTab === 'smtp'" @submit="saveSmtp" :validation-schema="smtpSchema">
                      <div class="profile-details">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Host <span class="text-danger">*</span>
                              </label>
                              <Field name="host" as="input" type="text" class="form-control" v-model="smtpForm.host" />
                              <ErrorMessage name="host" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Port <span class="text-danger">*</span>
                              </label>
                              <Field name="port" as="input" type="text" class="form-control" v-model="smtpForm.port" />
                              <ErrorMessage name="port" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Encryption <span class="text-danger">*</span>
                              </label>
                              <!-- <Field name="encryption" as="input" type="text" class="form-control"
                                v-model="smtpForm.encryption" /> -->
                              <Field as="select" name="encryption" class="form-select" v-model="smtpForm.encryption">
                                <option value="">
                                  ----select----
                                </option>
                                <option key="tls" value="tls">
                                  TLS
                                </option>
                                <option key="ssl" value="ssl">
                                  SSL
                                </option>
                                <option key="none" value="none">
                                  NONE
                                </option>
                              </Field>
                              <ErrorMessage name="encryption" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                User Name <span class="text-danger">*</span>
                              </label>
                              <Field name="username" as="input" v-model="smtpForm.username" type="text"
                                class="form-control" />
                              <ErrorMessage name="username" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Password <span class="text-danger">*</span></label>
                              <Field name="password" as="input" v-model="smtpForm.password" type="password"
                                class="form-control" />
                              <ErrorMessage name="password" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">From Name <span class="text-danger">*</span></label>
                              <Field name="from_name" as="input" v-model="smtpForm.from_name" type="text"
                                class="form-control" />
                              <ErrorMessage name="from_name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">From Email <span class="text-danger">*</span></label>
                              <Field name="from_email" as="input" v-model="smtpForm.from_email" type="email"
                                class="form-control" />
                              <ErrorMessage name="from_email" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Default <span class="text-danger">*</span></label>
                              <!-- <Field name="is_default" as="input" v-model="smtpForm.is_default" type="text"
                                class="form-control" /> -->
                              <Field as="select" name="is_default" class="form-select" v-model="smtpForm.is_default">
                                <option value="">
                                  ----select----
                                </option>
                                <option :key="1" :value="1">
                                  True
                                </option>
                                <option :key="0" :value="0">
                                  False
                                </option>
                              </Field>
                              <ErrorMessage name="is_default" class="text-danger" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="submit-button text-end">
                        <button type="submit" class="btn btn-primary">
                          Save SMTP
                        </button>
                      </div>
                    </Form>

                  </div>
                </div>
              </div>
              <!-- /Settings Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      title: "Settings",
      activeTab: 'company',
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
  setup() {
    const store = useStore();
    // const route = useRoute();
    // const router = useRouter();
    const imagePreview = ref("");
    const apiErrors = ref(null);

    const Countries = computed(() => store.getters["organisation/getCountries"]);
    // const user = computed(() => store.getters['auth/user']);
    // const territories = computed(() => store.getters["users/getterritories"]);

    const schema = Yup.object().shape({
      name: Yup.string().required("First name is required"),
    });

    const smtpSchema = Yup.object().shape({
      host: Yup.string()
        .required("Host is required"),
      port: Yup.number()
        .required("Port is required")
        .typeError("Port must be a number"),
      encryption: Yup.string()
        .required("Encryption type is required"),
      username: Yup.string()
        .required("Username is required"),
      password: Yup.string()
        .required("Password is required"),
      from_name: Yup.string()
        .required("From Name is required"),
      from_email: Yup.string()
        .email("Invalid email format")
        .required("From Email is required"),
      is_default: Yup.boolean()
        .required("Default status is required")
        .typeError("Default status must be true or false"),
    });

    const form = ref({
      id: null,
      logo: null,
      api_key: null,
      email: "",
      is_active: "",
      name: "",
      phone: "",
      address: "",
      subscription_duration_value: "",
      subscription_end_date: "",
    });

    const smtpForm = ref({
      host:'',
      port:null,
      encryption:'',
      username:'',
      password:'',
      from_name:'',
      from_email:'',
      is_default:'',
      type:'company'
    })

    // if (user.value.image) {
    //   imagePreview.value = user.value.image;
    // }

    const saveProfile = async () => {
      try {
        await store.dispatch("auth/updateCompany", form.value);
        // router.push('/');
        fetchCompanyData();
      } catch (error) {
        apiErrors.value = error.response.data.errors;
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        form.value.logo = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result; // Show the image preview
        };
        reader.readAsDataURL(file); // Convert the file to a data URL
      }
    };

    const fetchCompanyData = async () => {
      const data = await store.dispatch("auth/fetchCompany");
      Object.assign(form.value, data);
      if (data?.logo) {
        imagePreview.value = data.logo;
      }

      if (data?.subscription_end_date) {
        form.value.subscription_end_date = formatDate(data.subscription_end_date);
      }
      const smtpData = await store.dispatch('auth/getSmtpCompany');
      Object.assign(smtpForm.value,smtpData)
    };
    // Function to convert date format
    const formatDate = (dateTime) => {
      if(dateTime){
        const [date, time] = dateTime.split(" ");
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year} ${time.slice(0, 5)}`; // `slice(0, 5)` trims seconds
    }
    };
    const removeImage = () => {
      form.value.logo = "";
      imagePreview.value = "";
    };

    const saveSmtp = async () => {
      try {
          const response = await store.dispatch('auth/updateSmtp', smtpForm.value);   
      } catch (error) {
        console.log("error", error)
      }
    };

    onMounted(async () => {
      store.dispatch("organisation/fetchCountries");
      fetchCompanyData();
    });

    return {
      formatDate,
      schema,
      fetchCompanyData,
      imagePreview,
      removeImage,
      form,
      saveProfile,
      handleImageUpload,
      Countries,
      apiErrors,
      saveSmtp,
      smtpForm,
      smtpSchema
    };
  },
};
</script>
