<template>
<a-table class="table dataTable no-footer" :columns="columns" :data-source="data" :row-selection="rowSelection" :pagination="pagination" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'number'">
      <router-link :to="{ path: '/crm/edit-contract', query: { ter_Id: record.territory_id, org_Id: record.orgId, contract_Id: record.key }}" class="profile-split d-flex flex-column">
          {{ record.number }}
        </router-link>
    </template>
    <template v-else-if="column.key === 'org_name'">
      <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: record.territory_id, org_Id: record.orgId, for: 'contracts' }}" class="profile-split d-flex flex-column">
          {{ record.org_name }}
        </router-link>
    </template>
    
    <template v-else-if="column.key === 'action'">
      <div class="icon-field">
          <router-link :to="{ path: '/crm/edit-contract', query: { ter_Id: record.territory_id, org_Id: record.orgId, contract_Id: record.key }}" class="btn table_action_btn"><i class="ti ti-edit"></i> Edit</router-link>
      </div>
    </template>
  </template>
</a-table>
</template>

<script>
import {
  ref,
  watchEffect
} from 'vue';
import {
  useStore
} from "vuex";

const columns = [{
    title: "Number",
    dataIndex: "number",
    key: "number",
    sorter: {
      compare: (a, b) => {
        a = a.number;
        b = b.number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Name",
    dataIndex: "org_name",
    key: "org_name",
    sorter: {
      compare: (a, b) => {
        a = a.org_name;
        b = b.org_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "Description",
    sorter: {
      compare: (a, b) => {
        a = a.description;
        b = b.description;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contract Responsible",
    dataIndex: "org_res",
    sorter: {
      compare: (a, b) => {
        a = a.org_res;
        b = b.org_res;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.status;
  //       b = b.status;
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Date of Sale",
    dataIndex: "date_of_sale",
    sorter: {
      compare: (a, b) => {
        a = a.date_of_sale;
        b = b.date_of_sale;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SC Forecast Annual Consumption",
    dataIndex: "annual_consumption",
    sorter: {
      compare: (a, b) => {
        a = a.annual_consumption;
        b = b.annual_consumption;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
  },
];

export default {
  props: {
    searchQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const store = useStore();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchContracts = async () => {
      const params = {
        search: props.searchQuery, // Use the correct prop
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };

      const response = await store.dispatch('contract/fetchContracts', params);
      if (response.contracts.length > 0) {
        data.value = response.contracts.map((contract) => ({
          key: contract.id,
          number: contract.contract_number,
          territory_id: contract.opportunity.territory_id,
          orgId: contract.organization.id,
          org_name: contract.organization.name,
          org_res: contract.responsible_user_name,
          description: contract.contract_description,
          status: contract.overall_status,
          date_of_sale: contract.date_of_sale ? formatDate(contract.date_of_sale) : null,
          annual_consumption: contract.sc_forecast_or_amended_annual_consumption || '0.00',
          create: contract.date,
        }));
        pagination.value.total = response.total;
      }
    };
    // Function to convert date format
    const formatDate = (date) => {
      if(date){
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    }
    };
    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      // fetchContracts();
    };

    watchEffect(() => {
      fetchContracts();
    });

    return {
      data,
      pagination,
      formatDate,
      fetchContracts,
      handleTableChange,
    };
  },
};
</script>
