<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <Form @submit="updateActivity">
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <button type="submit" class="custom-header-button"> <i class="ti ti-check"></i>
                            Save
                          </button>
                  </li>
                  <li>
                    <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: terId, org_Id: orgId,for: 'activities' } }">
                      <i class="ti ti-arrow-narrow-left"></i>
                      Organisation
                    </router-link>
                  </li>
                <li>
                  <router-link to="/crm/activities-list">
                    <i class="ti ti-arrow-narrow-left"></i>
                    List
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-body settings-form">

                <div class="row organisation-details-section mt-2" v-if="Object.keys(org_details).length">
                  <div class="col-md-12">
                    <h4 class="d-flex align-items-center my-2"><a class="website me-2" :href="org_details.www">www</a>{{ org_details.name }}</h4>
                    <p class="mb-1" title="Postcode">{{ org_details.postcode }}</p>
                    <!-- <p title="Telephone">T: {{ org_details.telephone }}</p> -->
                    <!-- <p class="text-danger" title="Alert text"><i class="typcn typcn-info me-1"></i>{{ org_details.alert_text }}</p> -->
                    <!-- <p title="Address">{{ org_details.city +', ' + org_details.state +', ' + country.country_name  }}</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body settings-form">
                <!-- <Form @submit="updateActivity"> -->
                  <div class="settings-sub-header">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <h4> Activity details </h4>
                      </div>
                    </div>
                  </div>
                  <div class="pro-create">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Responsible<span class="text-danger">*</span></label>
                          <Field as="select" name="responsible" class="form-select" v-model="formData.responsible">
                            <option value="">-----Select-----</option>
                            <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Contact</label>
                          <Field as="select" name="organization_person_id" class="form-select" v-model="formData.organization_person_id">
                            <option value="">-----Select-----</option>
                            <option v-for="res in orgPersons" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Description</label>
                          <Field name="description" as="input" type="text" class="form-control" v-model="formData.description" />
                          <ErrorMessage name="description" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Main product/service</label>
                          <Field as="select" name="main_product_service" class="form-select" v-model="formData.activity_detail.main_product_service">
                            <option value="">-----Select-----</option>
                            <option v-for="res in Options.op_product" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Comment </label>
                          <textarea class="form-control" rows="1" v-model="formData.activity_detail.comment"></textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Client name</label>
                          <Field name="client_name" as="input" type="text" class="form-control" v-model="formData.activity_detail.client_name" />
                          <ErrorMessage name="client_name" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Client telephone</label>
                          <Field name="client_telephone" as="input" type="text" class="form-control" v-model="formData.activity_detail.client_telephone" />
                          <ErrorMessage name="client_telephone" class="text-danger" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row  mt-4">
                    <div class="col-md-6">
                      <div class="row mx-1" style="border: 1px solid #e1e1e1;">
                        <div class="col-12">
                          <div class="form-wrap mb-0">
                            <h4 class="p-2">Logs</h4>
                            <p class="col-form-label p-2" style="background-color: #eff1f0;">Add log entry</p>
                            <textarea class="form-control" rows="3" v-model="formData.content"></textarea>
                          </div>
                        </div>
                        <div class="col-12 my-4">
                          <div class="row">
                            <div class="col-md-8">
                              <div class="form-wrap">
                                <div class="row align-items-center mb-1">
                                  <div class="col-3">Days: </div>
                                  <div class="col-9 d-flex flex-wrap gap-1">
                                    <div class="add-cursor text-teal" @click="addToDate('days', day)" v-for="day in addDays" :key="day" :id="day">
                                    <div class='rounded-div'>+{{ day }}</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row align-items-center my-3">
                                  <div class="col-3">Weeks: </div>
                                  <div class="col-9 d-flex flex-wrap gap-1">
                                  <div class="add-cursor text-teal" @click="addToDate('weeks', week)" v-for="week in addWeeks" :key="week" :id="week">
                                    <div class='rounded-div'>+{{ week }}</div>
                                  </div>
                                </div>
                                </div>
                                <div class="row align-items-center">
                                  <div class="col-3">Months: </div>
                                  <div class="col-9 d-flex flex-wrap gap-1">
                                  <div class="add-cursor text-teal" @click="addToDate('months', month)" v-for="month in addMonths" :key="month" :id="month">
                                    <div class='rounded-div'>+{{ month }}</div>
                                  </div>
                                </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4  d-flex flex-column">
                              <div class="form-wrap icon-form">
                                <date-picker placeholder="" class="datetimepicker form-control" textInput v-model="formData.date" :editable="true" :clearable="true" :input-format="dateFormat" />
                                <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                              </div>
                              <div class="form-wrap icon-form mt-auto">
                                <input class="form-control datetimepicker-time" v-model="formattedTime" @change="updateTime" placeholder="Select Time" type="time" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-1 my-3" style="border: 1px solid #e1e1e1;">
                        <div class="col-md-12 p-0">
                          <div class="form-wrap mb-0 shadow" style="border: 1px solid #e1e1e1;">
                            <p class="col-form-label p-2" style="background-color: #eff1f0;">Logs</p>
                            <div class="row" v-for="log in activity_logs">
                              <div class="col-12">
                                <p class="p-2" style="border-bottom: 1px solid #e1e1e1;">{{ log.content }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="shadow p-3" style="border: 1px solid #e1e1e1;">
                        <div class="row meter-opportunities-section">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">

                                <h4 class="mb-3 text-indigo"><i class="ti ti-chart-arcs"></i> Meter Opportunities</h4>
                              </div>
                              <div class="col-6 text-end">
                                <router-link :to="{ path: '/crm/create-meter-opportunity', query: { add: 'activity', org_Id: orgName.id, ter_Id: terId, actId: formData.id } }" class="btn btn-primary p-1">
                                  <i class="ti ti-plus"></i>
                                </router-link>
                              </div>
                            </div>
                            <div class="opp-data">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row py-3 border-bottom" v-for="opp in activityData.meter_opportunities" :key="opp.id">
                                    <div class="col-5">
                                      <h6 class="mb-1"><i class="ti ti-calendar-check"></i> {{ formatDate(opp.date_of_sale) }}</h6>
                                      <h6 class="my-2"><i class="ion-person"></i> {{ orgName.name }}</h6>
                                      <h6 class="mb-1"><i class="ion-information-circled"></i> {{ opp.pipeline.name }}</h6>
                                    </div>
                                    <div class="col-7">
                                      <h6># {{ opp.meter_opportunity_nr_auto }}</h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-5"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="shadow mt-4 p-3" style="border: 1px solid #e1e1e1;">
                        <div class="row meter-opportunities-section">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">

                                <h4 class="mb-3 text-indigo"><i class="ti ti-file-check"></i> Contracts</h4>
                              </div>
                              <div class="col-6 text-end">
                                <router-link :to="{ path: '/crm/create-contract', query: { add: 'activity', org_Id: orgName.id, ter_Id: terId, actId: formData.id } }" class="btn btn-primary p-1">
                                  <i class="ti ti-plus"></i>
                                </router-link>
                              </div>
                            </div>
                            <div class="opp-data">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row py-3 border-bottom" v-for="res in activityData.contracts" :key="res.id">
                                    <div class="col-5">
                                      <h6 class="mb-1"><i class="ti ti-calendar-check"></i> {{ formatDate(res.opportunity.date_of_sale) }}</h6>
                                      <h6 class="my-2"><i class="ti ti-user"></i> {{ orgName.name }}</h6>
                                    </div>
                                    <div class="col-7">
                                      <h6># {{ res.contract_number }}</h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-5"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end mt-4">
                     <button type="button" class="btn btn-light" @click="handleCancel">
                        Cancel
                      </button>
                    <button type="submit" class="btn btn-primary">
                      Save
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
import { changeFormate } from '../../../../util'

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "dd/MM/yyyy",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const orgName = ref({});
    const terId = ref(route.query.ter_Id || null)
    const orgId = ref(route.query.org_Id || null)
    const Options = computed(() => store.getters['activities/getactivityDropdowns']);

    const activityData = computed(() => store.getters['activities/getactivityViewData']);

    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);

    // const saleResponsibleOptions = computed(() => store.getters['organisation/getfetchAssignedTo']);
    const saleResponsibleOptions = computed(() => store.getters["organisation/getresUsersOptions"]);

    const schema = Yup.object().shape({
      responsible: Yup.string()
        .required("responsible is required"),
    });

    const formData = ref({
      id: null,
      responsible: null, //int id
      organization_person_id: null,
      description: '',
      date: '',
      time: '',
      action: "Call Back",
      content: '',
      activity_detail: {
        main_product_service: "",
        comment: "",
        client_name: "",
        client_telephone: ""
      },
    });
    const activity_logs = ref([]);

    const org_details = ref({});
    const country = ref({});
    const addDays = ref(['0', '1', '4', '5', '6','7','8','9']);
    const addWeeks = ref(['1', '2', '3', '4']);
    const addMonths = ref(['2', '3', '6', '12']);

    const fetchActData = async (id) => {
      try {
        const data = await store.dispatch('activities/fetchActivityData', id);
        Object.assign(formData.value, data);
        orgName.value = data.organization;
        activity_logs.value = data.activity_logs;
        org_details.value = data.organization;
        // country.value = data.organization.country
        if (data.activity_detail && typeof data.activity_detail === 'object') {
          // Safely merge data.activity_detail into formData.activity_detail
          Object.assign(formData.value.activity_detail, data.activity_detail);
        } else {
          // If activity_detail is null or not an object, ensure it remains as initialized
          formData.value.activity_detail = {
            main_product_service: "",
            comment: "",
            client_name: "",
            client_telephone: ""
          };
        }
        if (data.date) {
          formData.value.date = new Date(data.date);
        }
        formData.value.content = ''
      } catch (error) {
        console.error('Error fetching activity data:', error);
      }
    };

    // Computed property to handle formatted time input
    const formattedTime = computed({
      get() {
        return formatTime(formData.value.time); // Format time to HH:mm (input type="time" supports only hours and minutes)
      },
      set(value) {
        // Parse and add seconds to the time
        formData.value.time = parseTime(value); // Convert input to H:i:s format
      }
    });

    // Function to format time for input field (HH:mm)
    const formatTime = (time) => {
      if (!time) return '';
      const [hours, minutes, seconds] = time.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    // Function to parse time from input and add seconds
    const parseTime = (value) => {
      const [hours, minutes] = value.split(':');
      // Default seconds to "00"
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
    }

    // Function to handle time input changes
    const updateTime = (event) => {
      const value = event.target.value;
      formData.value.time = parseTime(value); // Ensure time is in H:i:s format
    }

    // Function to add days or months
    const addToDate = (type, value) => {
      const numericValue = parseInt(value);
      const currentDate = new Date(formData.value.date || new Date());

      if (numericValue === 0) {
        formData.value.date = new Date();
      } else {
        if (type === 'days') {
          currentDate.setDate(currentDate.getDate() + numericValue); // Add days
        } else if (type === 'weeks') {
          currentDate.setDate(currentDate.getDate() + numericValue * 7); // Add weeks (7 days per week)
        } else if (type === 'months') {
          currentDate.setMonth(currentDate.getMonth() + numericValue); // Add months
        }
        formData.value.date = new Date(currentDate);
      }
    };

    const formatDate = (date) => {
      if(date){
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      }
    };

    const updateActivity = async () => {
      const response = await store.dispatch('activities/updateActivity', {...formData.value, date:changeFormate(formData.value.date) });
      if (response?.status) {
        // handleCancel();
      }
    };
    const handleCancel = () => {
      const query = route.query.add;
      if (query === "org-details") {
        router.push({
          path: "/crm/organisation-details",
          query: {
            org_Id: route.query.org_Id,
            ter_Id: route.query.ter_Id,
            for: 'activities'
          },
        });
      } else {
        router.push("/crm/activities-list");
      }
    };
    onMounted(async () => {
      const oppId = route.query.actId;
      const organisation_id = route.query.org_Id;
      const territory_id= route.query.ter_Id
      if (oppId) {
        fetchActData(oppId);
      }
      if (organisation_id) {
        store.dispatch('opportunity/fetchPersons', organisation_id);
      }
      // store.dispatch('organisation/fetchAssignedToUsers', territory_id)
      store.dispatch("organisation/fetchTerritoryCompanyUsers", territory_id);
      // store.dispatch('opportunity/fetchPipelines');
      store.dispatch('activities/fetchActivityOptions');
    });

    return {
      schema,
      formData,
      saleResponsibleOptions,
      updateActivity,
      formattedTime,
      handleCancel,
      formatTime,
      parseTime,
      updateTime,
      activityData,
      Options,
      orgPersons,
      org_details,
      country,
      addDays,
      addWeeks,
      addMonths,
      addToDate,
      activity_logs,
      orgName,
      formatDate,
      terId,
      orgId
    };
  },
};
</script>

<style lang="scss" scoped>
.rounded-div {
  height: 35px;
  width: 35px;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract-won-row {
  background-color: #5cb85c21;
}

.contract-lost-row {
  background-color: #ff00001a;
}

.website {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #00918e;
  color: #fff;
}

.add-cursor {
  cursor: pointer;
  font-weight: 400;
}

.opp-data {
  max-height: 310px;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;

  h6 {
    color: #6F6F6F;
  }
}
</style>
