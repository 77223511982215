<template>
<div>
  <div class="row tab-row-data m-0">
    <div class="col">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Basic details</h5>
      <div class="row">
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Postcode 
              <i class="ti ti-search" style="cursor: pointer" @click="openModal(formData.quoteDetails.PostCode, 'postcode')"></i></label> <span class="text-danger">*</span>
            <input type="text" class="form-control" v-model="formData.quoteDetails.PostCode" />
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.PostCode === '')">Please enter the postcode</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">MPR NO.
              <i class="ti ti-search" style="cursor: pointer" @click="
                    openModal(formData.quoteDetails.GasSupply.MPR, 'mprn')
                  "></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.GasSupply.MPR" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Usage details</h5>
      <div class="row">
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Annual Usage (kWh) <span class="text-danger">*</span></label>
            <input type="number" class="form-control" v-model="formData.quoteDetails.GasSupply.Consumption.Amount" />
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.GasSupply.Consumption.Amount === 0 )">Please enter the annual usage</span>
          </div>
        </div>
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Old Rate (pence/kWh)</label>
            <input type="number" class="form-control" v-model="formData.quoteDetails.OldRate" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Standing Charge (pence/day)</label>
            <input type="number" class="form-control" v-model="formData.quoteDetails.StandingCharge" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Current Cost (£)</label>
            <input type="number" class="form-control" v-model="formData.quoteDetails.CurrentCost" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Contract details</h5>
      <div class="row">
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Payment Type</label>
            <Field as="select" name="new_supplier_id" class="form-select" v-model="formData.quoteDetails.PaymentMethod">
              <option v-for="res in dropdownOptions.contract_payment_type" :key="res.value" :value="res.text">
                {{ res.text }}
              </option>
            </Field>
          </div>
        </div>
        <div class="col-md-4" ref="dropdownContainer">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Current Supplier <span class="text-danger">*</span></label>
            <Field name="supplier_id" type="text" class="form-control" placeholder="Search supplier name" v-model="searchQuery" @keydown.enter="searchSupplier" @input="onInput" @focus="showDropdown" />
            <ul v-if="filteredSuppliers.length" class="dropdown-menu supplier-data shadow show pe-0">
              <li class="dropdown-listing">
                <ul>
                  <li v-for="res in filteredSuppliers" :key="res.name" @click="selectSupplier(res)" class="dropdown-item" style="cursor: pointer">
                    {{ res.name }}
                  </li>
                </ul>
              </li>
            </ul>
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.CurrentSupplier === '')">Current supplier is required</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Renewal Date </label>
            <div class="icon-form">
              <date-picker textInput placeholder="" class="datetimepicker form-control" :editable="true" :clearable="true" :input-format="dateFormat" v-model="formData.quoteDetails.GasSupply.ContractRenewalDate" />
              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
            </div>
            <span class="text-danger" v-if="isNotValid && formData.quoteDetails.GasSupply.ContractRenewalDate && (new Date(formData.quoteDetails.GasSupply.ContractRenewalDate) <= new Date())">Renewal date should be greater than current date.</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Unit Rate Uplift</label>
            <Field as="select" name="uplift" class="form-select" v-model="formData.uplift_text" >
              <option v-for="res in unitRateUplift" :key="res.value" :value="res.value">
                {{ res.text }}
              </option>
            </Field>
          </div>
        </div>
        <div v-if="formData.uplift_text !== 'maximum' && formData.uplift_text !== 'minimum'" class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Invariable</label>
            <input type="text" class="form-control" v-model="invarUplift" @change="changeUplift"/>
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.Uplift === null) ">Unit Rate Uplift is required</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Email</label>
            <input type="email" class="form-control" v-model="formData.quoteDetails.Email" />
             <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.Email === '') ">Email is required</span>
             <span class="text-danger" v-if="validEmail && validEmail['formData.email'] && validEmail['formData.email'].length">
                            {{ validEmail['formData.email'][0]}}
                          </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Additional details</h5>
      <div class="row">
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Credit Score</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.Settings[1].value" />
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.Settings[1].value === '0')">Credit Score is required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<look-up-modal :formData="formData" :isVisible="isModalVisible" :searchPostcode="selectedPostcode" :selectedLabel="selectedLabel" @close="closeModal"></look-up-modal>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    isNotValid: {
      type: Boolean,
      default: false,
    },
    gasOppId: {
      type: Number,
      default: null,
    },
    validEmail: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      dateFormat: "dd/MM/yyyy",
    };
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const apiErrors = ref(null);
    const filteredSuppliers = ref([]);
    const searchQuery = ref("");
    const dropdownContainer = ref(null);
    const selectedPostcode = ref("");
    const selectedLabel = ref("");
    const isModalVisible = ref(false);
    const opp_Id = ref(route.query.opp_Id);
    const invarUplift = ref('0.2');
    const upliftValue = ref(null);
    const unitRateUplift = ref([
      {value: "maximum", text: "Maximum"},{value: "minimum", text: "Minimum"},{value: 'invariable', text: "Invariable"}
    ])

    const supplierOptions = computed(() => store.getters["price/getSuppliers"]);
    const dropdownOptions = computed(() => store.getters["price/getDropdowns"]);
    const quotingFormData = computed(() => store.getters["opportunity/getgasformData"]);

    const formData = ref({
      mainProductService: "gas",
      uplift_text: 'maximum',
      quoteDetails: {
        PostCode: "",
        Uplift: invarUplift.value || '0.2',
        PaymentMethod: "Monthly Direct Debit",
        CurrentSupplier: "",
        OldRate: "0",
        StandingCharge: "0",
        CurrentCost: "0",
        EnableQuoteEmail: false,
        QuoteReference: "",
        Email: "",
        BusinessType: "LTD",
        GasSupply: {
          Consumption: {
            Amount: 0,
            Type: "Day",
          },
          ContractRenewalDate: null,
          MPR: "",
          NewContractEndDate: "",
          SmartMeter: "false",
        },
        Settings: [{
            key: "upliftBehaviour",
            value: "closest"
          },
          {
            key: "CreditScore",
            value: ""
          },
          {
            key: "ShowNoQuoteReason",
            value: "True"
          }
        ],
      },
    });

    // Function to calculate CurrentCost on blur
    const calculateCurrentCost = () => {
      // old rate annual price calculation
      const annualPrice = parseFloat(formData.value.quoteDetails.GasSupply.Consumption.Amount) || 0;
      const oldRate = parseFloat(formData.value.quoteDetails.OldRate) || 0;
      const annual_rate = (annualPrice * oldRate) / 100;

      // old rate annual price calculation
      const standingCharge = parseFloat(formData.value.quoteDetails.StandingCharge) || 0;
      const statngCalc = (standingCharge * 365) / 100;

      // calculate current rate
      formData.value.quoteDetails.CurrentCost = (annual_rate + statngCalc).toFixed(2);
    };

    const onInput = () => {
      searchSupplier();
    };

    const searchSupplier = () => {
      const query = searchQuery.value.toLowerCase();
      if (query) {
        filteredSuppliers.value = supplierOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          return nameMatch;
        });
      } else {
        // If the query is empty, hide the dropdown
        filteredSuppliers.value = [];
      }
    };

    const showDropdown = () => {
      // Show all suppliers when the input is focused (clicked)
      if (!searchQuery.value) {
        filteredSuppliers.value = supplierOptions.value;
      }
    };

    const selectSupplier = (supplier) => {
      formData.value.quoteDetails.CurrentSupplier = supplier.name;
      searchQuery.value = supplier.name;

      filteredSuppliers.value = [];
    };

    const openModal = (code, label) => {
      selectedPostcode.value = code; // Assign the clicked value to selectedPostcode
      selectedLabel.value = label;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };
    const hideDropdown = () => {
      filteredSuppliers.value = [];
    };

    const handleClickOutside = (event) => {
      // Check if the click happened outside the dropdown container
      if (
        dropdownContainer.value &&
        !dropdownContainer.value.contains(event.target)
      ) {
        hideDropdown();
      }
    };
    const emitFormData = async () => {
      emit("submit-form", formData.value); // If valid, emit form data
    };
    const fetchOppData = async (id) => {
  const data = await store.dispatch('opportunity/fetchOpportunity', id);
  if (Object.keys(data).length > 0) {
    formData.value.quoteDetails.PostCode = data.supply_address.postcode;
    formData.value.quoteDetails.PaymentMethod = data.organization.payment_type || 'Monthly Direct Debit';
    formData.value.quoteDetails.GasSupply.MPR = data.mpr;
    formData.value.quoteDetails.GasSupply.Consumption.Amount =
      data.forecast_commission.sc_forecast_or_amended_annual_consumption;
    formData.value.quoteDetails.GasSupply.ContractRenewalDate =
      new Date(data.contract_start_date) || '';

    upliftValue.value = data.forecast_commission.u4b_uplift || '';
    formData.value.quoteDetails.Uplift = upliftValue.value;
    formData.value.uplift_text = upliftValue.value;
    formData.value.uplift_text = 'invariable';

    searchQuery.value = filterSupplier(data.supplier_id);
    formData.value.quoteDetails.CurrentSupplier = searchQuery.value;
    formData.value.quoteDetails.Email = data.sales_contact_email || '';
  }
};
    const filterSupplier = (supplier_id) => {
      const supplier = supplierOptions.value.find(s => s.id === supplier_id);
      return supplier ? supplier.name : "";
    };

    const changeUplift = (event) =>{
      const change_uplift = event.target.value;
        upliftValue.value = change_uplift;
      formData.value.uplift_text = 'invariable';
      formData.value.quoteDetails.Uplift = change_uplift;
    }
    watch(
      () => props.gasOppId,
      (newValue) => {
        if (newValue) {
          fetchOppData(newValue);
        }
      }
    );

    watch(
  () => formData.value.quoteDetails.CurrentSupplier,  
  (newValue) => {
    searchQuery.value = newValue;  
  },{immediate:true}
);
    
    onMounted(async () => {
      emitFormData();
      document.addEventListener("click", handleClickOutside);
      store.dispatch("price/fetchSuppliers");
      if (route.query.opp_Id) {
        fetchOppData(route.query.opp_Id)
      }
    });
    return {
      filterSupplier,
      changeUplift,
      upliftValue,
      apiErrors,
      unitRateUplift,
      calculateCurrentCost,
      quotingFormData,
      emitFormData,
      selectedLabel,
      selectedPostcode,
      openModal,
      closeModal,
      isModalVisible,
      filteredSuppliers,
      dropdownContainer,
      handleClickOutside,
      hideDropdown,
      showDropdown,
      searchSupplier,
      searchQuery,
      selectSupplier,
      onInput,
      formData,
      supplierOptions,
      dropdownOptions,
      opp_Id,
      invarUplift
    };
  },
};
</script>

<style scoped>
.tab-row-data {
  flex-wrap: inherit;
}

.supplier-data {
  width: 245px;
  max-height: 350px;
  overflow: auto;
}

.form-control, .form-wrap span, .col-form-label{
  font-size: 12px;
}

.not-valid {
  border-color: #FC0027;
}
</style>
