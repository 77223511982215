<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper meter-opp">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="contact-head">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                    <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: terId, org_Id: OrgId, for: 'contracts' } }">
                      <i class="ti ti-arrow-narrow-left"></i>
                      Organisation
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/crm/contracts-list">
                      <i class="ti ti-arrow-narrow-left"></i>
                      List
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-body settings-form">
                  <Form @submit="createContract" :validation-schema="schema">
                    <div class="row align-items-center mb-3">
                        <div class="col-6">
                          <h4> Create Contract</h4>
                        </div>
                      </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Organisation <span class="text-danger">*</span></label>
                          <div class="icon-form">
                            <Field name="organisation_id" type="text" class="form-control"
                              placeholder="Search organisation name / postcode" v-model="searchQuery"
                              @keyup="searchHandle" @focus="onFocus" @blur="onBlur" />
                            <span class="form-icon">
                              <i class="ti ti-building-community"></i>
                            </span>
                          </div>
                          <ul v-if="isFocused && !isOrganisationSelected" class="dropdown-menu shadow show pe-0"
                            style="width: 97%;">
                            <!-- Display search results if there are matches -->
                            <li v-if="filteredOrganisations.length" class="dropdown-listing" @scroll="onScrollEnd">
                              <ul>
                                <li v-for="organisation in filteredOrganisations" :key="organisation.id"
                                  @click="selectOrganisation(organisation)" class="dropdown-item"
                                  style="cursor: pointer;">
                                  {{ organisation.name + ' ' + (organisation.postcode || '') }}
                                </li>
                              </ul>
                            </li>

                            <!-- 'Add New Organization' link -->
                            <li class="px-3">
                              <router-link class="text-primary fw-bold"
                                :to="{ path: '/crm/create-organisation', query: { add: 'contract' } }">
                                Add New Organisation
                              </router-link>
                            </li>
                          </ul>
                          <ErrorMessage name="organisation_id" class="text-danger" />
                          <span class="text-danger" v-if="notValid">Organisation does not exist</span>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="form.organization_id">
                        <div class="form-wrap">
                          <label class="col-form-label">Person </label>
                          <div class="icon-form">
                            <Field name="organization_person_id" type="text" class="form-control"
                              placeholder="Search person name" v-model="searchPersonQuery" @input="onPersonInput" @focus="onFocusPerson" @blur="onBlurPerson" @keyup="searchPersons" />

                            <span class="form-icon">
                              <i class="ti ti-user"></i>
                            </span>
                          </div>

                          <!-- Dropdown for search results -->
                          <ul v-if="isPersonFocused && !isPersonSelected"
                            class="dropdown-menu shadow show pe-0" style="width: 97%;">
                            <li v-if="filteredPersons?.length" class="dropdown-listing">
                              <ul>
                                <li v-for="person in filteredPersons" :key="person.id" @click="selectPerson(person)"
                                  class="dropdown-item" style="cursor: pointer;">
                                  {{ person.name }}
                                </li>
                              </ul>
                            </li>

                            <li v-else class="dropdown-item text-muted">
                              No person found
                            </li>

                            <li class="px-3" v-if="(companyType === 'Partnership') || (companyType !== 'Partnership' && filteredPersons.length === 0) || (filteredPersons.length !== 0 && companyType === 'Partnership')">
                              <router-link class="text-primary fw-bold"
                                :to="{ path: '/crm/create-organisation', query: { add: 'contract', org_Id: form.organization_id } }">
                                Add New Person
                              </router-link>
                            </li>
                          </ul>

                          <!-- Error message -->
                          <ErrorMessage name="organization_person_id" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Date of Sale <span class="text-danger">*</span></label>
                          <div class="icon-form">
                            <date-picker placeholder="" textInput v-model="form.date_of_sale" class="datetimepicker form-control"
                              :editable="true" :clearable="true" :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Responsible</label>
                          <select id="userRes" class="form-select" v-model="form.contract_responsible"
                            :disabled="saleResponsibleOptions.length === 1">
                            <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Description</label>
                          <textarea class="form-control" rows="1" v-model="form.contract_description"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="submit-button text-end">
                      <!-- <router-link to="/crm/contracts-list" class="btn btn-light">Back</router-link> -->
                      <button type="button" class="btn btn-light" @click="handleCancel">
                        Back
                      </button>
                      <button type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import apiClient from "@/axios";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import $ from 'jquery';

import {
  Form,
  Field,
  ErrorMessage,
  useForm
} from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';
import { changeFormate } from '../../../../util'
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      dateFormat: "dd/MM/yyyy",
      title: "Settings",
      availStatus: [
        'No', 'Yes'
      ],
      loginSmsCode: ['always']
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isOrganisationSelected = ref(true);
    const isPersonSelected = ref(false);
    const isFocused = ref(false);
    const isPersonFocused = ref(false);
    const notValid = ref(false);
    const isFetching = ref(false);
    const current_page = ref(1);
    const OrgId = ref(route.query.org_Id || null)
    const terId = ref(route.query.ter_Id || null)
    // const responsibleUsers = ref([]);
    const apiErrors = ref(null);
    const companyType = ref(null)
    const searchQuery = ref('');
    const searchPersonQuery = ref('');
    const filteredPersons = ref([]);
    const orgOptions = computed(() => store.getters['opportunity/getorgOtionsData']);
    const Options = computed(() => store.getters['contract/getcontractOptions']);
    const orgPersons = ref([]);
    const saleResponsibleOptions = computed(() => store.getters['organisation/getresUsersOptions']);

    const filteredOrganisations = ref([]);

    const onFocus = () => {
      isFocused.value = true
    };

    const onFocusPerson = () => {
      isPersonFocused.value = true
      searchPersons();
    };

    const onBlur = () => {
      setTimeout(() => {
        isFocused.value = false; // Hide dropdown on blur
      }, 200);
    };

    const onBlurPerson = () => {
      setTimeout(() => {
        isPersonFocused.value = false; // Hide dropdown on blur
      }, 200);
    };
    const onPersonInput = () => {
      isPersonSelected.value = false;
      searchPersons();
    };

    let searchTimeout;

    const searchHandle = () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => {
        searchOrganisations(); 
      }, 300);
    };

    const searchPersons = () => {
      const query = searchPersonQuery.value.toLowerCase();
      if (query) {
        filteredPersons.value = orgPersons.value.filter(person =>
          person.name.toLowerCase().includes(query)
        );

      } else {
        filteredPersons.value = orgPersons.value;
      }
    };

    const selectPerson = (person) => {
      form.value.organization_person_id = person.id;
      searchPersonQuery.value = person.name;
      filteredPersons.value = [];
      isPersonSelected.value = true;
    };

    const appendDynamicData = (existingData, dynamicData) => {
      const combinedOutput = existingData.slice(); // Clone the existing output array
      dynamicData.forEach((item) => {
        combinedOutput.push(item);
      });

      filteredOrganisations.value = combinedOutput;

      return { data: combinedOutput };
    };

    const total = ref()

    const searchOrganisations = async () => {
      try {
        const query = searchQuery.value.toLowerCase();

        if (query && query.length >= 2) {
          if (isFetching.value) return;
          isFetching.value = true;
          let response;
          if (current_page.value === 1) {
            response = await store.dispatch("opportunity/getorgOptions", { search: query, page: current_page.value });
            filteredOrganisations.value = response.data.data
            current_page.value = response.data.current_page + 1;
            total.value = response.data.total;
            isFocused.value = true;
            isOrganisationSelected.value = false;
            return;
          }
          let count = filteredOrganisations.value.length;
          if (count >= 10 && total.value > count) {
            current_page.value = Math.ceil(count / 10) + 1;
            const params = {
              search: query,
              page: current_page.value
            };
            response = await store.dispatch("opportunity/getorgOptions", params);
            appendDynamicData(filteredOrganisations.value, response.data.data);
            current_page.value = response.data.current_page + 1;
            isFocused.value = true;
            isOrganisationSelected.value = false;
            return;
          }
        } 
        // else {
        //   filteredOrganisations.value = orgOptions.value.data;
        //   isFocused.value = true
        // }

      } catch (error) {

      }
      finally {
        isFetching.value = false;
      }
    };


    const onScrollEnd = (event) => {
      const element = event.target;
      if (element.scrollTop + element.clientHeight >= element.scrollHeight - 3 && !isFetching.value) {
        searchOrganisations()
      }
    };

    const selectOrganisation = async (organisation) => {
      form.value.organization_id = organisation.id;
      companyType.value = organisation.type;
      searchQuery.value = (organisation.name) + ' ' + (organisation.postcode || '');
      orgPersons.value = await store.dispatch("opportunity/fetchPersons", organisation.id);
      if(orgPersons.value.length === 1){
        selectPerson( orgPersons.value[0])
      }
      store.dispatch('organisation/fetchTerritoryCompanyUsers', organisation.territory_id)
      filteredOrganisations.value = [];
      isOrganisationSelected.value = true;
    };

    const schema = Yup.object().shape({
      organisation_id: Yup.string()
        .required("Organisation is required"),
    });

    const form = ref({
      organization_id: null,
      organization_person_id: null,
      contract_responsible: null,
      date_of_sale: new Date(),
      contract_description: '',
    });

    const selectedPerson = ref(null);

    const createContract = async () => {
      if(form.value.organization_id === null){
        notValid.value = true;
        return;
      }
      const data = await store.dispatch('contract/createContract', { ...form.value, date_of_sale: changeFormate(form.value.date_of_sale) });
      if (data.status) {
        handleCancel();
      }

    };
    const handleCancel = () => {
      const query = route.query.add;
      if (query === "org-details") {
        router.push({
          path: "/crm/organisation-details",
          query: {
            org_Id: route.query.org_Id,
            ter_Id: route.query.ter_Id,
            for: 'contracts'
          },
        });
      } else if (query === "activity") {
        router.push({
          path: '/crm/edit-activity',
          query: {
            add: 'org-details',
            ter_Id: route.query.ter_Id,
            org_Id: route.query.org_Id,
            actId: route.query.actId,
          },
        });
      } else {
        router.push("/crm/contracts-list");
      }
    };
    const fetchResponsibles = async (role) => {
      if (role === 'role') {
        form.value.territories = [];
      }

      const selectedRole = form.value.role;
      let selectedTerritoryIds = [];
      const selectedTer = form.value.territories;

      // Determine if multiple selections are allowed
      if (isMultiple.value && Array.isArray(selectedTer)) {
        selectedTerritoryIds = selectedTer.map(territory => territory.id);
      } else if (!isMultiple.value && selectedTer && selectedTer.id) {
        selectedTerritoryIds = [selectedTer.id];
      }

      if (selectedRole !== '') {
        try {
          const response = await apiClient.get('/dropdown/company/assigned-managers', {
            params: {
              role: selectedRole,
              territory_ids: selectedTerritoryIds.length > 0 ? selectedTerritoryIds : []
            }
          });
          responsibleUsers.value = response.data.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }));
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };
    // Watch for changes to selectedPerson
    watch(selectedPerson, (newVal) => {
      if (newVal) {
        form.value.organization_person_id = newVal.id; // Assign only the ID
      } else {
        form.value.organization_person_id = null; // Clear the ID if nothing is selected
      }
    });

    watch(searchQuery, (newQuery) => {
      if (!newQuery) {
        isOrganisationSelected.value = false;
      } else{
        total.value=null
        current_page.value=1
      }
    });
    watch(searchPersonQuery, (newQuery) => {
      if (!newQuery) {
        isPersonSelected.value = false;
      }
    });
    onMounted(async () => {
      const orgId = route.query.org_Id;
      if (orgId) {
        // const defaultOrganisation = orgOptions.value.find(org => org.id === orgId);
        // if (defaultOrganisation) {
        //   selectOrganisation(defaultOrganisation);
        // }
        const data = await store.dispatch("organisation/fetchOrgData", orgId);
        if (data) {
          selectOrganisation(data);
        }
      }
      // store.dispatch('opportunity/getorgOptions');
      store.dispatch('contract/fetchContractOptions');
    });

    return {
      schema,
      form,
      orgOptions,
      Options,
      handleCancel,
      searchQuery,
      filteredOrganisations,
      searchOrganisations,
      selectOrganisation,
      createContract,
      OrgId,
      terId,
      companyType,
      fetchResponsibles,
      orgPersons,
      saleResponsibleOptions,
      apiErrors,
      selectedPerson,
      isOrganisationSelected,
      isPersonSelected,
      searchPersons,
      selectPerson,
      onPersonInput,
      searchPersonQuery,
      notValid,
      filteredPersons,
      onFocus,
      searchPersonQuery,
      isFocused,
      onBlur,
      onBlurPerson,
      onFocusPerson,
      isPersonFocused,
      searchHandle,
      onScrollEnd,
      isFetching,
      current_page,
      total,
      appendDynamicData
    };
  },
  watch: {
    saleResponsibleOptions: {
      immediate: true,
      handler(newOptions) {
        if (newOptions.length === 1) {
          this.form.contract_responsible = newOptions[0].id;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.meter-opp {
  .card {
    .card-body {
      min-height: 80vh;
    }
  }
}

.dropdown-listing {
  overflow: auto;
  max-height: 25vh;
}
</style>
