<template>
<div>
  <div class="view-header">
    <h4>{{ orgOpportunities.length }} Meter Opportunities</h4>
    <ul>
      <li>
        <router-link class="btn btn-primary p-1" :to="{ path: '/crm/create-meter-opportunity', query: { add: 'org-details', org_Id: orgId, ter_Id: ter_Id }}">
          <i class="ti ti-plus"></i>
        </router-link>
      </li>
    </ul>
  </div>

  <div class="notes-activity" v-if="orgOpportunities.length > 0">
    <div class="calls-box" v-for="item in orgOpportunities" :key="item.id">
      <div class="caller-info">
        <div class="calls-user">
          <div class="opp-icon me-2">
            <i class="ti ti-chart-arcs"></i>
          </div>
          <div>

            <p title="Date of sale">
              <router-link class="text-teal" :to="{ path: '/crm/meter-opportunities-edit', query: {add: 'org-details', ter_Id: ter_Id, org_Id: orgId, opp_Id: item.id, for: 'opportunities' }}">
                {{ formatDate(item.sale_date) }}
              </router-link>
            </p>
            <h6 title="Opportunity nr auto">{{ item.meter_opportunity_nr_auto }}</h6>
            <p title="Sale Responsible Initials">{{ item.sale_responsible_initials }}</p>
          </div>
        </div>
        <div class="calls-action">
          <router-link class="text-teal" :to="{ path: '/crm/meter-opportunities-edit', query: {add: 'org-details', ter_Id: ter_Id, org_Id: orgId, opp_Id: item.id, for: 'opportunities' }}">
            <i class="ti ti-edit text-blue"></i>
          </router-link>
        </div>
      </div>
      <h5 class="org-title">{{ item.contact_person }}</h5>
      <div class="row">
        <div class="col-md-4">
          <p><b>Pipeline level : </b> {{ item.pipeline_level }}</p>
        </div>
        <div class="col-md-4">
          <p><b>SC Forecast Total Commission : </b>{{ item.sc_forecast_total_commission }}</p>
        </div>
        <div class="col-md-4">
          <p><b>*Main product/service :</b> {{ item.main_production_service }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6>Description :</h6>
          <p>
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row notes-activity" v-else>
    <div class="col-12 bg-light">
      <p class="text-center "><i class="ion-information-circled"></i> No data found</p>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import apiClient from '@/axios';

export default {
  // props: {
  //   terId: {
  //     type: String,
  //   }
  // },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const orgId = ref(null);
    const ter_Id = ref(route.query.ter_Id || null);
    const orgOpportunities = ref([]);

    const fetchOpp = async (id) => {
      try {
        const response = await apiClient.get(`/opportunities?organization_id=${id}`);
        orgOpportunities.value = response.data.data;
      } catch (error) {
        console.error('Error fetching opportunity data:', error);
      }
    }
     // Function to convert date format
   const formatDate = (date) => {
    if(date){
      const [day, month, year] = date.split('-');
      return `${day}/${month}/${year}`;
    }
    };

    onMounted(() => {
      orgId.value = route.query.org_Id;
      fetchOpp(orgId.value);
    });

    return {
      orgOpportunities,
      formatDate,
      orgId,
      ter_Id,
      fetchOpp
    };
  },
};
</script>

<style lang="scss" scoped>
.org-title{
  text-transform: capitalize;
}
.opp-icon {
  background-color: #E41F07;
  color: #FFF;
  border: 1px solid #E41F07;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
</style>
