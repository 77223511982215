<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <Form @submit="handleSubmit" :validation-schema="schema" class="toggle-height">
                        <div class="contact-head">
                            <div class="row align-items-center">
                                <div class="col-sm-6">
                                    <ul class="contact-breadcrumb">
                                        <li>
                                            <button type="submit" class="custom-header-button">
                                                <i class="ti ti-check"></i>
                                                Save
                                            </button>
                                        </li>
                                        <li v-if="isEditing">
                                            <router-link :to="{
                                                path: '/crm/organisation-details',
                                                query: { ter_Id: formData.territory_id, org_Id: org_id },
                                            }"><i class="ti ti-arrow-narrow-left"></i>Organisation</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/crm/organisation-list"><i
                                                    class="ti ti-arrow-narrow-left"></i>List</router-link>
                                        </li>
                                        <!-- <li>
                                        {{ isEditing ? "Edit Organisation" : "Create New Organisation" }}
                                    </li> -->
                                    </ul>
                                </div>
                                <div class="col-sm-6 text-end">
                                    <!-- <ul class="contact-breadcrumb">
                                        <li>
                                            <router-link :to="{
                                                path: '/crm/person-list',
                                                query: { org_Id: org_id },
                                            }"><span style="font-weight: 600; font-size: 20px;">Persons<i
                                                        class="ti ti-arrow-narrow-right ms-1"></i></span></router-link>
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12">
                                <div class="card">
                                    <div class="card-body settings-form">
                                        <!-- <Form @submit="handleSubmit" :validation-schema="schema" class="toggle-height"> -->
                                        <div class="settings-sub-header">
                                            <div class="row align-items-center">
                                                <div class="col-6">
                                                    <h4>
                                                        {{ isEditing ? "Edit Organisation" : "Create Organisation" }}
                                                    </h4>
                                                </div>
                                                <div class="col-6 text-end">
                                                    <!-- <router-link v-if="isEditing" class="btn btn-primary" :to="{
                                                        path: '/crm/create-person',
                                                        query: { org_Id: org_id },
                                                    }">
                                                        <i class="ti ti-square-rounded-plus me-1"></i>Add Person
                                                    </router-link> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pro-create">
                                            <div class="row">
                                                <div class="col-md-12" v-if="isEditing">
                                                    <div class="form-wrap">
                                                        <div class="profile-upload">
                                                            <div class="profile-upload-img">
                                                                <span v-if="!imagePreview"><i
                                                                        class="ti ti-photo"></i></span>
                                                                <img v-if="imagePreview" :src="imagePreview"
                                                                    class="img-fluid" />
                                                                <button v-if="imagePreview" type="button"
                                                                    class="profile-remove" @click="removeImage">
                                                                    <i class="feather-x"></i>
                                                                </button>
                                                            </div>
                                                            <div class="profile-upload-content">
                                                                <label class="profile-upload-btn">
                                                                    <i class="ti ti-file-broken"></i> Upload File
                                                                    <input type="file" class="input-img"
                                                                        @change="handleImageUpload" />
                                                                </label>
                                                                <p>
                                                                    Upload Logo of your organisation (In jpeg, png,
                                                                    jpg).
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger" v-if="
                                                            apiErrors && apiErrors['logo'] && apiErrors['logo'].length
                                                        ">
                                                            {{ apiErrors["logo"][0] }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="organisation col-md-12">
                                                    <div class="row">

                                                        <div class="col-md-5 pe-1">
                                                            <!-- ORGANISATION DETAILS -->
                                                            <div class="row organisation-detail mx-0">
                                                                <h4 class="pb-4 text-center">ORGANISATION DETAILS</h4>
                                                                <div class="col-md-6">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Organisation Name
                                                                            <span class="text-danger">*</span></label>
                                                                        <Field name="name" as="input" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.name" />
                                                                        <ErrorMessage name="name" class="text-danger" />
                                                                        <span class="text-danger"
                                                                            v-if="apiErrors && apiErrors['name'] && apiErrors['name'].length">
                                                                            {{ apiErrors["name"][0] }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company
                                                                            Type</label>
                                                                        <Field as="select" name="type" id="type"
                                                                            v-model="formData.type" class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in options.org_type"
                                                                                :key="res.value" :value="res.value">{{
                                                                                    res.value
                                                                                }}</option>
                                                                        </Field>
                                                                        <ErrorMessage name="type" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.type === 'Charity'"
                                                                    class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Charity
                                                                            No:<span
                                                                                class="text-danger">*</span></label>
                                                                        <Field name="charity_reg_no" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.charity_reg_no" />
                                                                        <ErrorMessage name="charity_reg_no"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.type !== 'Soletrader' && formData.type !== 'Partnership' && formData.type !== 'Church/community organisation' && formData.type !== 'Charity'"
                                                                    class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company Reg
                                                                            No:</label>
                                                                        <Field name="company_reg_no" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.company_reg_no" />
                                                                        <ErrorMessage name="company_reg_no"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label
                                                                            class="col-form-label truncate-text">Company
                                                                            Incorporated Date</label>
                                                                        <div class="icon-form">
                                                                            <date-picker text-input placeholder=""
                                                                                class="datetimepicker form-control"
                                                                                v-model="formData.company_incorporated_date"
                                                                                :editable="true" :clearable="true"
                                                                                :input-format="dateFormat"
                                                                                @update:modelValue="clearDate" />
                                                                            <span class="form-icon"><i
                                                                                    class="ti ti-calendar-check"></i></span>
                                                                        </div>
                                                                        <!-- <ErrorMessage name="company_incorporated_date" class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Customer Status
                                                                        </label>
                                                                        <select id="status" v-model="formData.status"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="res in options.org_account_status"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Customer Value
                                                                        </label>
                                                                        <Field name="customer_value" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.customer_value" />
                                                                        <!-- <ErrorMessage name="customer_value"
                                                                            class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Territory <span
                                                                                class="text-danger">*</span></label>
                                                                        <Field id="territory" name="territory"
                                                                            v-model="formData.territory_id"
                                                                            class="form-select" as="select">
                                                                            <option value=""
                                                                                v-if="Territories.length > 1">
                                                                                -----Select-----</option>
                                                                            <option v-for="ter in Territories"
                                                                                :key="ter.id" :value="ter.id">
                                                                                {{ ter.name }}
                                                                            </option>
                                                                        </Field>
                                                                        <ErrorMessage name="territory"
                                                                            class="text-danger" />
                                                                        <span class="text-danger" v-if="
                                                                            apiErrors &&
                                                                            apiErrors['territory'] &&
                                                                            apiErrors['territory'].length
                                                                        ">
                                                                            {{ apiErrors["territory"][0] }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Responsible
                                                                        </label>
                                                                        <select id="responsible"
                                                                            v-model="formData.responsible"
                                                                            class="form-select">
                                                                            <option value="" v-if="Responsibles.length > 1">-----None-----</option>
                                                                            <option v-for="res in Responsibles"
                                                                                :key="res" :value="res.id">
                                                                                {{ res.name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Credit Score
                                                                        </label>
                                                                        <Field name="credit_score" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.credit_score" />
                                                                        <!-- <ErrorMessage name="credit_score"
                                                                            class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company Sector
                                                                        </label>

                                                                        <select id="company_sector"
                                                                            v-model="formData.company_sector"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_customer_sector"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Turnover
                                                                        </label>

                                                                        <select id="turnover"
                                                                            v-model="formData.turnover"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_turnover"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Net Worth
                                                                        </label>

                                                                        <select id="net_worth"
                                                                            v-model="formData.net_worth"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_net_worth"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Building Use
                                                                        </label>

                                                                        <select id="building_use"
                                                                            v-model="formData.building_use"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_building_use"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">No of
                                                                            Employees</label>
                                                                        <select id="no_of_employees"
                                                                            v-model="formData.no_of_employees"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="emp in options.org_no_of_emp"
                                                                                :key="emp" :value="emp.value">
                                                                                {{ emp.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Website Url
                                                                        </label>
                                                                        <Field name="website_url" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.website_url" />
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT Declaration
                                                                        </label>

                                                                        <select id="vat_declaration"
                                                                            v-model="formData.vat_declaration"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option value="Y">Yes</option>
                                                                            <option value="N">NO</option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT %
                                                                        </label>
                                                                        <Field name="vat_percent" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.vat_percent" />
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT no.</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.vat_no" />


                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <input type="checkbox"
                                                                            class="form-check-input me-2"
                                                                            id="micro_buisness" :true-value="1"
                                                                            :false-value="0"
                                                                            v-model="formData.micro_buisness" />
                                                                        <label class="form-check-label"
                                                                            for="email_bounces">Micro
                                                                            Business</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <input type="checkbox"
                                                                            class="form-check-input me-2"
                                                                            id="micro_buisness" :true-value="1"
                                                                            :false-value="0"
                                                                            v-model="formData.main_address" />
                                                                        <label class="form-check-label"
                                                                            for="email_bounces">Main
                                                                            Address</label>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Postcode</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.postcode" />
                                                                        <span class="text-danger"
                                                                            v-if="apiErrors && apiErrors['postcode'] && apiErrors['postcode'].length">
                                                                            {{ apiErrors["postcode"][0] }}
                                                                        </span>
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Email
                                                                            Domain</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.email_domain" />
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Telephone</label>
                                                                        <Field name="telephone" as="input" type="text"
                                                                            v-model="formData.telephone"
                                                                            class="form-control" />
                                                                        <ErrorMessage name="telephone"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Alert text</label>
                                                                        <Field name="alert_text" as="input" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.alert_text" />
                                                                        <ErrorMessage name="alert_text"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-6">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">www</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.www" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Fax</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.fax" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-0">
                                                                        <label class="col-form-label">Comment </label>
                                                                        <textarea class="form-control" rows="1"
                                                                            v-model="formData.comment"></textarea>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">ERP ID </label>
                                                                            <input type="text" class="form-control"
                                                                                v-model="formData.erp_id" />
                                                                        </div>
                                                                    </div> -->
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Org URN</label>
                                                                        <div class="icon-form-end">
                                                                            <input type="text" class="form-control"
                                                                                placeholder=""
                                                                                v-model="formData.org_urn" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Original Lead
                                                                            Source</label>
                                                                        <select id="leadSource"
                                                                            v-model="formData.original_lead_source"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_lead_source"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">SIC code </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.sic_code" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">SIC
                                                                            Description</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.sic_description" />
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT group </label>
                                                                        <select id="vatgroup"
                                                                            v-model="formData.vat_group"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_vat_group"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Industry </label>
                                                                        <select id="industry"
                                                                            v-model="formData.industry"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in options.org_industry"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Import ID </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.charity_import_id" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Lead Source
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.charity_lead_source" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Duplicate Batch
                                                                        </label>
                                                                        <select id="duplicateBatch"
                                                                            v-model="formData.charity_duplicate_batch"
                                                                            class="form-select">
                                                                            <option value="">--</option>
                                                                            <option
                                                                                v-for="source in options.org_duplicate_batch"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Flag No Meter
                                                                            Opp</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.flag_no_meter_opp" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label truncate-text">Org
                                                                            Reallocation
                                                                            Marker</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.org_rellocation_marker" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Purchased Data
                                                                            Id</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.purchased_data_id" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Purchased Data
                                                                            Source
                                                                        </label>
                                                                        <select id="purchased_data_source"
                                                                            v-model="formData.purchased_data_source"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="res in options.purchased_data_source"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Allocation Batch
                                                                            ID</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.allocation_batch_id" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Outlook
                                                                            Sync</label>
                                                                        <select id="outlook_sync"
                                                                            v-model="formData.outlook_sync"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in options.outlook_sync"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col px-1">
                                                            <!-- ORGANISATION ADDRESS -->
                                                            <div class="row organisation-detail mx-0">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <h4 class="pb-4 text-center">ORGANISATION
                                                                            ADDRESS
                                                                        </h4>
                                                                        <div class="col-md-12">
                                                                            <div class="form-wrap">
                                                                                <label class="col-form-label">Address
                                                                                    Line
                                                                                    1</label>
                                                                                <Field as="textarea" name="address"
                                                                                    class="form-control" rows="1"
                                                                                    v-model="formData.address"></Field>
                                                                                <ErrorMessage name="address"
                                                                                    class="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-wrap">
                                                                                <label class="col-form-label">Address
                                                                                    Line
                                                                                    2</label>
                                                                                <Field as="textarea"
                                                                                    name="address_line_2"
                                                                                    class="form-control" rows="1"
                                                                                    v-model="formData.city"></Field>
                                                                                <!-- <ErrorMessage name="address_line_2"
                                                                                class="text-danger" /> -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-wrap">
                                                                                <label class="col-form-label">Address
                                                                                    Line
                                                                                    3</label>
                                                                                <Field as="textarea"
                                                                                    name="address_line_3"
                                                                                    class="form-control" rows="1"
                                                                                    v-model="formData.state"></Field>
                                                                                <!-- <ErrorMessage name="address_line_3"
                                                                                class="text-danger" /> -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-wrap">
                                                                                <label class="col-form-label">Address
                                                                                    Line
                                                                                    4</label>
                                                                                <Field as="textarea"
                                                                                    name="address_line_4"
                                                                                    class="form-control" rows="1"
                                                                                    v-model="formData.address_line_4">
                                                                                </Field>
                                                                                <!-- <ErrorMessage name="address_line_4"
                                                                                class="text-danger" /> -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-wrap">
                                                                                <label class="col-form-label">Postcode
                                                                                    <span
                                                                                        class="text-danger">*</span></label>
                                                                                <Field as="input" name="postcode"
                                                                                    class="form-control"
                                                                                    v-model="formData.postcode">
                                                                                </Field>
                                                                                <ErrorMessage name="postcode"
                                                                                    class="text-danger" />
                                                                                <span class="text-danger"
                                                                                    v-if="apiErrors && apiErrors['postcode'] && apiErrors['postcode'].length">
                                                                                    {{ apiErrors["postcode"][0] }}
                                                                                </span>
                                                                                <!-- <span class="text-danger" v-if="validateLoa && formData.value.postcode !==''">Postcode is required</span> -->
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="col-md-12">
                                                                            <div class="form-wrap mb-wrap">
                                                                                <label class="col-form-label">Post
                                                                                    code/ZIP</label>
                                                                                <input type="text" class="form-control"
                                                                                    v-model="formData.zip" />
                                                                            </div>
                                                                        </div> -->


                                                                        <!-- <div class="col-md-12">
                                                                            <div class="form-wrap mb-wrap">
                                                                                <label
                                                                                    class="col-form-label">Country</label>
                                                                                <select id="country_id"
                                                                                    v-model="formData.country_id"
                                                                                    class="form-select">
                                                                                    <option value="">-----Select-----
                                                                                    </option>
                                                                                    <option v-for="res in Countries"
                                                                                        :key="res" :value="res.id">
                                                                                        {{ res.country_name }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div> -->
                                                                        <div v-if="isTps" class="col-md-12">
                                                                            <div class="form-wrap mb-wrap">
                                                                                <label
                                                                                    class="col-form-label">TPS</label>
                                                                                <input type="text" disabled
                                                                                    class="form-control"
                                                                                    v-model="formData.tps" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- PERSON DETAILS< -->
                                                            <organisation-person-details :isEditing="isEditing"
                                                                :personData="personData"
                                                                :settingOptions="settingOptions"
                                                                :personformData="formData.person"
                                                                :validate-loa="isloaValid" @change-person="onPersonChange"></organisation-person-details>
                                                            <!-- PAYMENT DETAILS edit organisation-->
                                                            <payment-details v-if="isEditing" :options="options"
                                                                :paymentDetails="formData.payment_details"></payment-details>
                                                        </div>
                                                        <div class="col ps-1">
                                                            <!-- PAYMENT DETAILS On create organisation -->
                                                            <payment-details v-if="!isEditing" :options="options"
                                                                :paymentDetails="formData.payment_details"></payment-details>
                                                            <!-- LOA SECTION-->
                                                            <div class="loa-section">
                                                                <div v-if="isEditing"
                                                                    class="row organisation-detail mx-0">
                                                                    <div class="col-md-12 p-0">
                                                                        <org-send-loa
                                                                            v-if="organisationData && Object.keys(organisationData).length"
                                                                            :orgPersons="orgPersons" :sendFromOrg="true"
                                                                            :settingOptions="settingOptions"
                                                                            :opportunityData="organisationData"
                                                                            @valid-loa="validateLoa"></org-send-loa>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- OWNER DETAILS< -->
                                                            <owner-details
                                                                v-if="formData.type === 'LLP' || formData.type === 'Soletrader' || formData.type === 'Partnership'"
                                                                :ownerData="ownerData" :isEditing="isEditing"
                                                                :ownerformData="formData.owner_details"></owner-details>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row my-3">
                                            <div class="col-md-6">
                                                <div class="form-wrap mb-0 mt-4" style="border: 1px solid #e1e1e1">
                                                    <h4 class="p-2">Logs</h4>
                                                    <p class="col-form-label p-2" style="background-color: #eff1f0">
                                                        Add log entry
                                                    </p>
                                                    <textarea class="form-control" rows="1"
                                                        v-model="formData.content"></textarea>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row my-3" v-if="isEditing">
                                            <div class="col-md-6">
                                                <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1">
                                                    <p class="col-form-label p-2" style="background-color: #eff1f0">
                                                        Logs
                                                    </p>
                                                    <div class="logs_Section">
                                                        <div class="row" v-for="log in logs">
                                                            <div class="col-12">
                                                                <p class="p-2">{{ log.content }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="submit-button text-end">
                                            <button type="button" class="btn btn-light"
                                                @click="handleCancel">Cancel</button>
                                            <button type="submit" class="btn btn-primary">
                                                <!-- {{ isEditing ? "Save" : "Create" }} -->
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    onMounted,
    watch,
    computed
} from "vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Form,
    Field,
    ErrorMessage,
    validate
} from "vee-validate";
import * as Yup from "yup";
import {
    message
} from "ant-design-vue";
import apiClient from "@/axios";
import { changeFormate } from '../../../../util'
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            startdate: currentDate,
            startdateOne: currentDateOne,
            startdateTwo: currentDateTwo,
            dateFormat: "dd/MM/yyyy",
            DuplicateBatch: ["Yes"],
            VatGroup: ["A", "B"],
        };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const isEditing = ref(false);
        const org_id = ref(null);
        const imagePreview = ref("");
        const apiErrors = ref(null);
        const personData = ref([])
        const ownerData = ref([])
        const settingOptions = ref([])
        const date_of_birth = ref(null)
        const dob = ref(null)
        const isTps = ref(false)
        const organisationData = ref({});
        const orgPersons = ref([]);
        const isloaValid = ref(false);
        const options = computed(() => store.getters["organisation/getorgDropdowns"]);
        const Territories = computed(() => store.getters["organisation/getTerritories"]);
        const Responsibles = computed(() => store.getters["organisation/getresUsersOptions"]);
        const assignedToOptions = computed(
            () => store.getters["organisation/getfetchAssignedTo"]
        );
        const Countries = computed(() => store.getters["organisation/getCountries"]);

        const schema = Yup.object().shape({
            name: Yup.string().required("Organisation name is required"),
            postcode: Yup.string().required("Postcode is required"),
            territory: Yup.string().required("Territory is required"),
            // telephone: Yup.string()
            //     .nullable()
            //     .max(15, "Telephone must not be greater than 15 digits."),
            company_reg_no: Yup.string().when("type", {
                is: "Ltd",
                then: (schema) =>
                    schema.required("Please enter Company Reg No. for Ltd companies"),
                otherwise: (schema) => schema.notRequired(),
            }),
            date_of_birth: Yup.string().when("type", {
                is: (type) => type === "Soletrader" || type === "Partnership",
                then: (schema) =>
                    schema.required("Please enter Date of Birth for Soletrader or Partnership"),
                otherwise: (schema) => schema.notRequired(),
            }),
            address: Yup.string().when("type", {
                is: (type) => type === "Soletrader" || type === "Partnership",
                then: (schema) =>
                    schema.required("Please enter Address for Soletrader or Partnership"),
                otherwise: (schema) => schema.notRequired(),
            }),
            first_name: Yup.string().when(["type", "person_list"], {
                is: (type, person_list) =>
                    type === "Soletrader" || type === "Partnership" || Boolean(person_list),
                then: (schema) =>
                    schema.required("Please enter First name for Soletrader, Partnership, or if Person List is selected"),
                otherwise: (schema) => schema.notRequired(),
            }),
            email: Yup.string().when(["type", "person_list"], {
                is: (type, person_list) =>
                    type === "Soletrader" || type === "Partnership" || Boolean(person_list),
                then: (schema) =>
                    schema.required("Please enter email for Soletrader, Partnership, or if Person List is selected"),
                otherwise: (schema) => schema.notRequired(),
            }),
            first_name: Yup.string().when(
                ["last_name", "mobile_telephone", "date_of_birth", "customer_direct_debit_day", "customer_payment_method", "salutation", "job_title"],
                {
                    is: (...fields) => fields.some((field) => Boolean(field && field.trim())), // Check if any field has a value
                    then: (schema) =>
                        schema.required("First name is required because other person details are provided"),
                    otherwise: (schema) => schema.notRequired(),
                }
            ),
            email: Yup.string().when(
                ["last_name", "mobile_telephone", "date_of_birth", "customer_direct_debit_day", "customer_payment_method", "salutation", "job_title"],
                {
                    is: (...fields) => fields.some((field) => Boolean(field && field.trim())), // Check if any field has a value
                    then: (schema) =>
                        schema.required("Email is required because other person details are provided"),
                    otherwise: (schema) => schema.notRequired(),
                }
            ),

            charity_reg_no: Yup.string().when("type", {
                is: "Charity",
                then: (schema) => schema.required("Charity No. is required"),
                otherwise: (schema) => schema.notRequired(),
            }),

            owner_name: Yup.string().test(
                "owner-name-required",
                "Owner name is required because other owner details are provided",
                function (value) {
                    const {
                        default_address,
                        years_at_current_address,
                        current_address_line_1,
                        current_address_line_2,
                        current_address_line_3,
                        current_address_line_4,
                        current_postcode,
                        primary_partner,
                        dob,
                    } = this.parent;

                    const isRequired =
                        primary_partner === 1 ||
                        ["current", "previous"].includes(default_address) ||
                        Boolean(dob) ||
                        [
                            years_at_current_address,
                            current_address_line_1,
                            current_address_line_2,
                            current_address_line_3,
                            current_address_line_4,
                            current_postcode,
                        ].some((field) => Boolean(field && field.trim()));

                    if (isRequired) {
                        return Boolean(value && value.trim());
                    }

                    return true; // Not required, so validation passes
                }
            ),

            default_address: Yup.string().test(
                "view-details-for",
                "View Details For is required because other details are provided",
                function (value) {
                    const {
                        years_at_current_address,
                        current_address_line_1,
                        current_address_line_2,
                        current_address_line_3,
                        current_address_line_4,
                        current_postcode,
                    } = this.parent;

                    const isRequired =
                        [
                            years_at_current_address,
                            current_address_line_1,
                            current_address_line_2,
                            current_address_line_3,
                            current_address_line_4,
                            current_postcode,
                        ].some((field) => Boolean(field && field.trim()));

                    if (isRequired) {
                        return Boolean(value && value.trim());
                    }

                    return true; // Not required, so validation passes
                }
            ),



        });


        if (route.query.org_Id) {
            org_id.value = route.query.org_Id;
            isEditing.value = true;
        }

        const formData = ref({
            id: null,
            name: "",
            postcode: "",
            // telephone: "",
            fax: "",
            responsible: '',
            assign_to: null,
            logo: null,
            comment: "",
            territory_id: "",
            // vat_group: "",
            type: "",
            status: "",
            // industry: "",
            address: "",
            address_line_4: "",
            city: "",
            state: "",
            // zip: "",
            // country_id: null,
            // alert_text: "",
            www: "",
            vat_no: "",
            // erp_id: "",
            org_urn: "",
            original_lead_source: "",
            no_of_employees: "",
            sic_code: "",
            sic_description: "",
            company_reg_no: "",
            charity_reg_no: "",
            charity_import_id: "",
            charity_lead_source: "",
            charity_duplicate_batch: "",
            content: "",
            purchased_data_source: "",
            purchased_data_id: "",
            allocation_batch_id: "",
            email_domain: "",
            flag_no_meter_opp: "",
            org_rellocation_marker: "",
            outlook_sync: "",
            tps: "",
            parent_company_name: "",
            company_incorporated_date: null,
            customer_value: "",
            credit_score: "",
            company_sector: "",
            // turnover: "",
            // net_worth: "",
            // building_use: "",
            // website_url: "",
            // vat_declaration: "",
            // vat_percent: "",
            // micro_buisness: 0,
            // main_address: 0,
            person: {
                first_name: "",
                last_name: "",
                email: "",
                salutation: "",
                mobile_telephone: "",
                job_title: "",
                customer_direct_debit_day: "",
                customer_payment_method: "",
                id: "",
                date_of_birth: null,
                direct_line: "",
                landline: "",
                marketing_prefrences: []
            },
            owner_details: {
                id: "",
                first_name: "",
                dob: null,
                primary_partner: 0,
                default_address: "",
                years_at_current_address: "",
                current_address_line_1: "",
                current_address_line_2: "",
                current_address_line_3: "",
                current_address_line_4: "",
                current_postcode: "",
                years_at_previous_address: "",
                previous_address_line_1: "",
                previous_address_line_2: "",
                previous_address_line_3: "",
                previous_address_line_4: "",
                previous_postcode: "",
            },
            payment_details: {
                payment_type: "",
                direct_debit_start_date: null,
                account_holder: "",
                account_number: "",
                sort_code: "",
                bank_name: "",
                bank_postcode: ""
            }
        });
        const logs = ref([]);
        // const formattedTelephone = computed({
        //     get() {
        //         if (!formData.value || !formData.value.telephone) {
        //             return ""; // Return an empty string if there's no telephone number
        //         }

        //         const number = formData.value.telephone.replace(/\D/g, "");

        //         if (number.length === 10) {
        //             return `(${number.substring(0, 3)}) ${number.substring(
        //                 3,
        //                 6
        //             )}-${number.substring(6)}`;
        //         } else {
        //             return formData.value.telephone;
        //         }
        //     },
        //     set(value) {
        //         const cleanedValue = value.replace(/\D/g, "");
        //         formData.value.telephone = cleanedValue.length ? cleanedValue : "";
        //     },
        // });

        const clearDate = () => {
            if (formData.value.person.date_of_birth) {
                date_of_birth.value = formData.value.person.date_of_birth;
            } else {
                date_of_birth.value = null;
            }

            if (formData.value.owner_details.dob) {
                dob.value = formData.value.owner_details.dob;
            } else {
                dob.value = null;
            }
        }
        const validateLoa = (key) => {
            isloaValid.value = key;
        };
        const fetchOrgData = async (id) => {
            try {
                const data = await store.dispatch("organisation/fetchOrgData", id);
                org_id.value = id;
                isEditing.value = true;
                logs.value = data.logs;
                organisationData.value = data;
                orgPersons.value = data.persons;
                Object.assign(formData.value, data)
                formData.value.telephone = ''
                formData.value.company_incorporated_date = data.company_incorporated_date ? new Date(data.company_incorporated_date) : null

                // Handle person data
                if (data.persons && data.persons.length > 0) {
                    personData.value = data.persons;
                    const firstPerson = data.persons[0];
                    Object.assign(formData.value.person, firstPerson);
                    formData.value.person.date_of_birth = firstPerson.date_of_birth ? new Date(firstPerson.date_of_birth) : null
                    formData.value.person.marketing_prefrences = firstPerson.marketing_prefrences ? firstPerson.marketing_prefrences.split(',') : []
                    date_of_birth.value = formData.value.person.date_of_birth;
                }
                if (data.payment_details) {
                    const firstPerson = data.payment_details;
                    Object.assign(formData.value.payment_details, firstPerson)
                    formData.value.payment_details.direct_debit_start_date = firstPerson.direct_debit_start_date ? new Date(firstPerson.direct_debit_start_date) : null
                }
                // Handle owner details
                if (data.owner_details && data.owner_details.length > 0) {
                    ownerData.value = data.owner_details;
                    const owner = data.owner_details[0];
                    Object.assign(formData.value.owner_details, owner);
                    formData.value.owner_details.dob = owner.date_of_birth ? new Date(owner.date_of_birth) : null;

                    if (owner.current_address) {
                        const currentAddress = JSON.parse(owner.current_address);
                        Object.assign(formData.value.owner_details, currentAddress)
                    }

                    if (owner.previous_address) {
                        const previousAddress = JSON.parse(owner.previous_address);
                        Object.assign(formData.value.owner_details, previousAddress)
                    }
                }
                formData.value.content = "";
                imagePreview.value = data.logo;
            } catch (error) {
                console.error("Error fetching organization data:", error);
            }
        };

        const handleSubmit = async () => {
            const company_incorporated_date = changeFormate(formData.value.company_incorporated_date);
            const date_of_birth = changeFormate(formData.value.person.date_of_birth);
            const marketing_prefrences = formData.value.person.marketing_prefrences.toString();
            const dob = changeFormate(formData.value.owner_details.dob);
            const direct_debit_start_date = changeFormate(formData.value.payment_details.direct_debit_start_date);

            const response = await store.dispatch("organisation/saveOrgData", {
                ...formData.value, company_incorporated_date: company_incorporated_date,
                person: {
                    ...formData.value.person,
                    date_of_birth: date_of_birth,
                    marketing_prefrences: marketing_prefrences
                },
                owner_details: {
                    ...formData.value.owner_details,
                    date_of_birth: dob
                },
                payment_details: {
                    ...formData.value.payment_details,
                    direct_debit_start_date: direct_debit_start_date
                }
            });

            if (response.status) {
                if(response.data){
                    org_id.value = response.data.id;
                    isloaValid.value = false;
                    if (org_id.value) {
                        fetchOrgData(org_id.value);
                    }
                }
                handleCancel();
            } else {
                apiErrors.value = response.errors;
                message.error(apiErrors.value);
            }
        };
        const handleCancel = () => {
            const query = route.query.add;
            // const kanban = route.query.kanban;
            if (query === "opp") {
                // router.push("/crm/create-meter-opportunity");
                router.push({
                    path: "/crm/create-meter-opportunity",
                    query: {
                        org_Id: route.query.org_Id
                    },
                    });
            } else if (query === "act") {
                router.push({path: "/crm/create-activity",
                query: {
                        org_Id: route.query.org_Id
                    },
                });
            } else if (query === "editOpp") {
                router.push({path: "/crm/meter-opportunities-edit",
                query: {
                        org_Id: route.query.org_Id,
                        opp_Id: route.query.opp_Id,
                        ter_Id: route.query.ter_Id
                    },
                });
            } else if (query === "editContract") {
                router.push({path: "/crm/edit-contract",
                query: {
                        org_Id: route.query.org_Id,
                        contract_Id: route.query.contract_Id,
                        ter_Id: route.query.ter_Id
                    },
                });
            } else if (query === "org-details-person") {
                router.push({path: "/crm/organisation-details",
                query: {
                        org_Id: route.query.org_Id,
                        ter_Id: route.query.ter_Id,
                        for: 'persons'
                    },
                });
            } else if (query === "contract") {
                router.push({path: "/crm/create-contract",
                query: {
                        org_Id: route.query.org_Id
                    },
                });
            } else if (query === "dashboard") {
                router.push("/dashboard");
            } else if (query === "quoting") {
                router.push("/quoting/get-quoting");
            }
            // else {
            //     router.push("/crm/organisation-list");
            // }
        };
        const handleImageUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                formData.value.logo = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    imagePreview.value = e.target.result; // Show the image preview
                };
                reader.readAsDataURL(file); // Convert the file to a data URL
            }
        };

        const removeImage = () => {
            formData.value.logo = ""; // Clear the image URL in the form data
            imagePreview.value = ""; // Clear the image preview
        };
        // Watch for changes in the selected territory

        const fetchOptions = async () => {
            try {
                const response = await apiClient.get("settings/organisation-person");
                settingOptions.value = response.data.data.dropdowns;
            } catch (error) {
                console.error("Error fetching options data:", error);
            }
        };

        const onPersonChange = () => {
            const selectedPerson = personData.value.find(
                (person) => person.id === formData.value.person.id
            );
            if (selectedPerson) {
                Object.assign(formData.value.person, selectedPerson);
                formData.value.person.marketing_prefrences = selectedPerson.marketing_prefrences
                    ? selectedPerson.marketing_prefrences.split(",")
                    : [],
                    formData.value.person.date_of_birth = selectedPerson.date_of_birth
                        ? new Date(selectedPerson.date_of_birth)
                        : null,

                    date_of_birth.value = new Date(selectedPerson.date_of_birth || null);
            } else {
                Object.assign(formData.value.person, {
                    first_name: "",
                    last_name: "",
                    email: "",
                    salutation: "",
                    mobile_telephone: "",
                    job_title: "",
                    customer_direct_debit_day: "",
                    customer_payment_method: "",
                    direct_line: "",
                    landline: "",
                    marketing_prefrences: [],
                    date_of_birth: null,
                });

                date_of_birth.value = null;
            }
        };

        watch(
            () => formData.value.territory_id,
            async (newTerritory) => {
                if (newTerritory) {
                    store.dispatch("organisation/fetchTerritoryCompanyUsers", [newTerritory]);
                } else {
                    Responsibles.value = []; // Clear Responsible if no territory selected
                    assignedToOptions.value = [];
                }
            }, {
            immediate: true,
        } // Immediate to handle initial load if needed
        );

        onMounted(async () => {
            const orgId = route.query.org_Id;
            if (orgId) {
                fetchOrgData(orgId);
            }
            await fetchOptions();
            await store.dispatch("organisation/fetchDropdownOptions");
            await store.dispatch("organisation/fetchTerritories");
            const territories = await store.getters["organisation/getTerritories"];
            if (territories.length === 1) {
                formData.value.territory_id = territories[0].id; // Set the first territory as default
                const responsibles =  await store.dispatch("organisation/fetchTerritoryCompanyUsers", [formData.value.territory_id]);
                if (responsibles.length === 1) {
                    formData.value.responsible = responsibles[0].id; // Set the first territory as default
                }
            }
            // const responsibles =  await store.dispatch["organisation/fetchTerritoryCompanyUsers"];
            // await store.dispatch("organisation/fetchCountries");
        });

        return {
            schema,
            formData,
            organisationData,
            org_id,
            isloaValid,
            handleSubmit,
            handleCancel,
            isEditing,
            Territories,
            Responsibles,
            Countries,
            handleImageUpload,
            removeImage,
            imagePreview,
            assignedToOptions,
            options,
            apiErrors,
            logs,
            // formattedTelephone,
            personData,
            fetchOptions,
            settingOptions,
            onPersonChange,
            date_of_birth,
            validateLoa,
            clearDate,
            isTps,
            dob,
            ownerData,
            orgPersons,
        };
    },
};
</script>
<style>
.logs_Section {
    max-height: 320px;
    overflow-x: hidden;
}

.organisation-detail {
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    /* margin: 0rem !important; */
}
</style>
