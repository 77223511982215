<template>
  <div>
    <!-- Loop over organisations if available -->
    <div class="dashborad-view-section" @scroll="onScroll" id="orgSection">
      <div class="row m-3" v-if="organisations?.data?.length > 0">
        <div class="col-md-6 border py-2" v-for="item in organisations.data" :key="item.id">
          <router-link
            :to="{ path: '/crm/organisation-details', query: { org_Id: item.id, ter_Id: item.territory_id } }"
            class="text-teal">
            <!-- <i class="ion-navicon-round text-gray me-2"></i> -->
            <span>{{ item.name }}</span><span v-if="item.postcode"> - {{ item.postcode }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!-- Loop over userReports if available -->
    <div id="pipelineOppData" class="dashborad-view-section" v-if="pipeLineData">
      <div class="row mx-2">
        <div class="col-6">
          <p><strong class="me-3" style="color: #E41F07;">Overdue: </strong><strong>{{ overDuelength }}</strong></p>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-md-6 border py-2" v-for="(value, key) in pipeLineData.pipeline_level_data" :key="key">
          <div class="row">
            <div class="col-7">
              <strong class="text-teal">{{ key }}</strong>:
            </div>
            <div class="col-5 text-end">
              <strong>{{ value }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loop over userReports if available -->
    <div id="userReports" class="dashborad-view-section" @scroll="onScroll">
      <div class="row m-3" v-if="userReports?.data?.length > 0">
        <div class="col-md-6 border py-2" v-for="item in userReports.data" :key="item.id">
          <router-link
            :to="{ path: '/crm/overview/report', query: { modN: item.module.name, repN: item.name, reportId: item.id, moduleId: item.module.id } }"
            class="text-teal">
            <span><i class="ti ti-list text-dark"></i> {{ item.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!-- Loop over overDueOpportunities if available -->
    <div class="dashborad-view-section" @scroll="onScroll" id="overDueOpportunities">
      <div class="row mx-3" v-if="overDueOpportunities?.data?.length > 0"
        v-for="opportunity in overDueOpportunities.data" :key="opportunity.id">
        <div class="col-md-4 border py-2">
          <router-link :to="{
            path: '/crm/meter-opportunities-edit',
            query: {
              add: 'dashboard',
              ter_Id: opportunity.territory_id,
              org_Id: opportunity.organization.id,
              opp_Id: opportunity.id,
              for: 'opportunities'
            },
          }" class="text-teal">
            {{ formatDate(opportunity.create_date) }}
          </router-link>
        </div>
        <div class="col-md-4 border py-2">
          <p class="mb-0">
            <span>{{ opportunity.meter_opportunity_nr_auto }}</span>
            <span v-if="opportunity.mpr">- {{ opportunity.mpr }}</span>
            <span v-if="opportunity.mpan_top">- {{ opportunity.mpan_top }} {{ opportunity.mpan_bottom }}</span>
          </p>
        </div>
        <div class="col-md-4 border py-2">
          <router-link :to="{
            path: '/crm/organisation-details',
            query: { org_Id: opportunity.organization.id, ter_Id: opportunity.territory_id, opp_Id: opportunity.id, for: 'opportunities' },
          }" class="text-teal">
            <span>{{ opportunity.organization.name }}</span><span v-if="opportunity.organization.postcode"> - {{
              opportunity.organization.postcode }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!-- Loop over searchResults if available -->
    <div class="dashborad-view-section" @scroll="onScroll" id="searchSection">
      <div class="row mx-3" v-if="searchResults && searchResults?.data?.length > 0" v-for="search in searchResults.data"
        :key="search.id">
        <div class="col-md-12 border py-2" v-if="search.name">
          <router-link :to="{
            path: '/crm/organisation-details',
            query: {
              org_Id: search.id,
              ter_Id: search.territory_id
            },
          }" class="text-teal"><i class="ti ti-building-community text-gray me-2"></i>
            <span>{{ search.name }} </span><span v-if="search.postcode"> - {{ search.postcode }}</span>
          </router-link>
        </div>
        <div class="col-md-12 border py-2" v-if="search.direct_telephone">
          <div class="row">
            <div class="col-12">
              <router-link :to="{
                path: '/crm/organisation-details',
                query: {
                  org_Id: search.organization.id,
                  ter_Id: search.organization.territory_id,
                },
              }" class="text-teal"><i class="ti ti-building-community text-gray me-2"></i>
                <span>{{ search?.organization?.name }} </span><span v-if="search?.organization?.postcode"> - {{
                  search.organization.postcode }}</span>
              </router-link>

            </div>
          </div>
        </div>
        <div class="col-md-12 border py-2" v-if="search.first_name">
          <div class="row">
            <div class="col-12">
              <router-link :to="{
                path: '/crm/create-person',
                query: {
                  personId: search.id,
                  org_Id: search.organization.id,
                  ter_Id: search.organization.territory_id
                },
              }" class="text-teal"><i class="ion-person text-gray me-2"></i>
                <span>{{ search.first_name }}</span><span v-if="search.last_name"> - {{ search.last_name }}</span>
              </router-link>
            </div>
          </div>
          <p class="mb-0"><span v-if="search?.organization?.telephone">T: {{ search.organization.telephone }}</span>
            <span v-if="search.direct_telephone">D: {{ search.direct_telephone }}</span>
          </p>
        </div>
      </div>
      <div class="row mx-0" v-if="searchResults?.data?.length === 0">
        <div class="col-md-12 test-muted text-center py-2">
          No Data Found
        </div>
      </div>
    </div>
    <!-- Loop over userActivities if available -->
    <div class="dashborad-view-section" @scroll="onScroll" id="userActivities">
      <div class="row mx-3" v-if="userActivities?.data?.length > 0" v-for="res in userActivities.data" :key="res.id">
        <div class="col-md-4 border py-2">
          <router-link :to="{
            path: '/crm/edit-activity',
            query: {
              ter_Id: res.territory_id,
              org_Id: res.organization.id,
              actId: res.id,
              for: 'activities'
            },
          }" class="text-teal">
            <span>{{ res.date }}</span><span v-if="res.time"> {{ res.time }}</span>
          </router-link>
        </div>
        <div class="col-md-4 border py-2">
          <p class="mb-0">

            <span>{{ res.action }}</span>
          </p>
        </div>
        <div class="col-md-4 border py-2">
          <router-link :to="{
            path: '/crm/organisation-details',
            query: {
              org_Id: res.organization.id, ter_Id: res.territory_id,
              actId: res.id,
              for: 'activities'
            },
          }" class="text-teal">
            <span>{{ res.organization.name }}</span><span v-if="res.organization.postcode"> - {{
              res.organization.postcode }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    organisations: {
      type: Object,
      default: () => { },
    },
    overDueOpportunities: {
      type: Object,
      default: () => { },
    },
    overDuelength: {
      type: String,
    },
    searchResults: {
      type: Object,
      default: () => { },
    },
    userActivities: {
      type: Object,
      default: () => { },
    },
    pipeLineData: {
      type: Object,
      default: () => { },
    },
    userReports: {
      type: Object,
      default: () => { },
    },
    onScroll: {
      type: Function,
      default: null
    }
  },
  setup() {
    // Function to convert date format
    const formatDate = (date) => {
      if (date) {
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      }
    };

    return {
      formatDate,
    }
  }
};
</script>
<style scoped lang="scss">
.dashborad-view-section {
  max-height: 30vh;
  overflow: auto;
}
</style>
