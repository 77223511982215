<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="contact-head">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                    <a href="#" @click="handleSubmit">
                      <i class="ti ti-check"></i>
                            Save
                          </a>
                  </li>
                  <li>
                    <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: terId, org_Id: org_id, for: 'persons' } }">
                      <i class="ti ti-arrow-narrow-left"></i>
                      Organisation
                    </router-link>
                  </li>
                  <li v-if="contId">
                    <router-link :to="{ path: '/crm/edit-contract', query: { ter_Id: terId, org_Id: org_id, contract_Id: contId }}">
                      <i class="ti ti-arrow-narrow-left"></i> Contract
                    </router-link>
                  </li>
                  <li v-if="oppId">
                    <router-link :to="{ path: '/crm/meter-opportunities-edit', query: { ter_Id: terId, org_Id: org_id, opp_Id: oppId }}">
                      <i class="ti ti-arrow-narrow-left"></i> Opportunity
                    </router-link>
                  </li>
                  <!-- <li>{{ isEditing ? "Edit Person" : "Create New Person" }}</li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <!-- Company Settings -->
              <div class="card">
                <div class="card-body settings-form">
                  <!-- <form @submit.prevent="handleSubmit" class="toggle-height"> -->
                  <Form
                    @submit="handleSubmit"
                    :validation-schema="schema"
                    class="toggle-height"
                  >
                    <div class="settings-sub-header">
                      <div class="row align-items-center">
                        <div class="col-6">
                          <h4>
                            {{ isEditing ? "Edit Person" : "Create Person" }}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="pro-create">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label"
                              >First name<span class="text-danger">*</span></label
                            >
                            <Field
                              name="first_name"
                              as="input"
                              type="text"
                              class="form-control"
                              v-model="formData.first_name"
                            />
                            <ErrorMessage name="first_name" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <!-- Only show the Role label and link if isCheckboxVisible is false -->
                          <div class="form-wrap">
                            <label class="col-form-label">Role</label>
                            <div class="form-control edit-plain-button" v-if="!isCheckboxVisible">
                              <a href="#" @click.prevent="toggleCheckboxes" title="">
                                <i class="ti ti-edit edit-button"></i>
                              </a>
                            </div>
                            <!-- Display checkboxes when isCheckboxVisible is true -->
                            <div v-if="isCheckboxVisible">
                              <div v-for="(role, roleName) in roles" :key="roleName">
                                <label :for="'role-' + roleName">
                                  <input type="checkbox" :id="'role-' + roleName" :value="roleName"
                                    v-model="formData.role" />
                                  {{ role }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Last name</label>
                            <Field
                              name="last_name"
                              as="input"
                              type="text"
                              class="form-control"
                              v-model="formData.last_name"
                            />
                            <ErrorMessage name="last_name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Status </label>
                            <select
                              id="perStatusOptions"
                              v-model="formData.status"
                              class="form-select"
                              v-if="options && options.org_p_status"
                            >
                              <option disabled value="">-----Select-----</option>
                              <option
                                v-for="res in options.org_p_status"
                                :key="res.value"
                                :value="res.value"
                              >
                                {{ res.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Support cases</label>
                            <select
                              id="status"
                              v-model="formData.support_cases"
                              class="form-select"
                            >
                              <option value="">-----Select-----</option>
                              <option v-for="res in supportCases" :key="res" :value="res">
                                {{ res }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label"
                              >Email<span class="text-danger">*</span></label
                            >
                            <Field
                              name="email"
                              as="input"
                              type="email"
                              class="form-control"
                              v-model="formData.email"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                            <span
                              class="text-danger"
                              v-if="
                                apiErrors &&
                                apiErrors['email'] &&
                                apiErrors['email'].length
                              "
                              >{{ apiErrors["email"][0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap form-wrap mt-4 pt-2">
                            <input
                              type="checkbox"
                              class="form-check-input me-2"
                              id="block_mass_emails"
                              :true-value="1"
                              :false-value="0"
                              v-model="formData.block_mass_emails"
                            />
                            <label class="form-check-label" for="block_mass_emails"
                              >Block mass emails</label
                            >
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap mt-4 pt-2">
                            <input type="checkbox" class="form-check-input me-2" id="email_bounces" :true-value="1"
                              :false-value="0" v-model="formData.email_bounces" />
                            <label class="form-check-label" for="email_bounces">Email Bounces</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Skype</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="formData.skype"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Salutation</label>
                            <select
                              id="status"
                              v-model="formData.salutation"
                              class="form-select"
                              v-if="options && options.org_p_salutation"
                            >
                              <option value="">-----Select-----</option>
                              <option
                                v-for="res in options.org_p_salutation"
                                :key="res.value"
                                :value="res.value"
                              >
                                {{ res.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Job title</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="formData.job_title"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Direct telephone</label>
                            <Field
                              name="direct_telephone"
                              as="input"
                              type="text"
                              class="form-control"
                              v-model="formData.direct_telephone"
                            />
                            <ErrorMessage name="direct_telephone" class="text-danger" />
                            <!-- <p v-if="telephoneError" class="text-danger">
                              {{ telephoneError }}
                            </p> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Mobile</label>
                            <Field
                              name="mobile_telephone"
                              as="input"
                              type="text"
                              class="form-control"
                              v-model="formattedTelephone"
                            />

                            <ErrorMessage name="mobile_telephone" class="text-danger" />
                            <!-- <p v-if="mobileError" class="text-danger">
                              {{ mobileError }}
                            </p> -->
                            <span
                              class="text-danger"
                              v-if="
                                apiErrors &&
                                apiErrors['mobile_telephone'] &&
                                apiErrors['mobile_telephone'].length
                              "
                              >{{ apiErrors["mobile_telephone"][0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-wrap mb-0">
                            <label class="col-form-label">Comment</label>
                            <textarea
                              class="form-control"
                              rows="3"
                              v-model="formData.comment"
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Date of birth</label>
                            <div class="icon-form">
                              <date-picker textInput v-model="formData.date_of_birth" placeholder=""
                                class="datetimepicker form-control" :editable="true" :clearable="true"
                                :input-format="dateFormat" />
                              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Language</label>
                            <select id="language" v-model="formData.language" class="form-select"
                              v-if="options && options.language">
                              <option value="">-----Select-----</option>
                              <option v-for="res in options.language" :key="res.value" :value="res.value">
                                {{ res.text }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">job Function</label>
                            <select
                              id="job_function"
                              v-model="formData.job_function"
                              class="form-select"
                              v-if="options && options.org_p_job_functions"
                            >
                              <option value="">-----Select-----</option>
                              <option
                                v-for="res in options.org_p_job_functions"
                                :key="res.value"
                                :value="res.value"
                              >
                                {{ res.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Safe toTelephone</label>
                            <select
                              id="safe_to_telephone"
                              v-model="formData.safe_to_telephone"
                              class="form-select"
                            >
                              <option value="">-----Select-----</option>
                              <!-- <option
                                v-for="res in safeTelephones"
                                :key="res"
                                :value="res"
                              >
                                {{ res }}
                              </option> -->
                              <option
                                v-for="res in options.safe_to_telephone"
                                :key="res.value"
                                :value="res.value"
                              >
                                {{ res.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Forename</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="formData.dir_owner_forename"
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Surname</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="formData.dir_owner_surname"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Director / Owner</label>
                            <select
                              id="director_owner"
                              v-model="formData.director_owner"
                              class="form-select"
                              v-if="options && options.org_p_dir_own"
                            >
                              <option value="">-----Select-----</option>
                              <option
                                v-for="res in options.org_p_dir_own"
                                :key="res.value"
                                :value="res.value"
                              >
                                {{ res.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Director Date of Birth</label>
                            <div class="icon-form">
                              <date-picker textInput v-model="formData.director_date_of_birth" placeholder=""
                                class="datetimepicker form-control" :editable="true" :clearable="true"
                                :input-format="dateFormat" />
                              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Building No</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.dir_owner_building_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Building Name</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.dir_owner_building_name"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Director Street</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.dir_owner_street"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Director Town</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.dir_owner_town"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Director Postcode</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.dir_owner_postcode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Bank Account Name</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.customer_bank_account_name"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Account No</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.customer_account_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Sort Code</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.customer_sort_code"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Direct Debit Day</label>
                            <select
                              id="customer_direct_debit_day"
                              v-model="formData.customer_direct_debit_day"
                              class="form-select"
                              v-if="options && options.org_p_direct_debit_day"
                            >
                              <option disabled value="">-----Select-----</option>
                              <option
                                v-for="source in options.org_p_direct_debit_day"
                                :key="source.value"
                                :value="source.value"
                              >
                                {{ source.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Payment Method</label>
                            <select
                              id="customer_payment_method"
                              v-model="formData.customer_payment_method"
                              class="form-select"
                              v-if="options && options.org_p_payment_method"
                            >
                              <option disabled value="">-----Select-----</option>
                              <option
                                v-for="source in options.org_p_payment_method"
                                :key="source.value"
                                :value="source.value"
                              >
                                {{ source.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Bank Name</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.customer_bank_name"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Bank address</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_bank_address" />
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Bank address</label>
                            <div class="icon-form-end">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="formData.customer_contact_urn"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap mt-4 pt-2">
                            <input
                              type="checkbox"
                              class="form-check-input me-2"
                              id="key_accounting_system"
                              :true-value="1"
                              :false-value="0"
                              v-model="formData.key_accounting_system"
                            />
                            <label class="form-check-label" for="key_accounting_system"
                              >Key Accounting System ...</label
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap mt-4 pt-2">
                            <input
                              type="checkbox"
                              class="form-check-input me-2"
                              id="existing_dd"
                              :true-value="1"
                              :false-value="0"
                              v-model="formData.existing_dd"
                            />
                            <label class="form-check-label" for="existing_dd"
                              >Existing DD</label
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap mb-0 mt-4" style="border: 1px solid #e1e1e1">
                            <h4 class="p-2">Logs</h4>
                            <p class="col-form-label p-2" style="background-color: #eff1f0">
                              Add log entry
                            </p>
                            <textarea class="form-control" rows="3" v-model="formData.content"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3" v-if="isEditing">
                      <div class="col-md-4">
                        <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1">
                          <p class="col-form-label p-2" style="background-color: #eff1f0">
                            Logs
                          </p>
                          <div class="row" v-for="logs in logs">
                            <div class="col-12">
                              <p class="p-2">{{ logs.content }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="submit-button text-end">
                      <rbutton
                        type="button"
                        v-if="isEditing"
                        class="btn btn-light"
                        @click="handleCancel"
                        >Cancel</rbutton
                      >
                      <!-- <router-link v-else class="btn btn-light" :to="{
                        path: '/crm/create-organisation',
                        query: { org_Id: org_id },
                      }">Back</router-link> -->
                      <button
                        type="button"
                        v-else
                        class="btn btn-light"
                        @click="handleCancel"
                      >
                        Back
                      </button>
                      <button type="submit" class="btn btn-primary">
                        {{ isEditing ? "Update" : "Create" }}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, onBeforeMount } from "vue";
import { mapActions, mapGetters } from "vuex";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import apiClient from "@/axios";
import { changeFormate } from "../../../../util";

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "dd/MM/yyyy",
      supportCases: ["Same language as users"],
      safeTelephones: ["Y", "N"],
      VatGroup: ["A", "B"],
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isEditing = ref(false);
    const org_id = ref(route.query.org_Id || null);
    const terId = ref(route.query.ter_Id || null);
    const oppId = ref(route.query.opp_Id || null);
    const apiErrors = ref(null);
    const isCheckboxVisible =ref(false);
    const roles = ref([]); 
    const contId = ref(route.query.contract_Id || null)

    // onBeforeMount(async () => {
    //   await store.dispatch('person/fetchPersonOptions');
    // });

    const toggleCheckboxes = () => {
      isCheckboxVisible.value = !isCheckboxVisible.value; 
    }

    const options = ref([]);

    const schema = Yup.object().shape({
      first_name: Yup.string()
        .required("First name is required"),
        // .min(3, "First name must be at least 3 characters"),
      email: Yup.string()
        .required("Email is required")
        .email("The email field must be a valid email address."),
      direct_telephone: Yup.string()
        .nullable()
        .notRequired()
        .max(15, "Direct Telephone must not be greater than 15 digits"),
      mobile_telephone: Yup.string()
        .nullable()
        .notRequired()
        .max(14, "Mobile must not be greater than 15 digits"),
    });

    if (route.query.personId) {
      // org_id.value = route.query.org_Id;
      isEditing.value = true;
    }
    const formData = ref({
      id: null,
      organization_id: route.query.org_Id,
      first_name: "",
      last_name: "",
      status: "",
      support_cases: "",
      email: "",
      block_mass_emails: 0,
      skype: "",
      salutation: "",
      job_title: "",
      birthday: "",
      direct_telephone: "",
      mobile_telephone: "",
      comment: "",
      job_function: "",
      safe_to_telephone: "",
      dir_owner_forename: "",
      dir_owner_surname: "",
      director_owner: "",
      director_date_of_birth: null,
      dir_owner_building_no: "",
      dir_owner_building_name: "",
      dir_owner_street: "",
      dir_owner_town: "",
      dir_owner_postcode: "",
      customer_bank_account_name: "",
      customer_account_no: "",
      customer_sort_code: "",
      customer_direct_debit_day: "",
      customer_payment_method: "",
      customer_bank_name: "",
      customer_bank_address: "",
      customer_contact_urn: "",
      key_accounting_system: 0,
      existing_dd: 0,
      content: "",
      date_of_birth: "",
      email_bounces: 0,
      language: "",
      role: [],
    });

    const Territories = ref([]);
    const Responsibles = ref([]);
    const Countries = ref([]);
    const logs = ref([]);

    const fetchPersonData = async (id) => {
      try {
        const res = await apiClient.get(`/organisation/viewPerson/${id}`);
        Object.assign(formData.value, res.data.data);
        if (res.data.data.director_date_of_birth) {
          formData.value.director_date_of_birth = new Date(
            res.data.data.director_date_of_birth
          );
        }
         if (res.data.data.date_of_birth) {
          formData.value.date_of_birth = new Date(
            res.data.data.date_of_birth
          );
        }
        formData.value.role = res.data.data.role ? res.data.data.role.split(',') : [];
        if(formData.value.role.length > 0){
          isCheckboxVisible.value = true;
        }
        formData.value.content = "";
        logs.value = res.data.data.logs;
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };

    const fetchOptions = async () => {
      try {
        const response = await apiClient.get("settings/organisation-person");
        options.value = response.data.data.dropdowns;
        roles.value = response.data.data.roles;
      } catch (error) {
        console.error("Error fetching options data:", error);
      }
    };

    const handleSubmit = async () => {
      const query = route.query.add;
      try {
        let response;
        if (formData.value.id) {
          response = await apiClient.post(
            `organisation/updatePerson/${formData.value.id}`,
            {
              ...formData.value,
                director_date_of_birth: changeFormate(
                formData.value.director_date_of_birth
              ),
              date_of_birth: changeFormate(
                formData.value.date_of_birth
              ),
              role: formData.value.role.toString()
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          //  handleCancel()
        } else {
          response = await apiClient.post("/organisation/createPerson", {
            ...formData.value,
            director_date_of_birth: changeFormate(formData.value.director_date_of_birth),
            date_of_birth: changeFormate( formData.value.date_of_birth),
            role: formData.value.role.toString()
          });
        }
          if (response.status === 200) {
           handleCancel()
          }
      } catch (error) {
        apiErrors.value = error.response.data.errors;
        console.error("Error saving organization data:", error);
      }
    };
    const handleCancel = () => {
      const query = route.query.add;
      if (query === "opp") {
        router.push({
          path: "/crm/create-meter-opportunity",
          query: { org_Id: route.query.org_Id, kanban: route.query.kanban },
        });
      } else if (query === "act") {
        router.push({
          path: "/crm/create-activity",
          query: { org_Id: route.query.org_Id },
        });
      } else if (query === "contract") {
        router.push({
          path: "/crm/create-contract",
          query: { org_Id: route.query.org_Id },
        });
      } else if (query === "org-details") {
        router.push({
           path: "/crm/organisation-details",
          query: {
            org_Id: route.query.org_Id,
            ter_Id: route.query.ter_Id,
            for: 'persons'
          },
        });
      } else if(contId.value){
        router.push({
           path: "/crm/edit-contract",
          query: {
            ter_Id: terId.value, org_Id: org_id.value, contract_Id: contId.value
          },
        });
      } else if(oppId.value){
        router.push({
           path: "/crm/meter-opportunities-edit",
          query: {
            ter_Id: terId.value, org_Id: org_id.value, opp_Id: oppId.value
          },
        });
      } else if (query === "quoting") {
            router.push("/quoting/get-quoting");
      }
      else {
        router.push({
          path: "/crm/person-list",
          query: {
            org_Id: route.query.org_Id,
          },
        });
      }
    };
    // Assuming form is a reactive object
    const formattedTelephone = computed({
      get() {
        // Ensure form and form.value.telephone are defined
        if (!formData.value || !formData.value.mobile_telephone) {
          return ""; // Return an empty string if there's no telephone number
        }

        const number = formData.value.mobile_telephone.replace(/\D/g, "");

        // Check if the number is valid (e.g., 10 digits)
        if (number.length === 10) {
          // Format the number
          return `(${number.substring(0, 3)}) ${number.substring(
            3,
            6
          )}-${number.substring(6)}`;
        } else {
          // Return the original value if the number is not valid
          return formData.value.mobile_telephone;
        }
      },
      set(value) {
        // Clean the input value to store only digits
        const cleanedValue = value.replace(/\D/g, "");

        // If the cleaned value is empty, set form.telephone to an empty string
        formData.value.mobile_telephone = cleanedValue.length ? cleanedValue : "";
      },
    });

    onMounted(async () => {
      const personId = route.query.personId;
      if (personId) {
        fetchPersonData(personId);
      }
      fetchOptions();
    });

    return {
      schema,
      formData,
      handleSubmit,
      handleCancel,
      isEditing,
      Territories,
      Responsibles,
      Countries,
      options,
      terId,
      org_id,
      contId,
      oppId,
      apiErrors,
      logs,
      formattedTelephone,
      isCheckboxVisible,
      toggleCheckboxes,
      roles
    };
  },
};
</script>
<style>
.edit-button {
  display: flex;
  justify-content: end;
  align-items: center;
}
</style>
