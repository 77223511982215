<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input type="text" class="form-control" placeholder="Search Activities" v-model="searchActQuery" @input="searchActivity" />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <select v-model="selectedFilter" class="form-select" @change="applyFilter">
                            <option v-for="item in filterOptions" :key="item.value" :value="item.value">
                              {{ item.text }}
                            </option>
                          </select>
                        </li>
                        <li>
                          <router-link to="/crm/create-activity" class="btn btn-primary"><i class="ti ti-square-rounded-plus"></i>Add Activity</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Activity List -->
              <div class="table-responsive custom-table">
                <activities-table :search-act-query="searchActQuery" :filters="appliedFilters" ref="actTable"></activities-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Activity List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/axios';
export default {
  data() {
    return {
      title: "Activities",
      searchActQuery: '',
      filterOptions: [],
      selectedFilter: 'all',
      appliedFilters: {
        timeFrame: 'all',
      }
    };
  },
  methods: {
    async searchActivity() {
      this.$refs.actTable.fetchActivities();
    },
    async fetchFilters() {
      try {
        const response = await apiClient.get('/dropdown/options/time_frame');
        if (response.status) {
          this.filterOptions = response.data;
        }
      } catch (error) {
        console.error('Error fetching time frame:', error);
      }
    },
    applyFilter() {
      this.appliedFilters.timeFrame = this.selectedFilter;
      this.searchActivity(); // Trigger the API call in the child component
    }
  },
  mounted() {
    this.fetchFilters();
  }
};
</script>
