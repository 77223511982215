// src/store/modules/org.js
import apiClient from '@/axios';
import router from '@/router';

const state = {
  dashBoard: {},
};

const getters = {
  getDashData: (state) => state.dashBoard,
};

const mutations = {
  SET_PIPELINE_DATA(state, data) {
    state.dashBoard = data;
  },

};

const actions = {
  async getDashboardData({ commit }, { search, type, page, pageSize }) {
    try {
      const response = await apiClient.get('/dashboard', {
        params: {
          search: search, // Assuming the API expects 'search' as the query parameter
          type: type,  // Assuming the API expects 'filter' for selected option value
          pageSize: pageSize,
          page:page
        },
      });
      if (response.data.status) {
        commit('SET_PIPELINE_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  },
  async getOrgData({ commit }, { page, pageSize }) {
    try {
      // const response = await apiClient.get('/dashboard');
      const response = await apiClient.get('/dashboard/organisation', {
        params: {
          pageSize: pageSize,
          page:page
        },
      });
      if (response.data.status) {
        // commit('SET_PIPELINE_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching organisation:', error);
    }
  },
  async getOverDueOpp({ commit }, { page, pageSize }) {
    try {
      // const response = await apiClient.get('/dashboard');
      const response = await apiClient.get('/dashboard/overdue-opportunity', {
        params: {
          pageSize: pageSize,
          page:page
        },
      });

      if (response.data.status) {
        // commit('SET_PIPELINE_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching opportunity:', error);
    }
  },
  async getReportsData({ commit }, { page, pageSize }) {
    try {
      const response = await apiClient.get('/dashboard/reports-charts', {
        params: {
          pageSize: pageSize,
          page:page
        },
      });

      if (response.data.status) {
        // commit('SET_PIPELINE_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching reports & charts:', error);
    }
  },
  async getActivities({ commit }, { page, pageSize }) {
    try {
      const response = await apiClient.get('/dashboard/activities', {
        params: {
          pageSize: pageSize,
          page:page
        },
      });

      if (response.data.status) {
        // commit('SET_PIPELINE_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  },
  async getOppCount({ commit }) {
    try {
      const response = await apiClient.get('/dashboard/pipeline-wise/opportunities/count');
      if (response.data.status) {
        // commit('SET_PIPELINE_DATA', response.data);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline-wise opportunities count:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
