<template>
  <div class="col-xl-3 col-lg-12 theiaStickySidebar">
    <!-- Settings Sidebar -->
    <div class="card">
      <div class="card-body">
        <div class="settings-sidebar">
          <h4>General Settings</h4>
          <ul>
            <li>
              <router-link to="/settings/profile">Profile</router-link>
            </li>
            <li>
              <router-link to="/settings/company" v-if="user.roles?.[0] === 'company_admin'">Company</router-link>
            </li>
            <li>
              <router-link to="/settings/security">Security</router-link>
            </li>
            <li>
              <router-link to="/settings/plan-types" v-if="user.roles?.[0] === 'super_admin'">Plan Types</router-link>
            </li>
            <li>
              <router-link to="/settings/credentials" v-if="user.roles?.[0] === 'company_admin'">Credentials</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Settings Sidebar -->
  </div>
</template>
<script>
import { mapGetters
} from "vuex";
export default {
  data() {
    return {
      title: "Dashboard",
    };
  },
  computed: {
    ...mapGetters('auth', ['user']), // 'auth' is the namespace of your Vuex module
  }
};
</script>