<template>
    <div class="row organisation-detail mx-0">
        <div class="col-md-12">
            <div class="row">
                <h4 class="pb-4 text-center">PAYMENT DETAILS
                </h4>
                <div class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Payment
                            Type</label>
                        <select id="country_id" v-model="paymentDetails.payment_type" class="form-select">
                            <option value="">
                                -----Select-----
                            </option>
                            <option v-for="res in options.org_payment_type" :key="res" :value="res.value">
                                {{ res.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Direct
                            debit
                            Start
                            Date

                        </label>
                        <div class="icon-form">
                            <date-picker text-input placeholder="" class="datetimepicker form-control"
                                v-model="paymentDetails.direct_debit_start_date" :editable="true"
                                :clearable="true" :input-format="dateFormat" @update:modelValue="clearDate" />

                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-0">
                        <label class="col-form-label">Account
                            Name</label>

                        <Field type="text" name="account_holder" class="form-control"
                            v-model="paymentDetails.account_holder">
                        </Field>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Account
                            Number</label>

                        <Field type="text" name="account_number" class="form-control"
                            v-model="paymentDetails.account_number">
                        </Field>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Sort
                            Code
                        </label>
                        <Field type="text" name="sort_code" class="form-control"
                            v-model="paymentDetails.sort_code">
                        </Field>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Bank
                            Name
                        </label>
                        <Field type="text" name="bank_name" class="form-control"
                            v-model="paymentDetails.bank_name">
                        </Field>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Bank
                            Postcode</label>
                        <Field type="text" name="bank_postcode" class="form-control"
                            v-model="paymentDetails.bank_postcode">
                        </Field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref
} from "vue";
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
        };
    },
    props: {
        options: {
            type: Object,
            default: () => {},
        },
        paymentDetails: {
            type: Object,
            default: () => {},
        }
    },
    setup() {
        const dob = ref(null)
        const clearDate = () => {
                dob.value = null;
        }
        return {
            clearDate,
            dob
        };
    },
};
</script>
