<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link :to="{ path: '/users/create-user', query: {userId: record.key}}" class="profile-split d-flex flex-column">{{ record.name }}</router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'Mobile'">
      <div>{{ record.phone_number }}</div>
    </template>
    <template v-else-if="column.key === 'action'">
          <router-link :to="{ path: '/users/create-user', query: {userId: record.key}}" class="btn table_action_btn"><i class="ti ti-edit"></i> Edit</router-link>
          <a class="btn table_action_btn ms-2" href="javascript:;" @click="confirmDelete(record.key)">
            <i class="ti ti-trash text-danger"></i> Delete
          </a>
    </template>
  </template>
</a-table>

<div class="modal-backdrop fade show" v-if="showDeleteModal"></div>
<div v-if="showDeleteModal" class="modal custom-modal fade show" style="display: block;" tabindex="-1" role="dialog" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 m-0 justify-content-end">
        <button class="btn-close" @click="showDeleteModal = false" aria-label="Close">
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="success-message text-center">
          <div class="success-popup-icon">
            <i class="ti ti-trash-x"></i>
          </div>
          <h3>Delete Account</h3>
          <p class="del-info">Are you sure you want to delete?</p>
          <div class="col-lg-12 text-center modal-btn">
            <button class="btn btn-light" @click="showDeleteModal = false">Cancel</button>
            <button class="btn btn-danger" @click="deleteUser(selectedUserId)">Yes, Delete it</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  reactive,
  onMounted
} from 'vue';
import {
  useRouter
} from 'vue-router';
import apiClient from "@/axios";
import {
    useStore
} from "vuex";
import {
  message
} from "ant-design-vue";

export default {
  props: {
    searchUserQuery: String,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const columns = [{
        title: "Name",
        dataIndex: "name",
        key: "Name",
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name)
        }
      },
      {
        title: "Access Level",
        dataIndex: "role",
        sorter: {
          compare: (a, b) => a.role.localeCompare(b.role)
        }
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: {
          compare: (a, b) => a.email.localeCompare(b.email)
        }
      },
      {
    title: "Territory",
    dataIndex: "territory",
    sorter: {
      compare: (a, b) => {
        a = a.territory;
        b = b.territory;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
      {
        title: "Mobile",
        dataIndex: "phone_number",
        key: "Mobile",
        sorter: {
          compare: (a, b) => a.phone_number.localeCompare(b.phone_number)
        }
      },
      {
        title: "Created",
        dataIndex: "created",
        sorter: {
          compare: (a, b) => a.created.localeCompare(b.created)
        }
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        sorter: false
      },
    ];

    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0
    });
    const rowSelection = reactive({
      onChange: () => {},
      onSelect: () => {},
      onSelectAll: () => {},
    });
    const showDeleteModal = ref(false);
    const selectedUserId = ref(null);

    // Methods
    const openEditModal = (id) => {
      router.push({
        path: "/users/create-user",
        query: {
          userId: id
        }
      });
    };

    const fetchUsers = async (page = 1, pageSize = 10) => {
      try {
        const params={
          search: props.searchUserQuery,
            page,
            pageSize,
            allUsers : 0
        }
        const response = await store.dispatch('users/fetchUsers', params);
        if (response.status) {
          data.value = response.data.data.map((user) => ({
            key: user.id,
            name: user.last_name ? `${user.first_name} ${user.last_name}` : user.first_name,
            role: user.role,
            phone_number: formatPhoneNumber(user.phone_number),
            login_name: user.login_name,
            territory: user.territory_name,
            email: user.email,
            created: user.created ? formatDate(user.created) : null,
          }));
          pagination.value.total = response.data.total;
        } else {
          console.error("Failed to fetch users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const formatPhoneNumber = (number) => {
      if (!number) {
        return ''; // Return an empty string if the input is empty or undefined
      }

      const cleaned = number.replace(/\D/g, '');

      // Check if the cleaned number is valid (e.g., 10 digits)
      if (cleaned.length === 10) {
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;
      } else {
        // Optionally, return a placeholder or the original input if not valid
        return number; // Or return a default placeholder like 'Invalid number'
      }
    };

    const deleteUser = async (id) => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          message.error("No token found. Please log in again.");
          return;
        }
        const response = await apiClient.delete(`company/users/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        if (response.status) {
          message.success("User deleted successfully!");
          showDeleteModal.value = false;
          fetchUsers(pagination.value.current, pagination.value.pageSize);
        } else {
          message.error("Failed to delete User.");
        }
      } catch (error) {
        console.error("Error deleting User:", error);
        message.error("Error deleting User.");
      }
    };
 // Function to convert date format
    const formatDate = (dateTime) => {
      if(dateTime){
        return dateTime.replace(/-/g, '/');
      }
    };
    const confirmDelete = (id) => {
      selectedUserId.value = id;
      showDeleteModal.value = true;
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      fetchUsers(pagination.value.current, newPagination.pageSize);
    };

    // Lifecycle hook
    onMounted(() => {
      fetchUsers();
    });

    return {
      columns,
      data,
      pagination,
      formatDate,
      rowSelection,
      showDeleteModal,
      selectedUserId,
      openEditModal,
      fetchUsers,
      formatPhoneNumber,
      deleteUser,
      confirmDelete,
      handleTableChange,
    };
  },
};
</script>
