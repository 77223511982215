<template>
    <div class="row organisation-detail mx-0 my-2">
        <div class="col-md-12">
            <h4 class="pb-4 text-center">PERSON DETAILS</h4>
            <div class="row">
                <div v-if="isEditing && personData.length > 0" class="col-md-12 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Person
                            List</label>
                        <Field as="select" name="person_list" id="person_list" v-model="personformData.id"
                            class="form-select" @change="onPersonChange">
                            <option value="">-----Select-----
                            </option>
                            <option v-for="res in personData" :key="res.id" :value="res.id">
                                {{ res.first_name + ' ' + res.last_name }}
                            </option>
                        </Field>
                        <ErrorMessage name="person_list" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Title <span class="text-danger"
                                v-if="validateLoa">*</span></label>
                        <Field as="select" name="salutation" id="salutation" v-model="personformData.salutation"
                            class="form-select">
                            <option value="">-----Select-----
                            </option>
                            <option v-for="res in settingOptions.org_p_salutation" :key="res" :value="res.value">
                                {{ res.text }}
                            </option>
                        </Field>
                        <span class="text-danger" v-if="validateLoa">Title is required</span>
                        <ErrorMessage name="salutation" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">First
                            Name <span class="text-danger" v-if="validateLoa">*</span></label>
                        <Field type="text" name="first_name" class="form-control" v-model="personformData.first_name">
                        </Field>
                        <ErrorMessage name="first_name" class="text-danger" />
                        <span class="text-danger" v-if="validateLoa">First name is required</span>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Last
                            Name <span class="text-danger" v-if="validateLoa">*</span></label>
                        <Field type="text" name="last_name" class="form-control" v-model="personformData.last_name">
                        </Field>
                        <ErrorMessage name="last_name" class="text-danger" />
                        <span class="text-danger" v-if="validateLoa">Last name is required</span>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Job
                            Title</label>
                        <Field type="text" name="job_title" class="form-control" v-model="personformData.job_title">
                        </Field>
                        <ErrorMessage name="job_title" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Email <span class="text-danger"
                                v-if="validateLoa">*</span></label>
                        <Field type="text" name="email" class="form-control" v-model="personformData.email">
                        </Field>
                        <span class="text-danger" v-if="validateLoa">Email is required</span>
                        <ErrorMessage name="email" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Direct
                            Line</label>
                        <Field type="text" name="direct_line" class="form-control" v-model="personformData.direct_line">
                        </Field>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Landline</label>
                        <Field type="text" name="landline" class="form-control" v-model="personformData.landline">
                        </Field>
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Date Of
                            Birth</label>
                        <div class="icon-form">
                            <Field name="date_of_birth" v-model="personformData.date_of_birth">
                                <date-picker text-input placeholder="" class="datetimepicker form-control"
                                    v-model="personformData.date_of_birth" :editable="true" :clearable="true"
                                    :input-format="dateFormat" @update:modelValue="clearDate" />

                                <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                            </Field>
                        </div>
                        <ErrorMessage name="date_of_birth" class="text-danger" />
                    </div>

                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Mobile </label>
                        <Field type="text" name="mobile_telephone" class="form-control"
                            v-model="personformData.mobile_telephone">
                        </Field>
                        <ErrorMessage name="mobile_telephone" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Direct
                            Debit
                            Day</label>
                        <Field as="select" name="customer_direct_debit_day" id="customer_direct_debit_day"
                            v-model="personformData.customer_direct_debit_day" class="form-select"
                            v-if="settingOptions && settingOptions.org_p_direct_debit_day">
                            <option value="">-----Select-----
                            </option>
                            <option v-for="source in settingOptions.org_p_direct_debit_day" :key="source.value"
                                :value="source.value">
                                {{ source.text }}
                            </option>
                        </Field>
                        <ErrorMessage name="customer_direct_debit_day" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-6 company-field">
                    <div class="form-wrap">
                        <label class="col-form-label">Payment
                            Method</label>
                        <Field as="select" name="customer_payment_method" id="customer_payment_method"
                            v-model="personformData.customer_payment_method" class="form-select"
                            v-if="settingOptions && settingOptions.org_p_payment_method">
                            <option value="">-----Select-----
                            </option>
                            <option v-for="source in settingOptions.org_p_payment_method" :key="source.value"
                                :value="source.value">
                                {{ source.text }}
                            </option>
                        </Field>
                        <ErrorMessage name="customer_payment_method" class="text-danger" />
                    </div>
                </div>
                <div class="col-md-12 company-field">
                    <div class="form-wrap mb-wrap">
                        <label class="col-form-label">Marketing
                            Preference (optional)</label>
                        <div>
                            <!-- Email -->
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" :value="'email'"
                                    v-model="personformData.marketing_prefrences" id="checkboxEmail" />
                                <label class="form-check-label" for="checkboxEmail">Email</label>
                            </div>
                            <!-- Phone -->
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" :value="'phone'"
                                    v-model="personformData.marketing_prefrences" id="checkboxPhone" />
                                <label class="form-check-label" for="checkboxPhone">Phone</label>
                            </div>
                            <!-- SMS -->
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" :value="'sms'"
                                    v-model="personformData.marketing_prefrences" id="checkboxSMS" />
                                <label class="form-check-label" for="checkboxSMS">SMS</label>
                            </div>
                            <!-- Post -->
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" :value="'post'"
                                    v-model="personformData.marketing_prefrences" id="checkboxPost" />
                                <label class="form-check-label" for="checkboxPost">Post</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="row">
                    <div class="col-12 text-end">
                        <button type="button" class="btn btn-design" @click="addNewPerson">New Contact</button>
                    </div>
                    <!-- <div class="col-6">
                        <button type="submit" class="btn btn-primary">
                            Save
                        </button>
                    </div> -->
                </div>
        </div>
    </div>
</template>

<script>
import {
    ref
} from "vue";
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
        };
    },
    props: {
        settingOptions: {
            type: Object,
            default: () => { },
        },
        personformData: {
            type: Object,
            default: () => { },
        },
        isEditing: {
            type: Boolean,
        },
        validateLoa: Boolean,
        personData: {
            type: Object,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const dob = ref(null)
        const clearDate = () => {
            dob.value = null;
        }
        const onPersonChange = () => {
            emit('change-person')
        }
        const addNewPerson = () => {
  Object.keys(props.personformData).forEach((key) => {
    if (key === "marketing_prefrences") {
      props.personformData[key] = []; // Reset to an empty array
    } else if (key === "date_of_birth") {
      props.personformData[key] = null; // Reset to null for date_of_birth
    } else {
      props.personformData[key] = ""; // Reset other fields to an empty string
    }
  });
};
        return {
            clearDate,
            dob,
            onPersonChange,
            addNewPerson
        };
    },
};
</script>
