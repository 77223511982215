<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <Form @submit="updateOpportunity" :validation-schema="schema">
            <div class="contact-head">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <ul class="contact-breadcrumb">
                    <li>
                      <button type="submit" class="custom-header-button"> <i class="ti ti-check"></i>
                        Save
                      </button>
                    </li>
                    <li>
                      <router-link
                        :to="{ path: '/crm/organisation-details', query: { ter_Id: terId, org_Id: org_id, for: 'opportunities' } }">
                        <i class="ti ti-arrow-narrow-left"></i>
                        Organisation
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="isKanban
                        ? '/crm/meter-opportunities-kanban'
                        : '/crm/meter-opportunities-list'
                        ">
                        <i class="ti ti-arrow-narrow-left"></i>
                        List
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <send-loa v-if="opportunityData && Object.keys(opportunityData).length" :orgPersons="orgPersons"
              :opportunityData="opportunityData" :settingOptions="settingOptions"></send-loa>
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="card">
                  <div class="card-body settings-form">
                    <div class="settings-sub-header">
                      <div class="row align-items-center">
                        <div class="col-6">
                          <h4>
                            Meter Opportunity -
                            {{ formData.meter_opportunity_nr_auto }} | Edit
                          </h4>
                        </div>
                        <div class="col-6 text-end contact-head d-flex align-items-center justify-content-end">
                          <ul class="contact-breadcrumb mb-0 me-3">
                            <li class="mb-0" v-if="orgPersonsDetails">
                                  <router-link
                                :to="{ path: '/crm/create-organisation', query: { org_Id: org_id, opp_Id: formData.id, add: 'editOpp' } }"
                                :title="'Edit' + ' ' + orgPersonsDetails.first_name + ' ' + orgPersonsDetails.last_name"><i
                                  class="ti ti-user-edit"></i> </router-link>
                            </li>
                            <li class="mb-0" v-if="(companyType === 'Partnership') || (companyType !== 'Partnership' && orgPersons.length === 0) || (orgPersons.length !== 0 && companyType === 'Partnership')">
                              <router-link title="Add Person" :to="{
                                path: '/crm/create-organisation',
                                query: { org_Id: org_id, opp_Id: formData.id,  add: 'editOpp' },
                              }"><i class="ti ti-user-plus"></i></router-link>
                            </li>
                          </ul>
                          <router-link v-if="quoteCount !== 0" :to="{
                            path: '/crm/quoted-price-list',
                            query: {
                              territory_id: territory_id,
                              org_Id: org_id,
                              opp_Id: oppId,
                            },
                          }" class="btn btn-teal me-2">
                            <i class="ion-pricetags"></i> Quoted
                            Prices</router-link>
                          <button type="button" class="btn btn-teal" @click="getQuote">
                            Get quote
                          </button>
                        </div>
                      </div>
                      <div class="row organisation-details-section mt-2" v-if="Object.keys(org_details).length">
                        <div class="col-md-6">
                          <h4 class="d-flex align-items-center my-2">
                            <a class="website me-2" :href="org_details.www">www</a>{{ org_details.name }}
                          </h4>
                          <p title="Postcode">{{ org_details.postcode }}</p>
                          <p title="Contact Person Name" v-if="orgPersonsDetails">

                            {{ orgPersonsDetails.salutation + ' ' + orgPersonsDetails.first_name + ' ' +
                              orgPersonsDetails.last_name + ' ' + orgPersonsDetails.director_owner }}
                          </p>
                          <p v-if="orgPersonsDetails.landline || org_details.telephone">
                            T: <span title="Organisation telephone" v-if="org_details.telephone">{{ org_details.telephone }}, </span><span title="Contact landline">{{ orgPersonsDetails.landline }}</span>
                          </p>
                          <p title="Contact mobile" v-if="orgPersonsDetails.mobile_telephone">
                            M: {{ orgPersonsDetails.mobile_telephone }}
                          </p>
                          <p title="Address" v-if="
                            org_details.city ||
                            org_details.state ||
                            country?.country_name
                          ">
                            {{
                              (org_details.city || "") +
                              ", " +
                              (org_details.state || "") +
                              ", " +
                              (country?.country_name || "")
                            }}
                          </p>
                          <p title="Tps" v-if="
                            org_details?.tps
                          ">
                            TPS: {{
                              (org_details?.tps || "N/A")
                            }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <div class="form-wrap mb-0">
                            <label class="col-form-label">Comment </label>
                            <textarea class="form-control" rows="4" v-model="formData.comment"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pro-create">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Pipeline level
                              <span class="text-danger">*</span></label>
                            <Field as="select" name="pipeline_level" class="form-select"
                              v-model="formData.pipeline_level" @change="fetchWinProb">
                              <!-- <option value="">-----Select-----</option> -->
                              <option v-for="res in pipelineLevels" :key="res.id" :value="res.id">
                                {{ res.name }}
                              </option>
                            </Field>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">
                              Current Supplier
                              <span class="text-danger">*</span>
                            </label>
                            <!-- Vee-Validate Field -->
                            <Field name="supplier_id" v-model="supplier_id">
                              <vue-select v-bind="field" :options="supplierOptions" v-model="formData.supplier_id"
                                placeholder="Select....." @select="myChangeEvent($event, 'current')" />
                            </Field>
                            <!-- Error Message -->
                            <ErrorMessage name="supplier_id" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.supplier_id === null || formData.supplier_id === '')
                            ">Current supplier is required</span>
                          </div>

                        </div>

                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Meter Opportunity nr [Auto]</label>
                            <input type="text" class="form-control" v-model="formData.meter_opportunity_nr_auto"
                              readonly @keypress="$utils.allowOnlyNumbers" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['meter_opportunity_nr_auto'] &&
                              apiErrors['meter_opportunity_nr_auto'].length
                            ">
                              {{ apiErrors["meter_opportunity_nr_auto"][0] }}
                            </span>
                          </div>
                        </div> -->
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">SC Forecast or Amended Annual Consumption
                              <span class="text-danger">*</span></label>
                            <!-- <Field as="input" name="sc_forecast_or_amended_annual_consumption" type="text" class="form-control" v-model="formattedData.sc_forecast_or_amended_annual_consumption" @keypress="$utils.allowOnlyNumbers" @blur="updateFormData('sc_forecast_or_amended_annual_consumption', formattedData.sc_forecast_or_amended_annual_consumption)" @focus="formattedData.sc_forecast_or_amended_annual_consumption = formatNumber(formData.forecast_commission.sc_forecast_or_amended_annual_consumption)" /> -->
                            <Field as="input" name="sc_forecast_or_amended_annual_consumption" type="text"
                              class="form-control" v-model="formData.forecast_commission
                                .sc_forecast_or_amended_annual_consumption
                                " @keypress="$utils.allowOnlyNumbers" />
                            <ErrorMessage name="sc_forecast_or_amended_annual_consumption" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.forecast_commission.sc_forecast_or_amended_annual_consumption === '0.00' || formData.forecast_commission.sc_forecast_or_amended_annual_consumption === '')
                            ">SC Forecast or Amended Annual Consumption is required</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">SC Forecast Total Commission</label>
                            <input type="text" class="form-control" v-model="formData.sc_forecast_total_commission"
                              @keypress="$utils.allowOnlyNumbers" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['sc_forecast_total_commission'] &&
                              apiErrors['sc_forecast_total_commission'].length
                            ">
                              {{ apiErrors["sc_forecast_total_commission"][0] }}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Main product/service <span class="text-danger"
                                v-if="piplelineType === 1">*</span></label>
                            <Field as="select" name="main_production_service" class="form-select"
                              v-model="formData.main_production_service">
                              <option value="">-----Select-----</option>
                              <option v-for="res in options.op_product" :key="res.value" :value="res.value">
                                {{ res.text }}
                              </option>
                            </Field>
                            <ErrorMessage name="main_production_service" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.main_production_service === null || formData.main_production_service === '')
                            ">Main product/service is required</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="formData.main_production_service?.toLowerCase() === 'gas'">
                          <div class="form-wrap">
                            <label class="col-form-label">MPR NO.
                              <span class="text-danger">*</span></label>
                            <Field name="mpr" as="input" type="text" class="form-control" v-model="formData.mpr" />
                            <ErrorMessage v-if="!isNotValid" name="mpr" class="text-danger" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['mpr'] &&
                              apiErrors['mpr'].length
                            ">
                              {{ apiErrors["mpr"][0] }}
                            </span>
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.mpr === null || formData.mpr === '')
                            ">MPR No. is required</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="formData.main_production_service?.toLowerCase() === 'elec'">
                          <div class="form-wrap">
                            <label class="col-form-label">MPAN Top
                              <span class="text-danger">*</span></label>
                            <Field name="mpan_top" as="input" type="text" class="form-control"
                              v-model="formData.mpan_top" />
                            <ErrorMessage v-if="!isNotValid" name="mpan_top" class="text-danger" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['mpan_top'] &&
                              apiErrors['mpan_top'].length
                            ">
                              {{ apiErrors["mpan_top"][0] }}
                            </span>
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.mpan_top === null || formData.mpan_top === '')
                            ">MPAN Top is required</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="formData.main_production_service?.toLowerCase() === 'elec'">
                          <div class="form-wrap">
                            <label class="col-form-label">MPAN Bottom
                              <span class="text-danger">*</span></label>
                            <Field name="mpan_bottom" as="input" type="text" class="form-control"
                              v-model="formData.mpan_bottom" />
                            <ErrorMessage v-if="!isNotValid" name="mpan_bottom" class="text-danger" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['mpan_bottom'] &&
                              apiErrors['mpan_bottom'].length
                            ">
                              {{ apiErrors["mpan_bottom"][0] }}
                            </span>
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.mpan_bottom === null || formData.mpan_bottom === '')
                            ">MPAN Bottom is required</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="isShowWon || isLost">
                        <div class="row mx-1 mb-2" :class="isLost ? 'contract-lost-row' : 'contract-won-row'
                          ">
                          <h5 class="text-center my-3" :class="isLost ? 'text-danger' : 'text-green'">
                            {{
                              isShowWon
                                ? "Contract: Contract - won"
                                : "Lost meter opportunity"
                            }}
                          </h5>
                          <div class="col-md-4">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-wrap">
                                  <label class="col-form-label">Date of Sale</label>
                                  <div class="icon-form">
                                    <date-picker text-input placeholder="" class="datetimepicker form-control"
                                      v-model="formData.date_of_sale" :editable="true" :clearable="true"
                                      :input-format="dateFormat" />

                                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="isShowWon">
                            <div class="form-wrap">
                              <label class="col-form-label">Contract: responsible</label>
                              <Field as="select" name="contract_responsible" class="form-select" v-model="formData.contracts.contract_responsible
                                ">
                                <option value="">-----Select-----</option>
                                <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                                  {{ res.name }}
                                </option>
                              </Field>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="isShowWon">
                            <div class="form-wrap">
                              <label class="col-form-label">Description</label>
                              <Field name="contract_description" as="input" type="text" class="form-control" v-model="formData.contracts.contract_description
                                " />
                              <!-- <ErrorMessage name="contract_description" class="text-danger" /> -->
                            </div>
                          </div>
                          <div class="col-md-4" v-if="isShowWon">
                            <div class="form-wrap">
                              <label class="col-form-label">Contract: contact</label>
                              <Field as="select" name="contract_contact" class="form-select"
                                v-model="formData.contracts.contract_contact">
                                <option value="">-----Select-----</option>
                                <option v-for="res in orgPersons" :key="res.id" :value="res.id">
                                  {{ res.name }}
                                </option>
                              </Field>
                            </div>
                          </div>
                          <!-- <div class="col-md-4" v-if="isShowWon">
                            <div class="form-wrap">
                              <label class="col-form-label"
                                >Overall status</label
                              >
                              <Field
                                as="select"
                                name="overall_status"
                                class="form-select"
                                v-model="formData.contracts.overall_status"
                              >
                                <option value="">-----Select-----</option>
                                <option
                                  v-for="res in options.contract_overall_status"
                                  :key="res.value"
                                  :value="res.value"
                                >
                                  {{ res.text }}
                                </option>
                              </Field>
                            </div>
                          </div> -->
                          <!-- <div class="col-md-4" v-if="isShowWon">
                            <div class="form-wrap">
                              <label class="col-form-label">Overall risk</label>
                              <Field as="select" name="overall_risk" class="form-select"
                                v-model="formData.contracts.overall_risk">
                                <option value="">-----Select-----</option>
                                <option v-for="res in options.contract_overall_risk" :key="res.value"
                                  :value="res.value">
                                  {{ res.text }}
                                </option>
                              </Field>
                            </div>
                          </div> -->
                          <div class="col-md-4" v-if="isShowWon">
                            <div class="form-wrap">
                              <label class="col-form-label">Contract nr [Auto]</label>
                              <input type="text" class="form-control" v-model="formData.contract_nr_auto" />
                              <span class="text-danger" v-if="
                                apiErrors &&
                                apiErrors['contract_nr_auto'] &&
                                apiErrors['contract_nr_auto'].length
                              ">
                                {{ apiErrors["contract_nr_auto"][0] }}
                              </span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="isLost">
                            <div class="form-wrap">
                              <label class="col-form-label">Reason</label>
                              <Field as="select" name="reason" class="form-select" v-model="formData.reason">
                                <option value="">-----Select-----</option>
                                <option v-for="res in options.op_reason" :key="res.value" :value="res.value">
                                  {{ res.text }}
                                </option>
                              </Field>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="isLost">
                            <div class="form-wrap">
                              <label class="col-form-label">Competitor</label>
                              <Field as="select" name="competitor" class="form-select" v-model="formData.competitor">
                                <option value="">-----Select-----</option>
                                <option v-for="res in options.op_competitor" :key="res.value" :value="res.value">
                                  {{ res.text }}
                                </option>
                              </Field>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="isLost">
                            <div class="form-wrap">
                              <label class="col-form-label">Explanation</label>
                              <input type="text" class="form-control" v-model="formData.explanation" />
                              <span class="text-danger" v-if="
                                apiErrors &&
                                apiErrors['explanation'] &&
                                apiErrors['explanation'].length
                              ">
                                {{ apiErrors["explanation"][0] }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4" v-if="!isShowWon">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-8">
                                  <div class="form-wrap">
                                    <label class="col-form-label">Next update latest</label>
                                    <div class="icon-form" v-if="isDateEditable">
                                      <date-picker text-input placeholder="" class="datetimepicker form-control"
                                        v-model="formData.next_update_latest_date
                                          " :editable="true" :clearable="true" :input-format="dateFormat"
                                        @update:modelValue="setTime" />
                                      <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                    </div>
                                    <div class="row form-control mx-0 mb-0 disabled d-flex px-0" v-else>
                                      <div class="col-4">
                                        {{ formattedNextUpdateLatest }}
                                      </div>
                                      <div class="col-8 text-end">
                                        Locked to pipeline level
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="col-form-label">Time</label>
                                  <div class="form-wrap icon-form">
                                    <!-- <div class="custom-time-range d-flex align-items-center select2css">
                                    
                                      <vue-select class="no-arrow" v-model="selectedHour" :options="hours"
                                        :settings="{ allowClear: false, placeholder: 'hh', minimumResultsForSearch: Infinity }"
                                         />
                                         :
                                    
                                      <vue-select class="no-arrow" v-model="selectedMinute" :options="customMinutes"
                                        :settings="{ allowClear: false, placeholder: 'mm', minimumResultsForSearch: Infinity }"
                                         />
                                    </div> -->
                                    <!-- <input class="form-control datetimepicker-time" v-model="formattedTime"
                                      @change="updateTime" ref="timeInput" @click="openTimePicker"
                                      placeholder="Select Time" type="time" /> -->
                                    <a-space direction="vertical">
                                      <a-time-picker class="timepicker" v-model:value="timeValue" :minute-step="15"
                                        format="HH:mm" @change="handleTimeChange" @open-change="onPickerOpen" />
                                    </a-space>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12" v-if="isDateEditable">
                              <div class="form-wrap">
                                <div class="row mb-1">
                                  <div class="col-2">Days:</div>
                                  <div class="col add-cursor text-teal" @click="
                                    addToDate(
                                      'next_update_latest_date',
                                      'days',
                                      day
                                    )
                                    " v-for="day in addDays" :key="day" :id="day">
                                    +{{ day }}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-2">Months:</div>
                                  <div class="col add-cursor text-teal" @click="
                                    addToDate(
                                      'next_update_latest_date',
                                      'months',
                                      month
                                    )
                                    " v-for="month in addMonths" :key="month" :id="month">
                                    +{{ month }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="!isShowWon">
                          <div class="row">
                            <div class="col-12">
                              <div class="form-wrap">
                                <label class="col-form-label">Date of Sale</label>
                                <div class="icon-form">
                                  <date-picker text-input placeholder="" class="datetimepicker form-control"
                                    v-model="formData.date_of_sale" :editable="true" :clearable="true"
                                    :input-format="dateFormat" />
                                  <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-12">
                              <div class="form-wrap">
                                <div class="row mb-1">
                                  <div class="col-2">Days:</div>
                                  <div class="col add-cursor text-teal" @click="
                                    addToDate('date_of_sale', 'days', day)
                                    " v-for="day in addDays" :key="day" :id="day">
                                    +{{ day }}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-2">Months:</div>
                                  <div class="col add-cursor text-teal" @click="
                                    addToDate('date_of_sale', 'months', month)
                                    " v-for="month in addMonths" :key="month" :id="month">
                                    +{{ month }}
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Contract End Date</label>
                            <div class="icon-form">
                              <date-picker text-input placeholder="" class="datetimepicker form-control"
                                v-model="formData.contract_end_date" :editable="true" :clearable="true"
                                :input-format="dateFormat" />
                              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="!isShowWon" v-show="!isLost">
                          <div class="form-wrap">
                            <label class="col-form-label">Win probability %</label>
                            <Field as="select" name="win_probability" class="form-select"
                              v-model="formData.win_probability" v-if="isEditable">
                              <option value="">-----Select-----</option>
                              <option v-for="res in options.op_win_probability" :key="res.value" :value="res.value">
                                {{ res.text }}
                              </option>
                            </Field>
                            <div class="row form-control mb-0 disabled mx-0 d-flex px-0" v-else>
                              <div class="col-2">
                                {{ formData.win_probability }}
                              </div>
                              <div class="col-10 text-end">
                                Locked to pipeline level
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="formData.main_production_service?.toLowerCase() === 'water'">
                          <div class="form-wrap">
                            <label class="col-form-label">SPID</label>
                            <Field name="spid" as="input" type="text" class="form-control" v-model="formData.spid" />
                            <!-- <ErrorMessage name="spid" class="text-danger" /> -->
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['spid'] &&
                              apiErrors['spid'].length
                            ">
                              {{ apiErrors["spid"][0] }}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label d-flex justify-content-between w-100">Description</label>
                            <Field name="description" as="input" type="text" class="form-control"
                              v-model="formData.description" />
                            <!-- <ErrorMessage name="description" class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label d-flex w-100 justify-content-between"><span>Sales contact
                                <span class="text-danger">*</span></span>
                              <router-link :to="{
                                path: '/crm/create-person',
                                query: { org_Id: org_id },
                              }" class="btn text-teal text-decoration-underline p-0" v-if="(companyType === 'Partnership') || (companyType !== 'Partnership' && orgPersons.length === 0) || (orgPersons.length !== 0 && companyType === 'Partnership')">
                                Add Sales Contact
                              </router-link>
                            </label>
                            <Field as="select" name="sales_contact" class="form-select" v-model="formData.sales_contact">
  <option value="">-----Select-----</option>
  <option v-for="res in orgPersons" :key="res.id" :value="res.id">
    {{ res.name }}
  </option>
  <option v-if="orgPersons.length === 0" disabled>
    No contacts available
  </option>
</Field>
                            <ErrorMessage name="sales_contact" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.sales_contact === null || formData.sales_contact === '')
                            ">Sales contact is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">SC Forecast Total Commission</label>
                            <input type="text" class="form-control" v-model="formData.sc_forecast_total_commission"
                              @keypress="$utils.allowOnlyNumbers" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['sc_forecast_total_commission'] &&
                              apiErrors['sc_forecast_total_commission'].length
                            ">
                              {{ apiErrors["sc_forecast_total_commission"][0] }}
                            </span>
                          </div>
                        </div>
                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Quoted Annual Consumption</label>
                            <input type="text" class="form-control" v-model="formData.quoted_annual_consumption"
                              @keypress="$utils.allowOnlyNumbers" />
                            <span class="text-danger" v-if="
                              apiErrors &&
                              apiErrors['quoted_annual_consumption'] &&
                              apiErrors['quoted_annual_consumption'].length
                            ">
                              {{ apiErrors["quoted_annual_consumption"][0] }}
                            </span>
                          </div>
                        </div> -->
                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Quotation</label>
                            <input type="text" class="form-control" v-model="formData.quotation" />
                          </div>
                        </div> -->

                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Sales responsible
                              <span class="text-danger">*</span></label>
                            <Field as="select" name="sales_responsible" class="form-select"
                              v-model="formData.sales_responsible">
                              <option value="" v-if="saleResponsibleOptions.length > 1">-----Select-----</option>
                              <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                                {{ res.name }}
                              </option>
                            </Field>
                            <ErrorMessage name="sales_responsible" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.sales_responsible === null || formData.sales_responsible === '')
                            ">Sales responsible is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Referred By </label>
                            <Field as="select" name="referred_by" class="form-select" v-model="formData.referred_by">
                              <option value="">-----None-----</option>
                              <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                                {{ res.name }}
                              </option>
                            </Field>
                          </div>
                        </div>

                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">CM Responsible </label>
                            <Field as="select" name="cm_responsible" class="form-select"
                              v-model="formData.cm_responsible">
                              <option value="">-----None-----</option>
                              <option v-for="res in cmResponsibleOptions" :key="res.id" :value="res.id">
                                {{ res.name }}
                              </option>
                            </Field>
                          </div>
                        </div> -->

                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">LOA Status </label>
                            <Field as="select" name="loa_status" class="form-select" v-model="formData.loa_status">
                              <option value="">-----Select-----</option>
                              <option v-for="res in options.loa_status" :key="res.value" :value="res.value">
                                {{ res.text }}
                              </option>
                            </Field>
                          </div>
                        </div> -->

                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Town</label>
                            <input type="text" class="form-control" v-model="formData.town" />
                          </div>
                        </div> -->

                        <!-- <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Reason </label>
                            <Field as="select" name="reason" class="form-select" v-model="formData.reason">
                              <option value="">-----Select-----</option>
                              <option v-for="res in options.reason" :key="res.value" :value="res.value">
                                {{ res.text }}
                              </option>
                            </Field>
                          </div>
                        </div> -->
                        <!-- <div v-if="isTps" class="col-md-4">
                          <div class="form-wrap mb-wrap">
                            <label class="col-form-label">TPS</label>
                            <input type="text" disabled class="form-control" v-model="formData.tps" />
                          </div>
                        </div> -->

                        <h4 class="mb-2 py-2 text-danger">New Supplier</h4>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">New Supplier </label>
                            <vue-select :options="supplierOptions" :key="supplierOptions.id" :id="supplierOptions.id"
                              v-model="formData.new_supplier_id" placeholder="Search Supplier....."
                              @select="myChangeEvent($event, 'new')" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Contract Start Date
                              <span class="text-danger">*</span></label>
                            <div class="icon-form">
                              <Field name="contract_start_date" v-model="contract_start_date">
                                <date-picker text-input placeholder="" class="datetimepicker form-control"
                                  v-model="formData.contract_start_date" :editable="true" :clearable="true"
                                  :input-format="dateFormat" @update:modelValue="clearDate" />
                                <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                              </Field>

                            </div>
                            <ErrorMessage name="contract_start_date" class="text-danger" />

                            <span class="text-danger" v-if="
                              isNotValid &&
                              formData.contract_start_date === null
                            ">Contract Start Date is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Sales Type</label>
                            <Field as="select" name="sale_type" class="form-select" v-model="formData.sale_type">
                              <option value="">-----Select-----</option>
                              <option v-for="res in options.op_sales_type" :key="res.value" :value="res.value">
                                {{ res.text }}
                              </option>
                            </Field>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">COT</label>
                            <Field as="select" name="cot" class="form-select" v-model="formData.cot">
                              <option value="">-----Select-----</option>
                              <option v-for="res in yesNoOptions" :key="res" :value="res">
                                {{ res }}
                              </option>
                            </Field>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">COT Date</label>
                            <div class="icon-form">
                              <date-picker text-input placeholder="" class="datetimepicker form-control"
                                v-model="formData.cot_date" :editable="true" :clearable="true"
                                :input-format="dateFormat" />
                              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">NIC</label>
                            <Field as="select" name="nic" class="form-select" v-model="formData.nic">
                              <option value="">-----Select-----</option>
                              <option v-for="res in yesNoOptions" :key="res" :value="res">
                                {{ res }}
                              </option>
                            </Field>
                          </div>
                        </div>

                        <h4 class="mb-2 py-2 text-indigo">Supply Address</h4>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Building Name
                              <span class="text-danger">*</span></label>
                            <Field name="building_name" as="input" type="text" class="form-control"
                              v-model="formData.supply_address.building_name" />
                            <ErrorMessage name="building_name" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.supply_address.building_name === null || formData.supply_address.building_name === '')
                            ">Building Name is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Street <span class="text-danger">*</span></label>
                            <Field name="street" as="input" type="text" class="form-control"
                              v-model="formData.supply_address.street" />
                            <ErrorMessage name="street" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.supply_address.street === null || formData.supply_address.street === '')
                            ">Street is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">City <span class="text-danger">*</span></label>
                            <Field name="city" as="input" type="text" class="form-control"
                              v-model="formData.supply_address.city" />
                            <ErrorMessage name="city" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.supply_address.city === null || formData.supply_address.city === '')
                            ">City is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">County <span class="text-danger">*</span></label>
                            <Field name="county" as="input" type="text" class="form-control"
                              v-model="formData.supply_address.county" />
                            <ErrorMessage name="county" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.supply_address.county === null || formData.supply_address.county === '')
                            ">County is required</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Postcode
                              <span class="text-danger">*</span></label>
                            <Field name="postcode" as="input" type="text" class="form-control"
                              v-model="formData.supply_address.postcode" />
                            <ErrorMessage name="postcode" class="text-danger" />
                            <span class="text-danger" v-if="
                              isNotValid &&
                              formData.supply_address.postcode === null
                            ">Postcode is required</span>
                          </div>
                        </div>
                        <!-- <div class="col-md-4 mb-3">
                          <div class="form-wrap mb-0">
                            <label class="col-form-label">Comment </label>
                            <textarea class="form-control" rows="1" v-model="formData.comment"></textarea>
                          </div>
                        </div> -->

                        <h4 class="mb-2 py-2 text-indigo">Billing address</h4>
                        <div class="mb-2">
                          <label class="me-2">
                            <input type="checkbox" :checked="selectedOption === 'supply'"
                              @change="selectOption('supply')" />
                            Same as Supply Address
                          </label>
                          <label>
                            <input type="checkbox" :checked="selectedOption === 'organisation'"
                              @change="selectOption('organisation')" />
                            Same as Company Address
                          </label>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Address Line 1</label>
                            <Field name="address_line_1" as="input" type="text" class="form-control"
                              v-model="formData.billing_address.address_line_1" @change="handleChange" />
                            <ErrorMessage name="address_line_1" class="text-danger" />
                            <!-- <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.billing_address.address_line_1 === null || formData.billing_address.address_line_1 === '')
                            ">Address Line 1 is required</span> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Address Line 2 </label>
                            <Field name="address_line_2" as="input" type="text" class="form-control"
                              v-model="formData.billing_address.address_line_2" @change="handleChange" />
                            <ErrorMessage name="address_line_2" class="text-danger" />
                            <!-- <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.billing_address.address_line_2 === null || formData.billing_address.address_line_2 === '')
                            ">Address Line 2 is required</span> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Address Line 3 </label>
                            <Field name="address_line_3" as="input" type="text" class="form-control"
                              v-model="formData.billing_address.address_line_3" @change="handleChange" />
                            <ErrorMessage name="address_line_3" class="text-danger" />
                            <!-- <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.billing_address.address_line_3 === null || formData.billing_address.address_line_3 === '')
                            ">Address Line 3 is required</span> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Address Line 4 </label>
                            <Field name="address_line_4" as="input" type="text" class="form-control"
                              v-model="formData.billing_address.address_line_4" @change="handleChange" />
                            <ErrorMessage name="county" class="text-danger" />
                            <!-- <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.billing_address.address_line_4 === null || formData.billing_address.address_line_4 === '')
                            ">Address Line 4 is required</span> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Postcode</label>
                            <Field name="billing_postcode" as="input" type="text" class="form-control"
                              v-model="formData.billing_address.postcode" @change="handleChange" />
                            <ErrorMessage name="billing_postcode" class="text-danger" />
                            <!-- <span class="text-danger" v-if="
                              isNotValid &&
                              (formData.billing_address.postcode === null || formData.billing_address.postcode === '')
                            ">Postcode is required</span> -->
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-4">
                        <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1">
                          <h4 class="p-2">Logs</h4>
                          <p class="col-form-label p-2" style="background-color: #eff1f0">
                            Add log entry
                          </p>
                          <textarea class="form-control" rows="3" v-model="formData.content"></textarea>
                        </div>
                        <div class="row my-3">
                          <div class="col-md-12">
                            <div class="form-wrap mb-0 shadow" style="border: 1px solid #e1e1e1">
                              <p class="col-form-label p-2" style="background-color: #eff1f0">
                                Logs
                              </p>
                              <div class='logs_Section'>
                                <div class="row" v-for="log in logs">
                                  <div class="col-12">
                                    <p class="p-2" style="border-bottom: 1px solid #e1e1e1">
                                      {{ log.content }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-md-12">
                            <div class="form-wrap mb-0 shadow" style="border: 1px solid #e1e1e1">
                              <h4 class="p-2" style="background-color: #eff1f0">
                                Documents
                              </h4>
                              <div>
                                <div class="row">
                                  <div class="col-12">
                                    <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave"
                                      @drop="drop">
                                      <input type="file" multiple name="file" id="fileInput" class="hidden-input"
                                        @change="onChange" ref="fileInput" />

                                      <label for="fileInput" class="file-label">
                                        <div v-if="isDragging">Release to drop files here.</div>
                                        <div v-else>Choose a file or drop it here</div>
                                        <p style="font-size: 10px;">
                                          The file must be a type of : pdf, doc, docx, png, jpeg, jpg, mp3, mp4, flac,
                                          wav.
                                        </p>
                                      </label>
                                    </div>
                                    <div class="table-responsive">
                                      <table class="table table-bordered logs_Section">
                                        <tbody>
                                          <tr v-for="file in displayedFiles" :key="file.id">
                                            <td>
                                              <a href="javascript:void(0);" title="Download file"
                                                @click="downloadFile(file)"
                                                class="btn documnet-btn btn-primary export_btn p-1">
                                                <i class="ti ti-download icon-size"></i>
                                              </a>
                                            </td>
                                            <td>
                                              <i class="ti ti-x icon-size" @click="deleteFile(file.id)"
                                                style="color: red;"></i>
                                            </td>
                                            <td class="truncate-doc-name">
                                              <a href="#" @click="viewFile(file.file_path)">
                                                {{ file.doc_name.length > 20 ? file.doc_name.substr(0, 15) + "...." :
                                                  file.doc_name }}
                                              </a>
                                            </td>
                                            <td class="d-flex flex-column align-items-center">
                                              <span>{{ formatDate(file.created_at) }}</span>
                                              <span>{{ file.file_size + file.file_size_unit }}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div v-if="fileData.length > 4" class="col-12">
                                    <p class="p-2" @click="toggleViewMore" style="cursor: pointer; color: blue;">
                                      {{ showAllFiles ? "View less ..." : "View more ..." }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-8">
                        <div class="shadow p-3" style="border: 1px solid #e1e1e1">
                          <h4 class="mb-3 text-indigo">
                            CM Team Fields
                          </h4>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Cancelled Commission</label>
                                <input name="cancelled_commission" type="text" class="form-control"
                                  v-model="formattedData.cancelled_commission" @blur="
                                    updateFormData(
                                      'cancelled_commission',
                                      formattedData.cancelled_commission
                                    )
                                    " @focus="
                                      formattedData.cancelled_commission =
                                      formatNumber(
                                        formData.forecast_commission
                                          .cancelled_commission
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">*SC Forecast or Amended Total
                                  Commission</label>
                                <input name="sc_corecast_or_amended_total_commission" type="text" class="form-control"
                                  v-model="formattedData.sc_corecast_or_amended_total_commission
                                    " @blur="
                                      updateFormData(
                                        'sc_corecast_or_amended_total_commission',
                                        formattedData.sc_corecast_or_amended_total_commission
                                      )
                                      " @focus="
                                        formattedData.sc_corecast_or_amended_total_commission =
                                        formatNumber(
                                          formData.forecast_commission
                                            .sc_corecast_or_amended_total_commission
                                        )
                                        " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">SC Forecast or Amended First Payment</label>
                                <input name="sc_forecast_or_amended_first_payment" type="text" class="form-control"
                                  v-model="formattedData.sc_forecast_or_amended_first_payment
                                    " @blur="
                                      updateFormData(
                                        'sc_forecast_or_amended_first_payment',
                                        formattedData.sc_forecast_or_amended_first_payment
                                      )
                                      " @focus="
                                        formattedData.sc_forecast_or_amended_first_payment =
                                        formatNumber(
                                          formData.forecast_commission
                                            .sc_forecast_or_amended_first_payment
                                        )
                                        " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">SC Forecast or Amended Residual
                                  Payment</label>
                                <input name="sc_forecast_or_amended_residual_payment" type="text" class="form-control"
                                  v-model="formattedData.sc_forecast_or_amended_residual_payment
                                    " @blur="
                                      updateFormData(
                                        'sc_forecast_or_amended_residual_payment',
                                        formattedData.sc_forecast_or_amended_residual_payment
                                      )
                                      " @focus="
                                        formattedData.sc_forecast_or_amended_residual_payment =
                                        formatNumber(
                                          formData.forecast_commission
                                            .sc_forecast_or_amended_residual_payment
                                        )
                                        " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">U4B Uplift
                                  <!-- <span class="text-danger">*</span> -->
                                </label>
                                <!-- <Field as="input" name="u4b_uplift" type="text" class="form-control" v-model="formattedData.u4b_uplift" @blur="updateFormData('u4b_uplift', formattedData.u4b_uplift)" @focus="formattedData.u4b_uplift = formatNumber(formData.forecast_commission.u4b_uplift)" @keypress="$utils.allowOnlyNumbers" /> -->
                                <Field as="input" name="u4b_uplift" type="text" class="form-control" v-model="formData.forecast_commission.u4b_uplift
                                  " @keypress="$utils.allowOnlyNumbers" />
                                <ErrorMessage name="u4b_uplift" class="text-danger" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Final Contract Consumption</label>
                                <input name="final_contract_consumption" type="text" class="form-control" v-model="formattedData.final_contract_consumption
                                  " @blur="
                                    updateFormData(
                                      'final_contract_consumption',
                                      formattedData.final_contract_consumption
                                    )
                                    " @focus="
                                      formattedData.final_contract_consumption =
                                      formatNumber(
                                        formData.forecast_commission
                                          .final_contract_consumption
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Failed Credit</label>
                                <input name="failed_credit" type="text" class="form-control"
                                  v-model="formattedData.failed_credit" @blur="
                                    updateFormData(
                                      'failed_credit',
                                      formattedData.failed_credit
                                    )
                                    " @focus="
                                      formattedData.failed_credit = formatNumber(
                                        formData.forecast_commission.failed_credit
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Final Contract Commission</label>
                                <input name="final_contract_commission" type="text" class="form-control" v-model="formattedData.final_contract_commission
                                  " @blur="
                                    updateFormData(
                                      'final_contract_commission',
                                      formattedData.final_contract_commission
                                    )
                                    " @focus="
                                      formattedData.final_contract_commission =
                                      formatNumber(
                                        formData.forecast_commission
                                          .final_contract_commission
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Actual First Payment</label>

                                <input name="actual_first_payment" type="text" class="form-control"
                                  v-model="formattedData.actual_first_payment" @blur="
                                    updateFormData(
                                      'actual_first_payment',
                                      formattedData.actual_first_payment
                                    )
                                    " @focus="
                                      formattedData.actual_first_payment =
                                      formatNumber(
                                        formData.forecast_commission
                                          .actual_first_payment
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Actual Residual Payment</label>

                                <input name="actual_residual_payment" type="text" class="form-control" v-model="formattedData.actual_residual_payment
                                  " @blur="
                                    updateFormData(
                                      'actual_residual_payment',
                                      formattedData.actual_residual_payment
                                    )
                                    " @focus="
                                      formattedData.actual_residual_payment =
                                      formatNumber(
                                        formData.forecast_commission
                                          .actual_residual_payment
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">Forecast First Payment Amount</label>

                                <input name="forecast_first_payment_amount" type="text" class="form-control" v-model="formattedData.forecast_first_payment_amount
                                  " @blur="
                                    updateFormData(
                                      'forecast_first_payment_amount',
                                      formattedData.forecast_first_payment_amount
                                    )
                                    " @focus="
                                      formattedData.forecast_first_payment_amount =
                                      formatNumber(
                                        formData.forecast_commission
                                          .forecast_first_payment_amount
                                      )
                                      " @keypress="$utils.allowOnlyNumbers" />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-wrap">
                                <label class="col-form-label">KVA</label>
                                <input type="text" class="form-control" v-model="formData.forecast_commission.kva" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="submit-button mt-4">
                      <div class="row">
                        <div class="col-6">
                          <button type="button" class="btn btn-light" @click="handleCancel">
                            Back
                          </button>
                        </div>
                        <div class="col-6 text-end">
                          <button type="submit" class="btn btn-primary">
                            Save
                          </button>
                          <button type="button" class="btn btn-teal" @click="getQuote">
                            Get quote
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { changeFormate } from '../../../../util'
import apiClient from "@/axios";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
import dayjs from 'dayjs';
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    openTimePicker() {
      this.$refs.timeInput.showPicker();
    },
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "dd/MM/yyyy",
      yesNoOptions: ["YES", "NO"],
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const org_id = ref(route.query.org_Id || null);
    const isEditable = ref(true);
    const isDateEditable = ref(true);
    const isShowWon = ref(false);
    const isLost = ref(false);
    const oppId = ref(route.query.opp_Id || null);
    const terId = ref(route.query.ter_Id || null)
    // const isValidate = ref(0);
    const productFor = ref("");
    const companyType = ref(null)
    const isNotValid = ref(false);
    const quoteCount = ref(0);
    const isKanban = ref(false);
    const supplierOptions = ref([]);
    const isTps = ref(false)
    const isDragging = ref(false); // Reactive state for drag status
    const files = ref([]); // Reactive state for file storage
    const fileInput = ref(null); // Ref for file input element
    const fileData = ref([])
    const supplier_id = ref()
    const contract_start_date = ref();
    const logs = ref([]);

const org_details = ref({});
const orgPersonsDetails = ref({});
const country = ref({});
const addDays = ref(["0", "1", "4", "5", "6", "7", "14", "21"]);
const addMonths = ref(["1", "2", "3", "4", "5", "6", "9", "12"]);

    const selectedOption = ref()
    let billingAddress;

    const piplelineType = ref(0);

    const timeValue = ref(dayjs('00:00', 'HH:mm'));

    if (route.query.kanban) {
      isKanban.value = true;
    }

    const apiErrors = ref([])
    const settingOptions = ref({})

    const showAllFiles = ref(false);

    const displayedFiles = computed(() => (showAllFiles.value ? fileData.value : fileData.value.slice(0, 4)));

    const toggleViewMore = () => {
      showAllFiles.value = !showAllFiles.value;
    };
    const pipelineLevels = computed(
      () => store.getters["opportunity/getPipelineLevels"]
    );
    const options = computed(
      () => store.getters["opportunity/getoppDropdowns"]
    );
    const opportunityData = computed(
      () => store.getters["opportunity/getoppViewData"]
    );
    const orgPersons = ref([]);
    const saleResponsibleOptions = computed(
      () => store.getters["organisation/getresUsersOptions"]
    );
    // const cmResponsibleOptions = computed(
    //   () => store.getters["opportunity/getCmResponsibles"]
    // );
    // Computed property to format the date
    const formattedNextUpdateLatest = computed(() => {
      const date = new Date(formData.value.next_update_latest_date);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    });

    const formattedData = computed(() => {
      const { forecast_commission } = formData.value;
      return {
        cancelled_commission: formatNumber(
          forecast_commission.cancelled_commission
        ),
        sc_corecast_or_amended_total_commission: formatNumber(
          forecast_commission.sc_corecast_or_amended_total_commission
        ),
        sc_forecast_or_amended_first_payment: formatNumber(
          forecast_commission.sc_forecast_or_amended_first_payment
        ),
        sc_forecast_or_amended_residual_payment: formatNumber(
          forecast_commission.sc_forecast_or_amended_residual_payment
        ),
        // u4b_uplift: formatNumber(forecast_commission.u4b_uplift),
        final_contract_consumption: formatNumber(
          forecast_commission.final_contract_consumption
        ),
        failed_credit: formatNumber(forecast_commission.failed_credit),
        final_contract_commission: formatNumber(
          forecast_commission.final_contract_commission
        ),
        actual_first_payment: formatNumber(
          forecast_commission.actual_first_payment
        ),
        actual_residual_payment: formatNumber(
          forecast_commission.actual_residual_payment
        ),
        forecast_first_payment_amount: formatNumber(
          forecast_commission.forecast_first_payment_amount
        ),
      };
    });
    // Watch for changes to formatted data and update the raw data
    const updateFormData = (key, value) => {
      formData.value.forecast_commission[key] = removeFormatting(value);
    };

    const schema = Yup.object().shape({
      pipeline_level: Yup.string().required("Pipeline level is required"),
      main_production_service: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Main Product Service is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      mpr: Yup.string().when("main_production_service", {
        is: "Gas",
        then: (schema) => schema.required("MPR No. is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      mpan_top: Yup.string().when("main_production_service", {
        is: "Elec",
        then: (schema) => schema.required("MPAN top is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      mpan_bottom: Yup.string().when("main_production_service", {
        is: "Elec",
        then: (schema) => schema.required("MPAN bottom is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

      supplier_id: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Current Supplier is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

      contract_start_date: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Contract Start Date is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

      building_name: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Building name is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      street: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Street is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      city: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("City is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      county: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Country is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      sales_contact: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Sales contact is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      postcode: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Postcode is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      sales_responsible: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Sales Responsible is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      sc_forecast_or_amended_annual_consumption: Yup.number()
        .when("pipeline_level", {
          is: (pipeline_level) => {
            // Find the corresponding value in pipelineLevels
            const matchedLevel = pipelineLevels.value.find(
              (item) => item.id === pipeline_level
            );
            // Check if the value is "Quoted" or "Contract - won"
            return matchedLevel && matchedLevel.form_validation === 1;
          },
          then: (schema) =>
            schema
              .required(" Amended Annual Consumption is required")
              .min(0.1, "Annual consumption must be greater then 0"), // Validate minimum value
          otherwise: (schema) => schema.notRequired(),
        }),

      u4b_uplift: Yup.string().when("pipeline_level", {
        is: (pipeline_level) => {
          // Find the corresponding value in pipelineLevels
          const matchedLevel = pipelineLevels.value.find(
            (item) => item.id === pipeline_level
          );
          return matchedLevel && matchedLevel.form_validation === 1;
        },
        then: (schema) => schema.required("Main Product Service is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    });
    const formData = ref({
      id: null,
      is_valid: 0,
      pipeline_level: null, //int id
      win_probability: null, //int id
      next_update_latest_date: null, //date
      next_update_latest_time: "00:00:00",
      date_of_sale: null, //date
      contract_end_date: null, //date
      mpr: null, //String input
      mpan_top: null, //String input
      mpan_bottom: null, //String input
      supplier_id: null, //int id
      spid: "", //string input
      description: "", //string input
      sales_contact: null, //int id
      sc_forecast_total_commission: null, //int input number
      // quoted_annual_consumption: null, //int input number
      // quotation: "", //string input
      meter_opportunity_nr_auto: null, //int input number
      main_production_service: null, //string input
      sales_responsible: null, //int id
      referred_by: null, //int id
      new_supplier_id: null, //int id
      contract_start_date: null, //date
      sale_type: "", //string input
      cot: "", //string
      cot_date: null, //date
      nic: "", //string
      // cm_responsible: "",
      // loa_status: "",
      // town: "",
      reason: "",
      tps: "",
      contracts: {
        contract_contact: null,
        contract_description: "",
        contract_responsible: null,
        // overall_risk: null,
        overall_status: null,
      },
      supply_address: {
        building_name: null, //string input
        street: null, //string input
        city: null, //string input
        county: null, //string input
        postcode: null, //string input
        comment: null, //string input
        content: null, //string input
      },
      billing_address: {
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        postcode: "",
      },
      forecast_commission: {
        sc_forecast_or_amended_annual_consumption: null,
        final_contract_consumption: 0.0,
        cancelled_commission: 0.0,
        failed_credit: 0.0,
        sc_corecast_or_amended_total_commission: 0.0,
        final_contract_commission: 0.0,
        sc_forecast_or_amended_first_payment: 0.0,
        actual_first_payment: 0.0,
        sc_forecast_or_amended_residual_payment: 0.0,
        actual_residual_payment: 0.0,
        u4b_uplift: 0,
        forecast_first_payment_amount: 0.0,
        kva: 0.0
      },
      billing_address_same_as: ""
    });

    const setTime = async () => {
      if (!formData.value.next_update_latest_date) {
        formData.value.next_update_latest_time = ``;
        timeValue.value = null;
      }
      await updateOpportunity()
    }

    const onPickerOpen = (open) => {
      if (open) {
        // Ensure only numbers are allowed in the input
        sanitizeInput();
      }
    };
    const sanitizeInput = () => {
      const inputEl = document.querySelector(".ant-picker-input input");
      if (inputEl) {
        inputEl.addEventListener("input", (event) => {
          // Allow only numbers and colons
          const sanitizedValue = event.target.value.replace(/[^0-9:]/g, "");
          event.target.value = sanitizedValue;
        });
      }
    };

    const selectOption = (option) => {
      // Toggle the option or uncheck if already selected
      selectedOption.value = selectedOption.value === option ? null : option;
      formData.value.billing_address_same_as = selectedOption.value;
      if (selectedOption.value === 'supply') {
        formData.value.billing_address.address_line_1 = formData.value.supply_address.building_name;
        formData.value.billing_address.address_line_2 = formData.value.supply_address.street;
        formData.value.billing_address.address_line_3 = formData.value.supply_address.city;
        formData.value.billing_address.address_line_4 = formData.value.supply_address.county;
        formData.value.billing_address.postcode = formData.value.supply_address.postcode;
      } else if (selectedOption.value === 'organisation') {
        formData.value.billing_address.address_line_1 = org_details.value.address;
        formData.value.billing_address.address_line_2 = org_details.value.city;
        formData.value.billing_address.address_line_3 = org_details.value.state;
        formData.value.billing_address.address_line_4 = org_details.value.address_line_4;
        formData.value.billing_address.postcode = org_details.value.postcode;
      }
      else {
        formData.value.billing_address.address_line_1 = billingAddress.address_line_1;
        formData.value.billing_address.address_line_2 = billingAddress.address_line_2;
        formData.value.billing_address.address_line_3 = billingAddress.address_line_3;
        formData.value.billing_address.address_line_4 = billingAddress.address_line_4;
        formData.value.billing_address.postcode = billingAddress.postcode;
      }
    }

    const handleChange = () => {
      formData.value.billing_address_same_as = "";
      selectedOption.value = ""
    }

    const fetchOppData = async (id) => {
      try {
        const data = await store.dispatch("opportunity/fetchOpportunity", id);
        logs.value = data.opportunities_logs;
        org_details.value = data.organization;
        companyType.value = data.organization.type;
        orgPersonsDetails.value = data.organization_person;
        billingAddress = { ...data.billing_address };
        formData.value.billing_address_same_as = data.billing_address_same_as;
        selectedOption.value = data.billing_address_same_as;
        // isValidate.value = data.is_valid;
        piplelineType.value = data.form_validation;
        productFor.value = data.main_production_service;
        country.value = data.organization.country;
        quoteCount.value = data.quotes_count;
        if (data.next_update_latest_time) {
          const [hours, minutes] = data.next_update_latest_time.split(":");
          formData.value.next_update_latest_time = `${hours}:${minutes}:00`
          const timeString = `${hours}:${minutes}`; // HH:mm format
          const dayjsObject = dayjs(timeString, "HH:mm");
          timeValue.value = dayjsObject
        }
        else {
          timeValue.value = null
        }
        Object.assign(formData.value, data);
        Object.assign(
          formData.value.forecast_commission,
          data.forecast_commission
        );
        Object.assign(formData.value.contracts, data.contracts);
        if (formData.value.mpan_top || formData.value.mpan_bottom) {
          formData.value.main_production_service = 'Elec'
        } else if (formData.value.mpr) {
          formData.value.main_production_service = 'Gas'
        }
        if (data.organization.hasOwnProperty("tps")) {
          isTps.value = true;
          formData.value.tps = data?.organization?.tps ? data?.organization?.tps : "N/A"
        } else {
          isTps.value = false;
        }
        if (data.probability_disabled === 1) {
          isEditable.value = false;
        } else {
          isEditable.value = true;
        }
        if (data.date_disabled === 1) {
          isDateEditable.value = false;
        } else {
          isDateEditable.value = true;
        }
        if (data.pipeline_type === "contract_won") {
          isShowWon.value = true;
        } else {
          isShowWon.value = false;
        }
        if (data.pipeline_type === "closed_meter_op") {
          isLost.value = true;
        } else {
          isLost.value = false;
        }

        if (data.next_update_latest_date) {
          formData.value.next_update_latest_date = new Date(
            data.next_update_latest_date
          );
        }
        if (data.date_of_sale) {
          formData.value.date_of_sale = new Date(data.date_of_sale);
        }
        if (data.contract_end_date) {
          formData.value.contract_end_date = new Date(data.contract_end_date);
        }
        if (data.cot_date) {
          formData.value.cot_date = new Date(data.cot_date);
        }
        if (data.contract_start_date) {
          formData.value.contract_start_date = new Date(
            data.contract_start_date
          );
        }
        contract_start_date.value = data.contract_start_date;
        supplier_id.value = data.supplier_id;
        formData.value.content = "";
        fileData.value = data.opportunities_docs;
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    const myChangeEvent = (event, role) => {
      if (event.id === 'null') {
        if (role === 'current') {
          supplier_id.value = null;
          formData.value.supplier_id = null;
        } else if (role === 'new') {
          formData.value.new_supplier_id = null;
        }
      }
      else {
        if (role === 'current') {
          supplier_id.value = formData.value.supplier_id;
        }
      }
    }

    const clearDate = () => {
      if (formData.value.contract_start_date) {
        contract_start_date.value = formData.value.contract_start_date;
      }
      else {
        contract_start_date.value = null;
      }
    }
    // Utility functions for formatting and removing formatting
    const formatNumber = (value) => {
      const number = parseFloat(value);
      if (!isNaN(number)) {
        return number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return "0.00";
    };

    const removeFormatting = (value) => {
      const numberValue = value.replace(/,/g, ""); // Remove commas
      return parseFloat(numberValue) || 0;
    };
    // Function to handle change
    const fetchWinProb = async () => {

      const selectedPipelineLevelId = formData.value.pipeline_level;
      const selectedOppId = formData.value.id;

      const data = await store.dispatch("opportunity/fetchWinProb", {
        pipelineID: selectedPipelineLevelId,
        selectedOppId: selectedOppId,
      });
      piplelineType.value = data?.data?.form_validation
      if (data?.data?.pipeline_type === "contract_won") {
        isShowWon.value = true;
      } else {
        isShowWon.value = false;
      }
      if (data?.data?.pipeline_type === "closed_meter_op") {
        isLost.value = true;
      } else {
        isLost.value = false;
      }
      if (data && data.data) {
        formData.value.win_probability = data.data.win_prob_percentage;
        formData.value.next_update_latest_date = new Date(
          data.data.next_update_latest_date
        );
        if (data.data.probability_disabled === 1) {
          isEditable.value = false;
        } else {
          isEditable.value = true;
        }
        if (data.data.date_disabled === 1) {
          isDateEditable.value = false;
        } else {
          isDateEditable.value = true;
        }
      }
    };
    // Function to add days or months
    const addToDate = (field, type, value) => {
      // Check if the field is valid
      if (["date_of_sale", "next_update_latest_date"].includes(field)) {
        const numericValue = parseInt(value); // Convert the string value to an integer

        // If value is 0, set the date to today's date
        if (numericValue === 0) {
          formData.value[field] = new Date(); // Set to today's date
        } else {
          let currentDate;

          // Ensure the base date is correctly determined
          if (field === "next_update_latest_date") {
            currentDate = new Date(); // Start with today's date
          } else {
            currentDate = formData.value[field] ? new Date(formData.value[field]) : new Date();
          }

          // Add days or months based on the type
          if (type === "days") {
            currentDate.setDate(currentDate.getDate() + numericValue); // Add days
          } else if (type === "months") {
            currentDate.setMonth(currentDate.getMonth() + numericValue); // Add months
          }

          // Update the corresponding field in formData
          formData.value[field] = new Date(currentDate);
        }
        if (field === "next_update_latest_date") {
          updateOpportunity(); // Call your custom function
        }
      }
    };

    const getQuote = async () => {
      if (
        formData.value.main_production_service === null || formData.value.main_production_service === '' ||
        formData.value.supplier_id === null || formData.value.supplier_id === '' ||
        formData.value.contract_start_date === null || formData.value.contract_start_date === '' ||
        formData.value.supply_address.building_name === null || formData.value.supply_address.building_name === '' ||
        formData.value.supply_address.street === null || formData.value.supply_address.street === '' ||
        formData.value.supply_address.city === null || formData.value.supply_address.city === '' ||
        formData.value.supply_address.county === null || formData.value.supply_address.county === '' ||
        formData.value.sales_contact === null || formData.value.sales_contact === '' ||
        formData.value.sales_responsible === null || formData.value.sales_responsible === '' ||
        formData.value.supply_address.postcode === null || formData.value.supply_address.postcode === '' ||
        formData.value.forecast_commission.sc_forecast_or_amended_annual_consumption === '0.00'
      ) {
        isNotValid.value = true;
        return;
      } else {
        if (formData.value.main_production_service === 'Gas' && (formData.value.mpr === "" || formData.value.mpr === null)) {
          isNotValid.value = true;
        }
        else if (formData.value.main_production_service === 'Elec' && (formData.value.mpan_top === "" || formData.value.mpan_top) === null && (formData.value.mpan_bottom === "" || formData.value.mpan_bottom === null)) {
          isNotValid.value = true;
        }
        else {
          isNotValid.value = false;
          await updateOpportunity();
          if (apiErrors.value.length === 0)
            router.push({
              path: "/quoting",
              query: { opp_Id: oppId.value,  ter_Id: terId.value,
                org_Id: org_id.value, for: productFor.value },
            });
        }

      }
    }
    const updateOpportunity = async () => {
      if (
        formData.value.pipeline_level &&
        formData.value.main_production_service &&
        (
          formData.value.mpr ||
          (
            formData.value.mpan_top &&
            formData.value.mpan_bottom
          )
        ) &&
        formData.value.supplier_id &&
        formData.value.contract_start_date &&
        formData.value.supply_address.building_name &&
        formData.value.supply_address.street &&
        formData.value.supply_address.city &&
        formData.value.supply_address.county &&
        formData.value.sales_contact &&
        formData.value.sales_responsible &&
        formData.value.supply_address.postcode &&
        formData.value.forecast_commission.sc_forecast_or_amended_annual_consumption
      ) {
        // isNotValid.value = true;
        formData.value.is_valid = 1;
      } else {
        formData.value.is_valid = 0;
      }
      // formData.value.is_valid = 1;
      productFor.value = formData.value.main_production_service;
      const next_update_latest = changeFormate(formData.value.next_update_latest_date)
      const date_of_sale = changeFormate(formData.value.date_of_sale)
      const contract_end_date = changeFormate(formData.value.contract_end_date)
      const contract_start_date = changeFormate(formData.value.contract_start_date)
      const cot_date = changeFormate(formData.value.cot_date)
      const sc_forecast_or_amended_annual_consumption = formData.value.forecast_commission.sc_forecast_or_amended_annual_consumption || '0.00'
      // const next_update_latest =  changeFormate(formData.value.next_update_latest_date)
      if (formData.value.main_production_service === 'Gas') {
        formData.value.mpan_top = null;
        formData.value.mpan_bottom = null;
      } else {
        formData.value.mpr = null;
      }
      const response = await store.dispatch(
        "opportunity/updateOpportunity",
        {
          ...formData.value,
          next_update_latest_date: next_update_latest,
          date_of_sale: date_of_sale,
          contract_end_date: contract_end_date,
          contract_start_date: contract_start_date,
          cot_date: cot_date,
          forecast_commission: { ...formData.value.forecast_commission, sc_forecast_or_amended_annual_consumption: sc_forecast_or_amended_annual_consumption }
        }
      );
      if (response.status) {
        fetchOppData(formData.value.id);
        // router.push("/crm/meter-opportunities-list");
        apiErrors.value = []
      } else {
        // router.push("/crm/meter-opportunities-kanban");
        apiErrors.value = response.errors
      }
      // }
    };
    const handleCancel = () => {
      const query = route.query.add;
      if (query === "org-details") {
        router.push({
          path: "/crm/organisation-details",
          query: {
            org_Id: route.query.org_Id,
            ter_Id: route.query.ter_Id,
            for: 'opportunities'
          },
        });
      } else if (query === "dashboard") {
        router.push("/dashboard");
      } else {
        if (!isKanban.value) {
          router.push("/crm/meter-opportunities-list");
        } else {
          router.push("/crm/meter-opportunities-kanban");
        }
      }
    };

    const onChange = async () => {
      if (fileInput.value && fileInput.value.files) {
        files.value = fileInput.value.files;
        const opp_Id = route.query.opp_Id;
        const response = await store.dispatch("opportunity/uploadFile", {
          type: 'opportunity',
          file: files.value[0],
          id: opp_Id
        });
        if (response.status) {
          await fetchOppData(opp_Id)
        }
      }
    };

    const dragover = (e) => {
      e.preventDefault();
      isDragging.value = true;
    };

    const dragleave = () => {
      isDragging.value = false;
    };

    const drop = (e) => {
      e.preventDefault();
      if (fileInput.value) {
        fileInput.value.files = e.dataTransfer.files;
        onChange();
      }
      isDragging.value = false;
    };

    const deleteFile = async (id) => {
      const response = await store.dispatch("opportunity/deleteFile", id)
      if (response.status) {
        const opp_Id = route.query.opp_Id;
        await fetchOppData(opp_Id)
      }
    }

    const viewFile = (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop();
      link.target = '_blank';
      link.click();
    };

    const fetchOptions = async () => {
      try {
        const response = await apiClient.get("settings/organisation-person");
        settingOptions.value = response.data.data.dropdowns;
      } catch (error) {
        console.error("Error fetching options data:", error);
      }
    };
    const downloadFile = async (file) => {

      try {
        window.open(file.file_path, "_blank");
      } catch (error) {
        console.error('Error downloading the file:', error);
      }

    };
    const formatDate = (date) => {
      if (date) {
        const [year, month, day] = date.split('T')[0].split('-');
        return `${day}-${month}-${year}`;
      }
    };

    const handleTimeChange = (value) => {
      // Format the Day.js object to a string (HH:mm)
      const formattedTime = value?.format('HH:mm');
      if (formattedTime) {
        formData.value.next_update_latest_time = `${formattedTime}:00`;
      } else {
        formData.value.next_update_latest_time = `00:00:00`;
      }

    };
   const fetchOrgPerson = async (id) => {
  try {
    const totalPersons = await store.dispatch('opportunity/fetchPersons', id);
    orgPersons.value = totalPersons;

    // Automatically select the first contact if only one is available
    // if (orgPersons.value.length === 1) {
    //   formData.value.sales_contact = orgPersons.value[0].id;
    // }
  } catch (error) {
    console.error('Error fetching persons:', error);
  }
};
    onMounted(async () => {
      const opp_Id = route.query.opp_Id;
      const organisation_id = route.query.org_Id;
      const territory_id = route.query.ter_Id;
      if (opp_Id) {
        await fetchOppData(opp_Id);
      }
      if (organisation_id) {
        //  const orgPerson = store.dispatch("opportunity/fetchPersons", organisation_id);
        fetchOrgPerson(organisation_id)
        // if (orgPersons.value.length === 1) {
        //   formData.value.sales_contact = orgPersons.value[0].id;
        // }
      }
      fetchOptions();
      store.dispatch("organisation/fetchTerritoryCompanyUsers", territory_id);
      // await store.dispatch("opportunity/fetchCMUsers", territory_id);
      store.dispatch("opportunity/fetchPipelines");
      const data = await store.dispatch('opportunity/fetchOpportunityOptions');
      supplierOptions.value = [
        { id: 'null', text: 'Select....', original: null },
        ...data?.supplier?.map(item => ({
          id: item.id,
          text: item.name,
          original: item,
        }))
      ];
      // await fetchDocFiles(opp_Id)
    });

    return {
      quoteCount,
      isNotValid,
      productFor,
      oppId,
      companyType,
      fetchOrgPerson,
      schema,
      formData,
      org_id,
      updateOpportunity,
      handleCancel,
      pipelineLevels,
      options,
      supplierOptions,
      orgPersons,
      opportunityData,
      org_details,
      country,
      saleResponsibleOptions,
      // cmResponsibleOptions,
      addDays,
      addMonths,
      addToDate,
      apiErrors,
      terId,
      logs,
      formattedNextUpdateLatest,
      fetchWinProb,
      isEditable,
      isKanban,
      formattedData,
      formatNumber,
      updateFormData,
      removeFormatting,
      isDateEditable,
      isShowWon,
      myChangeEvent,
      isLost,
      changeFormate,
      setTime,
      isDragging,
      files,
      fileInput,
      onChange,
      dragover,
      dragleave,
      drop,
      fileData,
      deleteFile,
      viewFile,
      downloadFile,
      formatDate,
      showAllFiles,
      orgPersonsDetails,
      displayedFiles,
      toggleViewMore,
      supplier_id,
      contract_start_date,
      clearDate,
      getQuote,
      isTps,
      settingOptions,
      fetchOptions,
      timeValue,
      handleTimeChange,
      onPickerOpen,
      piplelineType,
      selectedOption,
      selectOption,
      handleChange
    };
  },
};
</script>

<style lang="scss" scoped>
.contract-won-row {
  background-color: #5cb85c21;
}

.contract-lost-row {
  background-color: #ff00001a;
}

.col-form-label {
  font-size: 13px;
}

.website {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #00918e;
  color: #fff;
}

.add-cursor {
  cursor: pointer;
  font-weight: 400;
}

.disabled {
  background-color: #f6f6f6;
}

.dropzone-container {
  text-align: center;
  padding: 1rem;
  margin: 1rem 5px;
  background: #f7fafc;
  border: 2px dotted#e2e8f0;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 16px;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 2px solid #a2a2a2;
  padding: 6px;
  margin-left: 6px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}

.table {
  border-color: rgb(225, 225, 225);
  word-wrap: break-word;
}

.table th,
.table td {
  padding: 0;
  padding-bottom: 5px;
  text-align: center;
  word-wrap: break-word;
}

.icon-size {
  font-size: 1.5rem;
  cursor: pointer;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 0;
  -webkit-overflow-scrolling: touch;
}

.truncate-doc-name {
  width: 200px;
}

.logs_Section {
  max-height: 320px;
  overflow-x: hidden;
}

.icon-form .ant-space .ant-space-item .ant-picker {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-color: #E8E8E8;
  color: #6F6F6F;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  min-height: 42px;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panel .ant-picker-content .ant-picker-time-panel-column::after {
  display: block;
  height: 0px !important;
  content: "";
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panel .ant-picker-footer .ant-picker-ranges .ant-picker-now {
  display: none !important;
}
</style>
