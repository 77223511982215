<template>
  <ul>
    <li v-for="item in side_bar_data" :key="item.tittle">
      <h6 class="submenu-hdr">
        <span v-if="user.roles?.[0] !== 'super_admin' && item.tittle === 'CRM'">{{
          item.tittle
        }}</span>
        <span v-if="user.roles?.[0] === 'super_admin' && item.tittle !== 'CRM'">{{
          item.tittle
        }}</span>
      </h6>
      <ul>
        <template v-for="menu in item.menu" :key="menu.menuValue">
          <li v-if="!menu.hasSubRoute && user?.permissions?.includes(menu.menuValue)" :class="{ active: isMenuActive(menu) }">
            <!-- <router-link
              v-if="
                menu.route &&
                menu.route === '/crm/leads-list' &&
                user.roles?.[0] === 'company_admin'
              "
              :to="menu.route"
              @click="expandSubMenus(menu)"
              ><i :class="'ti-' + menu.icon" v-if="menu.icon === 'bar-chart'"></i
              ><i class="ti" :class="'ti-' + menu.icon" v-else></i>
              <span>{{ menu.menuValue }}</span></router-link
            > -->

            <router-link
              :to="menu.route"
              @click="expandSubMenus(menu)"
              :class="{
                active:
                  currentPath === menu.active_link ||
                  currentPath === menu.active_link1 ||
                  currentPath === menu.active_link2 ||
                  currentPath === menu.active_link3 ||
                  currentPath === menu.active_link4,
              }"
            >
              <i :class="'ti-' + menu.icon" v-if="menu.icon === 'bar-chart'"></i>
              <i class="ti" :class="'ti-' + menu.icon" v-else></i>
              <span>{{ menu.menuValue }}</span>
            </router-link>
          </li>
          <li v-if="!menu.hasSubRoute &&  menu.route &&
                menu.route === '/companies' &&
                user.roles?.[0] === 'super_admin'" :class="{ active: isMenuActive(menu) }">
             <router-link
              :to="menu.route"
              @click="expandSubMenus(menu)"
              ><i :class="'ti-' + menu.icon" v-if="menu.icon === 'bar-chart'"></i
              ><i class="ti" :class="'ti-' + menu.icon" v-else></i>
              <span>{{ menu.menuValue }}</span></router-link
            >
          </li>
        </template>
      </ul>
    </li>
  </ul>
</template>

<script>
import side_bar_data from "@/assets/json/sidebar-data.json";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      side_bar_data: side_bar_data,
      openMenuItem: null,
      showSubRoute: null,
      route_array: [],
      multilevel: [false, false, false],
    };
  },
  methods: {
    ...mapActions("auth", ["loadUserFromLocalStorage"]),
    expandSubMenus(menu) {
      this.side_bar_data.forEach((item) => {
        if (item.menu && Array.isArray(item.menu)) {
          item.menu.forEach((subMenu) => {
            if (subMenu !== menu) {
              subMenu.showSubRoute = false;
              subMenu.showSubRoute1 = false;
            }
          });
        }
      });
      menu.showSubRoute = !menu.showSubRoute;
    },
    openMenu(menu) {
      this.openMenuItem = this.openMenuItem === menu ? null : menu;
    },
    openSubmenuOne(subMenus) {
      this.showSubRoute = this.showSubRoute === subMenus ? null : subMenus;
    },
    getCurrentPath() {
      this.route_array = this.$route.path.split("/");
      return this.$route.path;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    currentPath() {
      return this.getCurrentPath();
    },
    isMenuActive() {
      return (menu) => {
        if (menu.menuValue === "Application") {
          return (
            this.$route.path.startsWith("/application") || // Check if current route starts with '/application'
            this.$route.path.startsWith("/call") ||
            this.$route.path === menu.active_link ||
            this.$route.path === menu.active_link1 ||
            this.$route.path === menu.active_link2
          );
        } else {
          return (
            this.$route.path === menu.route ||
            this.$route.path === menu.active_link ||
            this.$route.path === menu.active_link1 ||
            this.$route.path === menu.active_link2 ||
            this.$route.path === menu.active_link3 ||
            this.$route.path === menu.active_link4 ||
            this.$route.path === menu.active_link5 ||
            this.$route.path === menu.active_link6 ||
            this.$route.path === menu.active_link7 ||
            this.$route.path === menu.active_link8 ||
            this.$route.path === menu.active_link9 ||
            this.$route.path === menu.active_link10 ||
            this.$route.path === menu.active_link11
          );
        }
      };
    },
  },
};
</script>
