<template>
  <index-loader></index-loader>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <dashboard-header :title="title" />
          <div class="row">
            <div class="col-md-6 d-flex">
              <div class="card flex-fill">
                <div class="card-body p-0">
                  <div class="statistic-header p-3 mb-0 border-bottom">
                    <div class="col-md-4 px-2">
                      <div class="form-wrap icon-form mb-0">
                        <span class="form-icon">
                          <i class="ti ti-search"></i>
                        </span>
                        <input type="text" class="form-control" placeholder="Search" v-model="searchQuery" />
                      </div>
                    </div>
                    <div class="col-md-4 px-2">
                      <select class="form-select" v-model="selectedOption">
                        <option v-for="option in searchOptions" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 px-2">
                      <button type="submit" @click="onSearch" class="btn btn-primary p-2">
                        <i class="ti ti-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 d-flex">
              <div class="card flex-fill">
                <div class="card-body p-0">
                  <div class="statistic-header p-3 mb-3 border-bottom">
                    <div class="col-6">
                      <h4 class="mb-0">
                        <i class="ion-search me-2"></i> Last search result
                      </h4>
                    </div>
                    <div class="col-6 text-end">
                    </div>
                  </div>
                  <!-- :onScroll="onScroll" -->
                  <deals-dashboard-table :onScroll="onScroll" :searchResults="searchResults"></deals-dashboard-table>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="card flex-fill">
                <div class="card-body p-0 pb-3">
                  <div class="statistic-header p-3 border-bottom mb-3">
                    <div class="col-6">
                      <h4 class="mb-0">
                        <!-- <router-link to="/crm/meter-opportunities-list"><i class="ti ti-chart-arcs me-1"></i> -->
                          Pipeline
                        <!-- </router-link> -->
                      </h4>
                    </div>
                    <div class="col-6 text-end">
                    </div>
                  </div>
                  <deals-dashboard-table :pipeLineData="pipelineOppData" :overDuelength="total_opp"></deals-dashboard-table>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex" v-if="user?.permissions?.includes('Meter Opportunities')">
              <div class="card flex-fill">
                <div class="card-body p-0 pb-3">
                  <div class="statistic-header p-3 border-bottom mb-3">
                    <div class="col-6">
                      <h4 class="mb-0">
                        <router-link to="/crm/meter-opportunities-list"><i class="ti ti-chart-arcs me-1"></i>
                          Overdue Meter Opportunities
                        </router-link>
                      </h4>
                    </div>
                    <div class="col-6 text-end">
                    </div>
                  </div>
                  <deals-dashboard-table :onScroll="onScroll" :overDueOpportunities="overDueOpportunities"></deals-dashboard-table>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex" v-if="user?.permissions?.includes('Organisations')">
              <div class="card flex-fill">
                <div class="card-body p-0">
                  <div class="statistic-header p-3 mb-0 border-bottom">
                    <div class="col-6">
                      <h4 class="mb-0">
                        <router-link to="/crm/organisation-list">
                          <i class="ti ti-building-community me-1"></i> Organisations
                        </router-link>
                      </h4>
                    </div>
                    <div class="col-6 text-end">
                      <router-link :to="{ path: '/crm/create-organisation', query: { add: 'dashboard' } }"
                        class="btn btn-primary p-1">
                        <i class="ti ti-plus"></i>
                      </router-link>
                    </div>
                  </div>
                  <deals-dashboard-table :onScroll="onScroll" :organisations="organisations"></deals-dashboard-table>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex" v-if="user?.permissions?.includes('Reports')">
              <div class="card flex-fill">
                <div class="card-body p-0">
                  <div class="statistic-header p-3 mb-0 border-bottom">
                    <div class="col-6">
                      <h4 class="mb-0">
                        <router-link to="/crm/reports-list">
                          <i class="ti-bar-chart me-1"></i> Reports & Charts
                        </router-link>
                      </h4>
                    </div>
                    <div class="col-6 text-end">
                      <!-- <router-link class="btn btn-primary p-1" to="/crm/create-organisation">
                      <i class="ti ti-plus"></i>
                    </router-link> -->
                    </div>
                  </div>
                  <deals-dashboard-table :onScroll="onScroll" :userReports="userReports"></deals-dashboard-table>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex"
              v-if="user?.permissions?.includes('Activities')">
              <div class="card flex-fill">
                <div class="card-body p-0 pb-3">
                  <div class="statistic-header p-3 mb-3 border-bottom">
                    <div class="col-6">
                      <h4 class="mb-0">
                        <router-link to="/crm/organisation-list">
                          <i class="ti ti-bounce-right me-1"></i> Activities
                        </router-link>
                      </h4>
                    </div>
                    <div class="col-6 text-end">
                      <router-link class="btn btn-primary p-1" to="/crm/create-activity">
                        <i class="ti ti-plus"></i>
                      </router-link>
                    </div>
                  </div>
                  <deals-dashboard-table :onScroll="onScroll" :userActivities="userActivities"></deals-dashboard-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import {
  ref
} from "vue";
import {
  onMounted,
  computed
} from "vue";
import {
  useStore, mapGetters
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import { message } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Dashboard",
    };
  },
  computed: {
    ...mapGetters('auth', ['user']), // 'auth' is the namespace of your Vuex module

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const searchOptions = ref([{
      value: 'organization',
      text: 'Organisation'
    }, {
      value: 'person',
      text: 'Person'
    }, {
      value: 'telephone',
      text: 'Telephone'
    }, {
      value: 'mpr',
      text: 'MPR NO.'
    },
    {
      value: 'mpan_top',
      text: 'MPAN Top'
    },
    {
      value: 'mpan_bottom',
      text: 'MPAN Bottom'
    },
    {
      value: 'postcode',
      text: 'Postcode'
    }]);

    const organisations = ref([]);
    const overDueOpportunities = ref([]);
    const searchResults = ref([]);
    const userActivities = ref([]);
    const userReports = ref([]);
    const pipelineOppData = ref({});

    const total = ref(false);
    const current_page = ref(1);
    const current_opp_page = ref(1);
    const total_opp = ref();
    const current_report_page = ref(1);
    const current_search_page = ref(1);
    const current_act_page = ref(1);
    const total_report = ref();
    const total_act = ref();
    const total_search = ref(1);
    const isFetching = ref(false);

    const searchQuery = ref('');
    const selectedOption = ref(searchOptions.value[0].value);

    const onSearch = async () => {
      isFetching.value = true
      searchResults.value = []
      current_search_page.value = 1;
      total_search.value = 1;
      if (searchQuery.value === '') {
        message.error('Please enter a text to search for');
        return;
      }
      fetchData(searchQuery.value, selectedOption.value)
    }

    const fetchData = async (searchQuery, selectedOption) => {
      try {
        let resData;
        if (current_search_page.value === 1) {
         const resData = await store.dispatch('dashboard/getDashboardData', {
            search: searchQuery,
            type: selectedOption,
            page: current_search_page.value,
            pageSize: 10
          })
          searchResults.value = resData.data;
          current_search_page.value = resData.data.current_page + 1;
          total_search.value = resData.data.total;
          return;
        }
        let count = searchResults.value.data.length;
        if (count >= 10 && total_search.value > count) {
          if (isFetching.value) return;
          isFetching.value = true;
          current_search_page.value = Math.ceil(count / 10) + 1;
          const params = {
            search: searchQuery,
            type: selectedOption,
            page: current_search_page.value,
            pageSize: 10
          };
          resData = await store.dispatch("dashboard/getDashboardData", params);
          const key = 'search'
          appendDynamicData(searchResults.value, resData.data.data, key);
          current_search_page.value = resData.data.current_page + 1;
          return;
        }

      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
      finally {
        isFetching.value = false;
      }
    };
    const appendDynamicData = (existingData, dynamicData, key) => {
      const combinedOutput = existingData.data.slice(); // Clone the existing output array
      dynamicData.forEach((item) => {
        combinedOutput.push(item);
      });
      if(key === 'opp'){
        overDueOpportunities.value.data = combinedOutput;
      } else if(key==='report') {
        userReports.value.data = combinedOutput;
      } else if (key === 'search'){
        searchResults.value.data = combinedOutput;
      } else if (key === 'activity'){
        userActivities.value.data = combinedOutput;
      } else {
        organisations.value.data = combinedOutput;
      }
      return { data: combinedOutput };
    };

    const fetchOrgData = async () => {
      try {
        let resData;
        if (current_page.value === 1) {
          resData = await store.dispatch('dashboard/getOrgData', {
            page: current_page.value,
            pageSize: 15
          })
          organisations.value = resData.data.organizations;
          current_page.value = resData.data.organizations.current_page + 1;
          total.value = resData.data.organizations.total;
          return;
        }
        let count = organisations.value.data.length;
        if (count >= 15 && total.value > count) {
          if (isFetching.value) return;
          isFetching.value = true;
          current_page.value = Math.ceil(count / 15) + 1;
          const params = {
            page: current_page.value,
            pageSize: 15
          };
          resData = await store.dispatch("dashboard/getOrgData", params);
          appendDynamicData(organisations.value, resData.data.organizations.data);
          current_page.value = resData.data.current_page + 1;
          return;
        }

      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
      finally {
        isFetching.value = false;
      }
    };

    const fetchOppData = async () => {
      try {
        let resData;
        if (current_opp_page.value === 1) {
          resData = await store.dispatch('dashboard/getOverDueOpp', {
            page: current_opp_page.value,
            pageSize: 8
          })
          overDueOpportunities.value = resData.data.overdue_opportunities;
          current_opp_page.value = resData.data.overdue_opportunities.current_page + 1;
          total_opp.value = resData.data.overdue_opportunities.total;
          return;
        }
        let count = overDueOpportunities.value.data.length;
        if (count >= 8 && total_opp.value > count) {
          if (isFetching.value) return;
          isFetching.value = true;
          current_opp_page.value = Math.ceil(count / 8) + 1;
          const params = {
            page: current_opp_page.value,
            pageSize: 8
          };
          resData = await store.dispatch("dashboard/getOverDueOpp", params);
          const key = 'opp'
          appendDynamicData(overDueOpportunities.value, resData.data.overdue_opportunities.data, key);
          current_opp_page.value = resData.data.current_page + 1;
          return;
        }

      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
      finally {
        isFetching.value = false;
      }
    };
    const fetchActivities = async () => {
      try {
        let resData;
        if (current_act_page.value === 1) {
          resData = await store.dispatch('dashboard/getActivities', {
            page: current_act_page.value,
            pageSize: 8
          })
          userActivities.value = resData.data.activities;
          current_act_page.value = resData.data.activities.current_page + 1;
          total_act.value = resData.data.activities.total;
          return;
        }
        let count = userActivities.value.data.length;
        if (count >= 8 && total_act.value > count) {
          if (isFetching.value) return;
          isFetching.value = true;
          current_act_page.value = Math.ceil(count / 8) + 1;
          const params = {
            page: current_act_page.value,
            pageSize: 8
          };
          resData = await store.dispatch("dashboard/getActivities", params);
          const key = 'activity'
          appendDynamicData(userActivities.value, resData.data.activities.data, key);
          current_act_page.value = resData.data.current_page + 1;
          return;
        }

      } catch (error) {
        console.error("Error fetching activities data:", error);
      }
      finally {
        isFetching.value = false;
      }
    };
    const fetchReportData = async () => {

      try {
        let resData;
        if (current_report_page.value === 1) {
          resData = await store.dispatch('dashboard/getReportsData', {
            page: current_report_page.value,
            pageSize: 10
          })
          userReports.value = resData.data.reports;
          current_report_page.value = resData.data.reports.current_page + 1;
          total_report.value = resData.data.reports.total;
          return;
        }
        let count = userReports.value.data.length;
        if (count >= 10 && total_report.value > count) {
          if (isFetching.value) return;
          isFetching.value = true;
          current_report_page.value = Math.ceil(count / 10) + 1;
          const params = {
            page: current_report_page.value,
            pageSize: 10
          };
          resData = await store.dispatch("dashboard/getReportsData", params);
          const key = 'report'
          appendDynamicData(userReports.value, resData.data.reports.data, key);
          current_report_page.value = resData.data.current_page + 1;
          return;
        }

      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
      finally {
        isFetching.value = false;
      }

    };

    const onScroll = (event) => {
      const element = event.target;
      if (element.scrollTop + element.clientHeight >= element.scrollHeight - 3 && !isFetching.value) {
        if(element.id === 'overDueOpportunities'){
          fetchOppData();
        } else if(element.id === 'orgSection'){
          fetchOrgData();
        } else if(element.id === 'userReports'){
          fetchReportData();
        } else if(element.id === 'searchSection') {
          fetchData(searchQuery.value, selectedOption.value);
        } else if(element.id === 'userActivities') {
          fetchActivities();
        }
      }
    };
    const fetchPiplineOppCount = async () =>{
      const resData = await store.dispatch('dashboard/getOppCount');
      pipelineOppData.value = resData;
    }
    onMounted(async () => {
      await fetchActivities();
      await fetchOrgData();
      await fetchOppData();
      await fetchReportData();
      await fetchPiplineOppCount();
    });

    return {
      userReports,
      onSearch,
      userActivities,
      searchQuery,
      current_act_page,
      total_act,
      selectedOption,
      organisations,
      overDueOpportunities,
      searchResults,
      current_search_page,
      fetchActivities,
      total_search,
      searchOptions,
      fetchData,
      fetchOrgData,
      pipelineOppData,
      fetchOppData,
      fetchReportData,
      onScroll,
      total,
      current_page,
      appendDynamicData,
      isFetching,
      current_opp_page,
      total_opp,
      current_report_page,
      total_report
    };
  },
};
</script>
