<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <!-- Page Header -->
                    <page-header :title="title" />
                    <!-- /Page Header -->

                    <!-- Settings Menu -->
                    <settings-tabs></settings-tabs>
                    <!-- /Settings Menu -->

                    <div class="row">
                        <general-sidebar></general-sidebar>

                        <div class="col-xl-9 col-lg-12">
                            <!-- Settings Info -->
                            <div class="card">
                                <div class="card-body">
                                    <!-- <h4 class="mb-3">Credentials Information</h4> -->
                                    <div class="settings-form">
                                        <Form @submit="saveProfile" :validation-schema="schema" v-slot="{ errors }">
                                            <div class="profile-details">
                                                <div class="row">
                                                    <h5 class="mb-3">Credit Safe Credentials</h5>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">
                                                                User Name
                                                            </label>
                                                            <Field name="credit_safe_credentials.username" as="input"
                                                                type="text" class="form-control"
                                                                v-model="form.credit_safe_credentials.username" />
                                                            <ErrorMessage
                                                                v-if="form.credit_safe_credentials.username === ''"
                                                                name="credit_safe_credentials" class="text-danger" />
                                                            <!-- <span class="text-danger">{{ errors["credit_safe_credentials"] }}</span> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Password</label>
                                                            <Field name="credit_safe_credentials.password" as="input"
                                                                type="password" class="form-control"
                                                                v-model="form.credit_safe_credentials.password" />
                                                            <ErrorMessage
                                                                v-if="form.credit_safe_credentials.password === ''"
                                                                name="credit_safe_credentials" class="text-danger" />
                                                            <!-- <span class="text-danger">{{ errors["credit_safe_credentials"] }}</span> -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <h5 class="mb-3">Power Price Credentials</h5>
                                                    <!-- <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">License Code</label>
                                                            <Field name="power_license_code" as="input" type="text"
                                                                class="form-control"
                                                                v-model="form.power_price_credentials.license_code" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Password</label>
                                                            <Field name="power_password" as="input" type="text"
                                                                class="form-control"
                                                                v-model="form.power_price_credentials.password" />
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Power Licence Code</label>
                                                            <Field name="power_price_credentials.power_licence_code"
                                                                as="input" type="text" class="form-control"
                                                                v-model="form.power_price_credentials.power_licence_code" />
                                                            <ErrorMessage
                                                                v-if="form.power_price_credentials.power_licence_code === ''"
                                                                name="power_price_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Contract PDF Licence
                                                                Code</label>
                                                            <Field
                                                                name="power_price_credentials.power_contract_pdf_licence_code"
                                                                as="input" type="text" class="form-control"
                                                                v-model="form.power_price_credentials.power_contract_pdf_licence_code" />
                                                            <ErrorMessage
                                                                v-if="form.power_price_credentials.power_contract_pdf_licence_code === ''"
                                                                name="power_price_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Mascarade User</label>
                                                            <Field
                                                                name="power_price_credentials.power_contract_pdf_mascarade_user"
                                                                as="input" type="text" class="form-control"
                                                                v-model="form.power_price_credentials.power_contract_pdf_mascarade_user" />
                                                            <ErrorMessage
                                                                v-if="form.power_price_credentials.power_contract_pdf_mascarade_user === ''"
                                                                name="power_price_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Contract PDF Source</label>
                                                            <Field
                                                                name="power_price_credentials.power_contract_pdf_source"
                                                                as="input" type="text" class="form-control"
                                                                v-model="form.power_price_credentials.power_contract_pdf_source" />
                                                            <ErrorMessage
                                                                v-if="form.power_price_credentials.power_contract_pdf_source === ''"
                                                                name="power_price_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <h5 class="mb-3">Docusign Credentials</h5>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Integration Key</label>
                                                            <Field name="docusign_credentials.integration_key"
                                                                as="input" type="text" class="form-control"
                                                                v-model="form.docusign_credentials.integration_key" />
                                                            <ErrorMessage
                                                                v-if="form.docusign_credentials.integration_key === ''"
                                                                name="docusign_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Secret Key</label>
                                                            <Field name="docusign_secret_key" as="input" type="text"
                                                                class="form-control"
                                                                v-model="form.docusign_credentials.secret_key" />
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Account ID</label>
                                                            <Field name="docusign_credentials.account_id" as="input"
                                                                type="text" class="form-control"
                                                                v-model="form.docusign_credentials.account_id" />
                                                            <ErrorMessage
                                                                v-if="form.docusign_credentials.account_id === ''"
                                                                name="docusign_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Impersonated User ID</label>
                                                            <Field name="docusign_credentials.impersonated_user_id"
                                                                as="input" type="text" class="form-control"
                                                                v-model="form.docusign_credentials.impersonated_user_id" />
                                                            <ErrorMessage
                                                                v-if="form.docusign_credentials.impersonated_user_id === ''"
                                                                name="docusign_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Auth Server</label>
                                                            <Field name="docusign_auth_server" as="input" type="text"
                                                                class="form-control"
                                                                v-model="form.docusign_credentials.auth_server" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">JWT Scope</label>
                                                            <Field name="docusign_jwt_scope" as="input" type="text"
                                                                class="form-control"
                                                                v-model="form.docusign_credentials.jwt_scope" />
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">User Name</label>
                                                            <Field name="docusign_credentials.username" as="input"
                                                                type="text" class="form-control"
                                                                v-model="form.docusign_credentials.username" />
                                                            <ErrorMessage
                                                                v-if="form.docusign_credentials.username === ''"
                                                                name="docusign_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Password</label>
                                                            <Field name="docusign_credentials.password" as="input"
                                                                type="text" class="form-control"
                                                                v-model="form.docusign_credentials.password" />
                                                            <ErrorMessage
                                                                v-if="form.docusign_credentials.password === ''"
                                                                name="docusign_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <h5 class="mb-3">Lookup Credentials</h5>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">User Name</label>
                                                            <Field name="lookup_credentials.username" as="input"
                                                                type="text" class="form-control"
                                                                v-model="form.lookup_credentials.username" />
                                                            <ErrorMessage v-if="form.lookup_credentials.username === ''"
                                                                name="lookup_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-wrap">
                                                            <label class="col-form-label">Password</label>
                                                            <Field name="lookup_credentials.password" as="input"
                                                                type="password" class="form-control"
                                                                v-model="form.lookup_credentials.password" />
                                                            <ErrorMessage v-if="form.lookup_credentials.password === ''"
                                                                name="lookup_credentials" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="submit-button text-end">
                                                <!-- <a href="javascript:;" class="btn btn-light">Cancel</a> -->
                                                <button type="submit" class="btn btn-primary">
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <!-- /Settings Info -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";

import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            title: "Settings",
        };
    },
    setup() {
        const store = useStore();
        const apiErrors = ref(null);
        const form = ref({
            credit_safe_credentials: {
                username: "",
                password: ""
            },
            power_price_credentials: {
                // license_code: "",
                // password: "",
                power_licence_code: "",
                power_contract_pdf_licence_code: "",
                power_contract_pdf_mascarade_user: "",
                power_contract_pdf_source: ""
            },
            docusign_credentials: {
                integration_key: "",
                // secret_key: "",
                account_id: "",
                impersonated_user_id: "",
                // auth_server: "",
                // jwt_scope: "",
                username: "",
                password: ""
            },
            lookup_credentials: {
                username: "",
                password: ""
            }
        });

        const schema = Yup.object().shape({
            credit_safe_credentials: Yup.object()
                .test(
                    "username-password-validation",
                    "Username and Password are both required if one is provided.",
                    function (value) {
                        const { username, password } = value || {};
                        if ((username && !password) || (!username && password)) {
                            return false; // Either both fields are filled, or none
                        }
                        return true;
                    }
                ),

            power_price_credentials: Yup.object().test(
                "fields-required",
                "All fields in Power price credentials must be filled or none.",
                function (value) {
                    const { power_licence_code, power_contract_pdf_licence_code, power_contract_pdf_mascarade_user, power_contract_pdf_source } = value || {};
                    const filledFields = [power_licence_code, power_contract_pdf_licence_code, power_contract_pdf_mascarade_user, power_contract_pdf_source].filter(Boolean);
                    return filledFields.length === 0 || filledFields.length === 4; // Either all or none should be filled
                }
            ),

            docusign_credentials: Yup.object().test(
                "fields-required",
                "All fields in Docusign credentials must be filled or none.",
                function (value) {
                    const { integration_key, account_id, impersonated_user_id, username, password } = value || {};
                    const filledFields = [integration_key, account_id, impersonated_user_id, username, password].filter(Boolean);
                    return filledFields.length === 0 || filledFields.length === 5; // Either all or none should be filled
                }
            ),

            lookup_credentials: Yup.object()
                .test(
                    "username-password-validation",
                    "Username and Password are both required if one is provided.",
                    function (value) {
                        const { username, password } = value || {};
                        if ((username && !password) || (!username && password)) {
                            return false; // Either both fields are filled, or none
                        }
                        return true;
                    }
                ),
        });




        const saveProfile = async () => {
            try {
                await store.dispatch("auth/updateCredentials", form.value);
                // fetchData();
            } catch (error) {
                apiErrors.value = error.response.data.errors;
            }
        };

        // const fetchData = async () => {
        //     const data = await store.dispatch("auth/fetchCredentials");
        //     // Object.assign(form.value, JSON.parse(data));
        //     if (data && data.credit_safe_credentials) {
        //         const formattedData = JSON.parse(data.credit_safe_credentials)
        //         Object.assign(form.value.credit_safe_credentials, formattedData);
        //     }

        //     if (data && data.power_price_credentials) {
        //         const formattedData = JSON.parse(data.power_price_credentials)
        //         Object.assign(form.value.power_price_credentials, formattedData);
        //     }

        //     if (data && data.docusign_credentials) {
        //         const formattedData = JSON.parse(data.docusign_credentials)
        //         Object.assign(form.value.docusign_credentials, formattedData);
        //     }

        //     if (data && data.lookup_credentials) {
        //         const formattedData = JSON.parse(data.lookup_credentials)
        //         Object.assign(form.value.lookup_credentials, formattedData);
        //     }
        // };


        const fetchData = async () => {
            const data = await store.dispatch("auth/fetchCredentials");

            if (data) {
                // Process credit_safe_credentials
                if (data.credit_safe_credentials) {
                    const formattedData =
                        typeof data.credit_safe_credentials === "string"
                            ? JSON.parse(data.credit_safe_credentials)
                            : data.credit_safe_credentials;
                    Object.assign(form.value.credit_safe_credentials, formattedData);
                }

                // Process power_price_credentials
                if (data.power_price_credentials) {
                    const formattedData =
                        typeof data.power_price_credentials === "string"
                            ? JSON.parse(data.power_price_credentials)
                            : data.power_price_credentials;
                    Object.assign(form.value.power_price_credentials, formattedData);
                }

                // Process docusign_credentials
                if (data.docusign_credentials) {
                    const formattedData =
                        typeof data.docusign_credentials === "string"
                            ? JSON.parse(data.docusign_credentials)
                            : data.docusign_credentials;
                    Object.assign(form.value.docusign_credentials, formattedData);
                }

                // Process lookup_credentials
                if (data.lookup_credentials) {
                    const formattedData =
                        typeof data.lookup_credentials === "string"
                            ? JSON.parse(data.lookup_credentials)
                            : data.lookup_credentials;
                    Object.assign(form.value.lookup_credentials, formattedData);
                }
            }
        };


        onMounted(async () => {
            fetchData();
        });

        return {
            fetchData,
            form,
            saveProfile,
            apiErrors,
            schema
        };
    },
};
</script>