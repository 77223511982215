<template>
  <div>
    <div class="view-header">
      <h4>{{ orgPersons.length }} Persons</h4>
      <ul>
        <li class="mb-0"
          v-if="(orgType === 'Partnership') || (orgType !== 'Partnership' && orgPersons.length === 0) || (orgPersons.length !== 0 && orgType === 'Partnership')">
          <router-link class="btn btn-primary p-1" title="Add Person" :to="{
            path: '/crm/create-organisation',
            query: { org_Id: orgId, ter_Id: ter_Id, add: 'org-details-person' },
          }"><i class="ti ti-plus"></i></router-link>
        </li>
      </ul>
    </div>

    <div class="notes-activity row" v-if="orgPersons.length > 0">
      <div class="col-md-4 mb-4" v-for="item in orgPersons" :key="item.id">
        <div class="calls-box">
          <div class="caller-info">
            <div class="calls-user">
              <div class="opp-icon me-2">
                <i class="ion-person"></i>
              </div>
              <div>
                <p title="Person name">
                  <router-link class="text-teal org-title" :to="{
                    path: '/crm/create-organisation',
                    query: { org_Id: orgId, ter_Id: ter_Id, add: 'org-details-person' },
                  }">
                    {{ item.first_name + ' ' + (item.last_name || '') }}
                  </router-link>
                </p>
                <h6 title="Direct telephone">{{ item.direct_telephone }}</h6>
                <p title="Mobile telephone">{{ item.mobile_telephone }}</p>
              </div>
            </div>
            <div class="calls-action">
              <router-link class="text-teal" :to="{
                path: '/crm/create-organisation',
                query: { org_Id: orgId, ter_Id: ter_Id, add: 'org-details-person' },
              }">
                <i class="ti ti-edit text-blue"></i>
              </router-link>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p><b>Job Title : </b> {{ item.job_title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row notes-activity" v-else>
      <div class="col-12 bg-light">
        <p class="text-center "><i class="ion-information-circled"></i> No data found</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import apiClient from '@/axios';

export default {
  props: {
    terId: {
      type: String,
    },
    orgType: {
      type: String,
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const orgId = ref(null);
    const ter_Id = ref(route.query.ter_Id || null);
    const orgPersons = ref([]);
    const isPersonsTabActive = ref(false); // Flag to track tab visibility

    const fetchPersons = async (id) => {
      if (!isPersonsTabActive.value) {
        try {
          const response = await apiClient.get(`/organisation/${id}/persons`);
          orgPersons.value = response.data.data;

          isPersonsTabActive.value = true;
        } catch (error) {
          console.error('Error fetching opportunity data:', error);
        }
      }
    }
    onMounted(() => {
      orgId.value = route.query.org_Id;
      if (route.query.for === 'persons') {
        fetchPersons(orgId.value);
      }
      const personsTab = document.querySelector('[data-bs-target="#persons"]');

      personsTab.addEventListener('shown.bs.tab', () => {
        fetchPersons(orgId.value); // Call the API when the Persons tab is active
      });
    });

    return {
      fetchPersons,
      orgPersons,
      orgId,
      ter_Id,
      isPersonsTabActive
    };
  },
};
</script>

<style lang="scss" scoped>
.org-title {
  text-transform: capitalize;
}

.opp-icon {
  background-color: #E41F07;
  color: #FFF;
  border: 1px solid #E41F07;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
</style>
