<template>
  <div class="modal fade" :class="{ show: isVisible }" tabindex="-1" aria-labelledby="exampleModalLabel"
    style="display: block" aria-modal="true" role="dialog" v-if="isVisible">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row w-100">
            <div class="col-6">
              <h3>Create Opportunity</h3>
            </div>
            <div class="col-6 text-end">
              <button class="btn-close" @click="closeModal">
                <i class="ti ti-x"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <Form @submit="saveOpprtunityData" :validation-schema="schema">
              <!-- v-if="notOppData === ''" -->
              <div class="row">
                <h4 class="text-indigo mb-2 text-decoration-underline">Contact Email</h4>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Email <span class="text-danger">*</span></label>
                    <Field id="email" name="email" as="input" type="email" class="form-control"
                      v-model="contact_email" />
                    <ErrorMessage name="email" class="text-danger" />
                    <span class="text-danger"
                      v-if="apiErrors && apiErrors['formData.email'] && apiErrors['formData.email'].length">
                      {{ apiErrors['formData.email'][0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <h4 class="text-indigo mb-2 text-decoration-underline">Organisation Details</h4>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Name <span class="text-danger">*</span></label>
                    <div class="icon-form">
                      <Field name="organisation_id" type="text" class="form-control"
                        placeholder="Search organisation name / postcode" v-model="searchQuery" @keyup="searchHandle"
                        @blur="onBlur" />
                      <span class="form-icon">
                        <i class="ti ti-building-community"></i>
                      </span>
                    </div>
                    <ul v-if="filteredOrganisations.length > 0 && isFocused && !isOrganisationSelected"
                      class="dropdown-menu shadow show pe-0" style="width: 97%;">
                      <li class="dropdown-listing" @scroll="onScrollEnd">
                        <ul>
                          <li v-for="organisation in filteredOrganisations" :key="organisation.id"
                            @click="selectOrganisation(organisation)" class="dropdown-item" style="cursor: pointer;">{{
                              (organisation.name) + ' ' + (organisation.postcode || '') }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ErrorMessage name="organisation_id" class="text-danger" />
                    <span class="text-danger"
                      v-if="apiErrors && apiErrors['quoteData.organisation.name'] && apiErrors['quoteData.organisation.name'].length">
                      {{ apiErrors['quoteData.organisation.name'][0] }}
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Postcode</label>
                    <Field name="org_postcode" as="input" type="text" class="form-control"
                      v-model="formData.QuoteData.organisation.postcode" />
                  </div>
                </div>
              </div>
              <div class="row">
                <h4 class="text-indigo mb-2 text-decoration-underline">Person Details</h4>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Name <span class="text-danger">*</span></label>
                    <div class="icon-form">
                      <Field name="person_id" type="text" class="form-control" placeholder="Search person name"
                        v-model="searchPerson" @keydown.enter="searchPersons" @keyup="onPersonInput"
                        @focus="showPersonDropdown" @blur="hidePersonDropdown" />
                      <span class="form-icon">
                        <i class="ti ti-building-community"></i>
                      </span>
                    </div>
                    <ul v-if="filteredPersons.length && isDropdownPersonVisible" class="dropdown-menu shadow show pe-0"
                      style="width: 97%;">
                      <li class="dropdown-listing">
                        <ul>
                          <li v-for="person in filteredPersons" :key="person.id" @click="selectPerson(person)"
                            class="dropdown-item" style="cursor: pointer;">{{ person.name }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ErrorMessage name="person_id" class="text-danger" />
                  </div>
                </div>
              </div>
              <div class="row">
                <h4 class="text-indigo mb-2 text-decoration-underline">Supply Address</h4>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Building Name <span class="text-danger">*</span></label>
                    <Field name="building_name" as="input" type="text" class="form-control"
                      v-model="formData.QuoteData.opportunity.building_name" />
                    <ErrorMessage name="building_name" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Street <span class="text-danger">*</span></label>
                    <Field name="street" as="input" type="text" class="form-control"
                      v-model="formData.QuoteData.opportunity.street" />
                    <ErrorMessage name="street" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">City <span class="text-danger">*</span></label>
                    <Field name="city" as="input" type="text" class="form-control"
                      v-model="formData.QuoteData.opportunity.city" />
                    <ErrorMessage name="city" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">County <span class="text-danger">*</span></label>
                    <Field name="county" as="input" type="text" class="form-control"
                      v-model="formData.QuoteData.opportunity.county" />
                    <ErrorMessage name="county" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">Postcode <span class="text-danger">*</span></label>
                    <Field name="postcode" as="input" type="text" class="form-control"
                      v-model="formData.QuoteData.opportunity.postcode" />
                    <ErrorMessage name="postcode" class="text-danger" />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6"></div>
                <div class="col-md-6 text-end">
                  <button type="submit" class="btn btn-primary">
                    {{ notOppData === '' ? 'Create and Send' : 'Create' }}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  onUnmounted,
  computed,
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
import { message } from 'ant-design-vue';
import opportunity from "@/store/modules/opportunity";

export default {
  props: {
    isVisible: {
      type: Boolean,
    },
    supplierData: {
      type: Object
    },
    currentSupplierData: {
      type: Object
    },
    notOppData: {
      type: String
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const filteredOrganisations = ref([]);
    const opportunity_id = ref(route.query.opp_Id || null)
    const filteredPersons = ref([]);
    const pdfParams = ref({});
    const searchQuery = ref('');
    const searchPerson = ref('');
    const apiErrors = ref(null);
    const isFocused = ref(false)
    const isFetching = ref(false);
    const current_page = ref(1);
    const isOppPresent = ref('');
    const total = ref()
    const isOrganisationSelected = ref(false)
    const isDropdownPersonVisible = ref(false)
    const isPersonSelected = ref(false);
    const supplierPostCode = ref(props.currentSupplierData?.quoteDetails?.PostCode || '')
    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);

    const onPersonInput = () => {
      isPersonSelected.value = false;
      searchPersons();
    }
    const searchPersons = () => {
      const query = searchPerson.value?.toLowerCase();
      formData.value.QuoteData.organisationPerson.first_name = query;
      formData.value.QuoteData.organisationPerson.person_id = null;

      if (query) {
        filteredPersons.value = orgPersons.value.filter((per) => {
          const nameMatch = per.name?.toLowerCase().includes(query);
          return nameMatch;
        });
      } else {
        filteredPersons.value = orgPersons.value;
      }
    };

    const selectPerson = (person) => {
      searchPerson.value = person.name;
      formData.value.QuoteData.organisationPerson.person_id = person.id;
      formData.value.QuoteData.organisationPerson.first_name = '';
      filteredPersons.value = [];
      isPersonSelected.value = true;
    };
    const selectOrganisation = async (organisation) => {
      formData.value.QuoteData.organisation.org_id = organisation.id;
      formData.value.QuoteData.organisation.name = '';
      if (organisation.postcode) {
        formData.value.QuoteData.organisation.postcode = organisation.postcode
      } else {
        formData.value.QuoteData.organisation.postcode = ''
      }
      searchQuery.value = organisation.name;
      await store.dispatch('opportunity/fetchPersons', organisation.id)
      await store.dispatch('organisation/fetchTerritoryCompanyUsers', organisation.territory_id)
      filteredOrganisations.value = [];
      isOrganisationSelected.value = true;
    };

    const contact_email = ref('');
    const formData = ref({
      QuoteData: {
        organisation: {
          org_id: null,
          name: "",
          postcode: "",
        },
        organisationPerson: {
          person_id: null,
          first_name: ""
        },
        opportunity: {
          postcode: supplierPostCode.value,
          building_name: "",
          street: "",
          city: "",
          county: ""
        }
      }
    })
    const schema = Yup.object().shape({
      organisation_id: Yup.string()
        .required("Organisation name is required"),
      person_id: Yup.string()
        .required("Person name is required"),
      building_name: Yup.string()
        .required("Building name is required"),
      postcode: Yup.string()
        .required("Postcode is required"),
      email: Yup.string()
        .required("Email is required")
        .email("The email field must be a valid email address."),
      // email: Yup.string().when([], {
      //   is: () => props.notOppData === "",
      //   then: (schema) =>
      //     schema
      //       .required("Email is required")
      //       .email("The email field must be a valid email address."),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
      street: Yup.string()
        .required("Street is required"),
      city: Yup.string()
        .required("City is required"),
      county: Yup.string()
        .required("County is required"),
    });
    const changeFormate = (date) => {
      const contractRenewalDate = new Date(date);
      let isoString = contractRenewalDate.toISOString();
      let formattedDate = isoString.split('.')[0];
      return formattedDate;
    };
    const saveOpprtunityData = async (supplier, oppData) => {
      if(typeof (oppData && oppData.id) === 'string'){
        isOppPresent.value = 'opp'
      } else {
        isOppPresent.value = ''
      }
      pdfParams.value = {
        service_type: props.currentSupplierData.mainProductService,
        organization_id: oppData?.organization_id ?? formData.value.QuoteData.organisation.org_id,
        person_id: oppData?.sales_contact ?? formData.value.QuoteData.organisationPerson.person_id,
        opportunity_id: opportunity_id.value ?? oppData?.id,
        quoteData: formData.value.QuoteData,
        formData: {
          email: contact_email.value || props.currentSupplierData.quoteDetails.Email,
          mpr: props.currentSupplierData.quoteDetails?.GasSupply?.MPR || props.currentSupplierData.quoteDetails?.ElectricSupply?.MPANBottom,
          contract_length: supplier?.Term || props.supplierData?.Term,
          credit_score: props.currentSupplierData.quoteDetails.Settings[1].value,
          current_supplier: props.currentSupplierData.quoteDetails.CurrentSupplier,
          renew_date: changeFormate(props.currentSupplierData.quoteDetails?.GasSupply?.ContractRenewalDate || props.currentSupplierData.quoteDetails?.ElectricSupply?.ContractRenewalDate),
          new_contract_end_date: (supplier?.ContractEndDate || props.supplierData?.ContractEndDate) + 'T00:00:00',
          payment_type: supplier?.PaymentMethod?.DisplayName || props.supplierData?.PaymentMethod?.DisplayName,
          annual_price: supplier?.RawAnnualPrice || props.supplierData?.RawAnnualPrice || null,
          annual_price_inclusive:  supplier?.RawAnnualPriceInclusive || props.supplierData?.RawAnnualPriceInclusive || null,
          ref: supplier?.Ref || props.supplierData?.Ref || '',
          tariff_code: supplier?.TariffCode || props.supplierData?.TariffCode ||  '',
          pricebook_version: supplier.PricebookVersion || props.supplierData?.PricebookVersion || '',
          unit_rate_uplift: supplier?.Uplift || props.supplierData?.Uplift,
          annual_commission: supplier?.RawCommission || props.supplierData?.RawCommission,
          new_supplier: supplier?.Supplier || props.supplierData?.Supplier,
          standing_charge_uplift: supplier?.StandingChargeUplift || props.supplierData?.StandingChargeUplift || '',
          mpan_top: props.currentSupplierData.quoteDetails?.ElectricSupply?.MPANTop || '',
          mpan_bottom: props.currentSupplierData.quoteDetails?.ElectricSupply?.MPANBottom || '',
          kva: props.currentSupplierData.quoteDetails?.ElectricSupply?.KvaConsumption?.Amount || null,
        },
        docusignDetails: {
          UsageRatesData: [
            {
              Key: "standing charge",
              Value: supplier?.StandingCharge ||props.supplierData?.StandingCharge || '',
            },
            {
              Key: "dayusage",
              Value: props.currentSupplierData.quoteDetails?.GasSupply?.Consumption.Amount || props.currentSupplierData.quoteDetails?.ElectricSupply?.DayConsumption.Amount || '',
            },
            {
              Key: "daycharge",
              Value: supplier?.DayUnitrate || props.supplierData?.DayUnitrate || '',
            },
            {
              Key: "nightusage",
              Value: props.currentSupplierData.quoteDetails?.ElectricSupply?.NightConsumption.Amount || '',
            },
            {
              Key: "nightcharge",
              Value: supplier?.NightUnitrate || props.supplierData?.NightUnitrate || '',
            },
            {
              Key: "eveandwendusage",
              Value: props.currentSupplierData.quoteDetails?.ElectricSupply?.WendConsumption.Amount || '',
            },
            {
              Key: "eveandwendcharge",
              Value: supplier?.WendUnitrate || props.supplierData?.WendUnitrate || '',
            }
          ],
          MeterDetailsData: [
            {
              Key: "ref",
              Value: supplier?.Ref || props.supplierData?.Ref || '',
            },
            {
              Key: "period",
              Value: supplier?.Period || props.supplierData?.Period || '',
            },
            {
              Key: "sc",
              Value: supplier?.SC || props.supplierData?.SC || '',
            },
            {
              Key: "fits",
              Value:  supplier?.Fits || props.supplierData?.Fits || '',
            },
            {
              Key: "creditscore",
              Value: props.currentSupplierData.quoteDetails.Settings[1].value || '',
            }
          ],
          TemplateOptions: {
            PlanType: null,
            HalfHourly: false,
            ChangeOfTenancy: false
          }
        }
      }
      if (props.notOppData === 'notOpp' && isOppPresent.value === '') {
        const response = await store.dispatch("price/createOpp", pdfParams.value);
        if (response.success) {
          emit('get-Opp', response)
          message.success('Opportunity created successfully');
          closeModal();
        } else {
          message.error(response.errors);
        }
      } else {
        const response = await store.dispatch("price/sendSupplierPdf", pdfParams.value);
        if (response.success) {
          closeModal()
          emit('sendingFail', response)
        } else {
          apiErrors.value = response;
          emit('sendingFail', apiErrors.value)
          closeModal()
          message.error(response.errors);
        }
      }
    };
    const closeModal = () => {
      contact_email.value = null;
      searchQuery.value = null;
      searchPerson.value = null;
      formData.value.QuoteData = {
        organisation: {
          org_id: null,
          name: "",
          postcode: "",
        },
        organisationPerson: {
          person_id: null,
          first_name: ""
        },
        opportunity: {
          postcode: supplierPostCode.value,
          building_name: "",
          street: "",
          city: "",
          county: ""
        }
      }
      emit("close"); // Now correctly using emit
    };

    const showPersonDropdown = () => {
      isDropdownPersonVisible.value = true;
      searchPersons();
    }
    const hidePersonDropdown = () => {
      setTimeout(() => {
        isDropdownPersonVisible.value = false;
      }, 100);
    }

    let searchTimeout;

    const searchHandle = () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => {
        searchOrganisations();
      }, 300);
    };

    const onBlur = () => {
      setTimeout(() => {
        isFocused.value = false; // Hide dropdown on blur
      }, 200);
    };

    const navigateToAddNewOrganisation = () => {
      emit('close')
      router.push({
        path: '/crm/create-organisation',
        query: { add: 'quoting' },
      });
    }

    const navigateToAddNewPerson = () => {
      emit('close')
      router.push({
        path: '/crm/create-person',
        query: { add: 'quoting', org_Id: formData.value.QuoteData.organisation.org_id },
      });
    }

    const appendDynamicData = (existingData, dynamicData) => {
      const combinedOutput = existingData.slice(); // Clone the existing output array
      dynamicData.forEach((item) => {
        combinedOutput.push(item);
      });

      filteredOrganisations.value = combinedOutput;

      return { data: combinedOutput };
    };

    const searchOrganisations = async () => {
      try {
        const query = searchQuery.value.toLowerCase();
        formData.value.QuoteData.organisation.name = query;
        formData.value.QuoteData.organisation.org_id = null;
        if (query && query.length >= 2) {
          if (isFetching.value) return;
          isFetching.value = true;
          let response;
          if (current_page.value === 1) {
            response = await store.dispatch("opportunity/getorgOptions", { search: query, page: current_page.value });
            filteredOrganisations.value = response.data.data
            current_page.value = response.data.current_page + 1;
            total.value = response.data.total;
            isFocused.value = true;
            isOrganisationSelected.value = false;
            return;
          }
          let count = filteredOrganisations.value.length;
          if (count >= 10 && total.value > count) {
            current_page.value = Math.ceil(count / 10) + 1;
            const params = {
              search: query,
              page: current_page.value
            };
            response = await store.dispatch("opportunity/getorgOptions", params);
            appendDynamicData(filteredOrganisations.value, response.data.data);
            current_page.value = response.data.current_page + 1;
            isFocused.value = true;
            isOrganisationSelected.value = false;
            return;
          }
        }
      } catch (error) {

      }
      finally {
        isFetching.value = false;
      }
    };

    const onScrollEnd = (event) => {
      const element = event.target;
      if (element.scrollTop + element.clientHeight >= element.scrollHeight - 3 && !isFetching.value) {
        searchOrganisations()
      }
    };

    watch(searchQuery, (newQuery) => {
      if (!newQuery) {
        isOrganisationSelected.value = false;
      } else {
        total.value = null
        current_page.value = 1
      }
    });

    watch(searchPerson, (newQuery) => {
      if (!newQuery) {
        isPersonSelected.value = false;
      }
    });

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue) {
          document.body.classList.add("modal-open");
          const backdrop = document.createElement("div");
          backdrop.className = "modal-backdrop fade show";
          document.body.appendChild(backdrop);
        } else {
          document.body.classList.remove("modal-open");
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.remove();
          }
        }
      }
    );
    // Cleanup on component unmount
    onUnmounted(() => {
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
    });
    watch(
      () => props.isVisible, // Watching the `isVisible` prop
      (newValue) => {
        if (newValue) {
          contact_email.value =
            props.currentSupplierData.quoteDetails.Email;
        }
      }
    );
    // Initialize search options on component mount
    onMounted(async () => {
      await store.dispatch('opportunity/getorgOptions');
    });

    return {
      schema,
      contact_email,
      apiErrors,
      searchPerson,
      pdfParams,
      opportunity_id,
      selectPerson,
      orgPersons,
      searchPersons,
      supplierPostCode,
      onPersonInput,
      closeModal,
      changeFormate,
      formData,
      saveOpprtunityData,
      searchOrganisations,
      searchQuery,
      selectOrganisation,
      filteredOrganisations,
      filteredPersons,
      isDropdownPersonVisible,
      showPersonDropdown,
      hidePersonDropdown,
      searchHandle,
      // onFocus,
      onBlur,
      onScrollEnd,
      isFocused,
      isFetching,
      isOppPresent,
      current_page,
      total,
      isOrganisationSelected,
      appendDynamicData,
      navigateToAddNewOrganisation,
      isPersonSelected,
      navigateToAddNewPerson
    };
  },
};
</script>

<style scoped>
.modal {
  display: block;
}

.table_height {
  max-height: 428px;
}

.dropdown-listing {
  overflow: auto;
  max-height: 25vh;
}
</style>
