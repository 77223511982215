<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <!-- Page Header -->
            <page-header :title="title" />
            <!-- /Page Header -->
    
            <!-- Settings Menu -->
            <settings-tabs></settings-tabs>
            <!-- /Settings Menu -->
    
            <div class="row">
              <!-- <website-sidebar></website-sidebar> -->
    
              <div class="col-xl-12 col-lg-12">
                <!-- Company Settings -->
                <div class="card">
                  <div class="card-body settings-form">
                    <!-- Search -->
                    <div class="search-section">
                      <div class="row">
                        <div class="col-md-5 col-sm-4">
                          <div class="form-wrap icon-form">
                            <span class="form-icon">
                              <i class="ti ti-search"></i>
                            </span>
                            <input type="text" class="form-control" placeholder="Search Supplier name" v-model="searchUserQuery" @input="searchUsers" />
                          </div>
                        </div>
                        <div class="col-md-7 col-sm-8">
                        </div>
                      </div>
                    </div>
                    <!-- /Search -->
                    <div class="row mb-4 align-items-center">
                      <div class="col-md-5 col-sm-4">
                        <h4>Suppliers</h4>
                      </div>
                      <div class="col-md-7 col-sm-8">
                        <div class="export-list text-sm-end">
                          <router-link class="btn btn-primary" to="/supplier/create-supplier">
                            Add Supplier</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive custom-table">
                      <supplier-table :search-user-query="searchUserQuery" ref="supplierTable"></supplier-table>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="datatable-length"></div>
                      </div>
                      <div class="col-md-6">
                        <div class="datatable-paginate"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Company Settings -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    
    <script>
    export default {
      data() {
        return {
          title: "Settings",
          searchUserQuery: "",
        };
      },
      methods: {
        async searchUsers() {
          this.$refs.supplierTable.fetchSupplier();
        },
      }
    };
    </script>
    