<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap icon-form">
                          <span class="form-icon"><i class="ti ti-search"></i></span>
                          <input type="text" class="form-control" placeholder="Search opportunity by name / number"
                            v-model="searchOppQuery" @input="searchOpportunities" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <user-filters :from="'opportunity'" @filterData="searchOpportunities"></user-filters>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="view-icons">
                            <router-link to="/crm/meter-opportunities-kanban"><i
                                class="ti ti-layout-kanban"></i></router-link>
                            <router-link to="/crm/meter-opportunities-list" class="active"><i
                                class="ti ti-list-tree"></i></router-link>
                          </div>
                        </li>
                        <li>
                          <div class="form-sorts dropdown">
                            <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="true">
                              <i class="ti ti-filter-share"></i>Filter
                            </a>
                            <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
                              <div class="filter-set-view">
                                <div class="filter-set-head">
                                  <h4><i class="ti ti-filter-share"></i>Filter</h4>
                                </div>
                                <div class="filter-content-list mb-2">
                                  <label for="search-status" class="mb-2">Next update Start Date - Next update End
                                    Date</label>
                                  <date-time-picker v-model="date" range multi-calendars format="yyyy-MM-dd" />
                                </div>
                                <div class="filter-content-list mb-4">
                                  <label for="search-status" class="mb-2">Pipeline</label>
                                  <Field as="select" name="pipeline" class="form-select" v-model="pipeline">
                                    <option value="">-----Select-----</option>
                                    <option v-for="res in pipelineLevels" :key="res.id" :value="res.id">
                                      {{ res.name }}
                                    </option>
                                  </Field>
                                </div>
                                <div class="filter-reset-btns">
                                  <div class="row">
                                    <div class="col-6">
                                      <a href="javascript:void(0);" class="btn btn-light"
                                        @click="resetFilters">Reset</a>
                                    </div>
                                    <div class="col-6">
                                      <a href="javascript:void(0);" class="btn btn-primary"
                                        @click="applyFilters">Filter</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <router-link to="/crm/create-meter-opportunity" class="btn btn-primary"><i
                              class="ti ti-square-rounded-plus"></i>Add Opportunity</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Contact List -->
              <div class="table-responsive custom-table">
                <meter-opportunities-table :search-opp-query="searchOppQuery" :date="date" :pipeline="pipeline"
                  ref="oppTable"></meter-opportunities-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Contact List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  data() {
    return {
      title: "Meter Opportunities",
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const searchOppQuery = ref(""); // Example: For holding search query input
    const oppTable = ref(null);
    const date = ref(null);
    const pipeline = ref(null);

    const pipelineLevels = computed(
      () => store.getters["opportunity/getPipelineLevels"]
    );
    const searchOpportunities = () => {
      if(oppTable.value){
        oppTable.value.fetchOpportunities();
      }
    };

    const resetFilters = () => {
      date.value = null;
      pipeline.value = null
  setTimeout(() => {
    applyFilters();
  }, 0); 
};

    const applyFilters = () => {
      searchOpportunities();
    };

    onMounted(async () => {
      await store.dispatch("opportunity/fetchPipelines");
    });

    return {
      pipelineLevels,
      date,
      resetFilters,
      searchOpportunities,
      applyFilters,
      searchOppQuery,
      pipeline,
      oppTable
    };
  },
};
</script>
