<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <page-header :title="title" />
        <settings-tabs></settings-tabs>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="contact-head">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <ul class="contact-breadcrumb">
                    <li>
                      <router-link to="/territory/territory-list"><i class="ti ti-arrow-narrow-left"></i>List</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Company Settings -->
            <div class="card">
              <div class="card-body settings-form">
                <div class="row mb-2">
                  <div class="col-md-5 col-sm-4">
                    <h4>{{ isEditing ? 'Edit Territory' : 'Create Territory' }}</h4>
                  </div>
                  <div class="col-md-7 col-sm-8">
                  </div>
                </div>
                <Form @submit="createTerritory" :validation-schema="schema">
                  <div class="territory-details">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Name</label>
                          <Field name="name" as="input" type="text" class="form-control" v-model="form.name" />
                          <ErrorMessage name="name" class="text-danger" />
                          <span class="text-danger" v-if="apiError">{{ apiError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end">
                    <router-link class="btn btn-light" to="/territory/territory-list">
                      Back</router-link>
                    <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create' }}</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted
} from "vue";
import apiClient from "@/axios"; // Adjust this path if necessary
import {
  useRoute,
  useRouter
} from 'vue-router';
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      title: "Settings",
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isEditing = ref(false);
    const apiError =ref('');
    
    const schema = Yup.object().shape({
      name: Yup.string()
      .required("Name is required")
    });
    
    if (route.query.territoryId) {
      isEditing.value = true;
    }

    const form = ref({
      id: null,
      name: ''
    });

    const fetchTerritoryData = async (id) => {
      try {
        const response = await apiClient.get(`/territory/view/${id}`);
        Object.assign(form.value, response.data.data);
      } catch (error) {
        console.error('Error fetching territory data:', error);
      }
    };


    const createTerritory = async () => {
      try {
        if (form.value.id) {
          await apiClient.put(`/territory/update/${form.value.id}`, form.value);
        } else {
          await apiClient.post('/territory/create', form.value);
        }
        router.push('/territory/territory-list');
      } catch (error) {
        apiError.value = error.response.data.message;  
        console.error('Error saving territory:', error);
      }
    };

    onMounted(async () => {
      const territoryId = route.query.territoryId;
      if (territoryId) {
        fetchTerritoryData(territoryId);
      }
    });
    return {
      schema,
      isEditing,
      form,
      createTerritory,
      apiError
    };
  },
};
</script>
