import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/pages/pages/authentication/auth-login.vue'
import Forgot_Password from '@/views/pages/pages/authentication/forgot-password.vue'
import Reset_Password from '@/views/pages/pages/authentication/reset-password.vue'
import Success from '@/views/pages/pages/authentication/auth-success.vue'
import Crm_Error from '@/views/pages/pages/error/crm-error.vue'
import Error_404 from '@/views/pages/pages/error/error-404.vue'
import Error_500 from '@/views/pages/pages/error/error-500.vue'
import Blank_Page from '@/views/pages/pages/blank-page.vue'
import Coming_Soon from '@/views/pages/pages/coming-soon.vue'
import Under_Maintenance from '@/views/pages/pages/under-maintenance.vue'
import CRM from '@/views/pages/crm/crm-index.vue'
import ReportsList from '@/views/pages/crm/reports/reports-list.vue'
import Crm_Settings from '@/views/pages/settings/crm-settings.vue'
import Crm_Profile from '@/views/pages/settings/general-settings/crm-profile.vue'
import Crm_Security from '@/views/pages/settings/general-settings/crm-security.vue'
import Crm_Notifications from '@/views/pages/settings/general-settings/crm-notifications.vue'
import Credential from '@/views/pages/settings/general-settings/crm-credentilas.vue'
import PlanTypes from '@/views/pages/settings/general-settings/plan-types.vue'
import Website_Settings from '@/views/pages/settings/data-import/website-settings.vue'
import Upload_file from '@/views/pages/settings/data-import/upload-file.vue'
// users
import Users from '@/views/pages/settings/users/users.vue'
import Users_list from '@/views/pages/settings/users/users-list.vue'
import Create_user from '@/views/pages/settings/users/create-user.vue'
// pipeline
import PipeLines from '@/views/pages/settings/pipelines/pipelines.vue'
import PipeLines_list from '@/views/pages/settings/pipelines/pipelines-list.vue'
import Create_PipeLine from '@/views/pages/settings/pipelines/create-pipeline.vue'

import Data_status from '@/views/pages/settings/data-import/import-data-status.vue'
import Get_quoting from '@/views/pages/quoting/get-quoting.vue'
import Quoting from '@/views/pages/quoting/quoting-index.vue'

// company admin
import Companies from '@/views/pages/companies/companies-index.vue'
import CompaniesAdminList from '@/views/pages/companies/companies-list.vue'
import Create_company from '@/views/pages/companies/create-company.vue'

// pipeline
import Supplier from '@/views/pages/settings/supplier/supplier.vue'
import Supplier_list from '@/views/pages/settings/supplier/supplier-list.vue'
import Create_Supplier from '@/views/pages/settings/supplier/create-supplier.vue'

//dropdowns
import DropdownList from '@/views/pages/settings/drop-downs/dropdown-list.vue'
import Dropdown from '@/views/pages/settings/drop-downs/dropdown.vue'


// import data
import Map_data from '@/views/pages/settings/data-import/map-data.vue'
import Assign_data from '@/views/pages/settings/data-import/assign-data.vue'
import Validate from '@/views/pages/settings/data-import/validate.vue'
import Appearance from '@/views/pages/settings/data-import/appearance-settings.vue'
import Create_mode from '@/views/pages/settings/data-import/create-mode.vue'
import Error_logs from '@/views/pages/settings/data-import/error-logs.vue'
import ReportEdit from '@/views/pages/crm/reports/report-edit.vue'
import ReportOverview from '@/views/pages/crm/reports/report-overview.vue'
import CompaniesList from '@/views/pages/crm/organisation/organisation-list.vue';
import CreateOrganisation from '@/views/pages/crm/organisation/create-organisation.vue';
import ReportChart from '@/views/pages/crm/reports/report-chart.vue'

import PersonsList from '@/views/pages/crm/person/person-list.vue';
import CreatePerson from '@/views/pages/crm/person/create-person.vue';

import Crm_User from '@/views/pages/user/crm-user.vue'
import Manage_Users from '@/views/pages/user/manage-users.vue'
import Roles_Permissions from '@/views/pages/user/roles-permissions.vue'
import Crm_Permission from '@/views/pages/user/crm-permission.vue'
import Delete_Request from '@/views/pages/user/delete-request.vue'
import Crm_Report from '@/views/pages/report/crm-report.vue'
import Lead_Report from '@/views/pages/report/lead-reports/lead-reports.vue'
import Deal_Report from '@/views/pages/report/deal-reports/deal-reports.vue'
import Contact_Report from '@/views/pages/report/contact-reports/contact-reports.vue'
import Company_Report from '@/views/pages/report/company-reports/company-reports.vue'
import Project_Report from '@/views/pages/report/project-reports/project-reports.vue'
import Task_Report from '@/views/pages/report/task-reports/task-reports.vue'
import CompaniesGrid from '@/views/pages/crm/organisation/companies-grid.vue';
import OrganisationDetails from '@/views/pages/crm/organisation/organisation-details/organisation-details.vue';

// meter-opportunities
import OppLeadsList from '@/views/pages/crm/meter-opportunities/meter-opportunities-list.vue'
import LeadsKanban from '@/views/pages/crm/meter-opportunities/meter-opportunities-kanban.vue'
import MeterOppEdit from '@/views/pages/crm/meter-opportunities/meter-opportunities-edit.vue'
import MeterOppCreate from '@/views/pages/crm/meter-opportunities/create-meter-opportunity.vue'
import QuotedPrice from '@/views/pages/crm/meter-opportunities/quoted-price-list.vue'

import LeadsList from '@/views/pages/crm/leads/leads-list.vue'
import Activities from '@/views/pages/crm/activities/activities-list.vue'
import Dashboard from '@/views/pages/dashboard/dashboard-index.vue'
import DealsDashboard from '@/views/pages/dashboard/deals-dashboard/deals-dashboard.vue'
import Contracts_List from '@/views/pages/crm/contracts/contracts-list.vue'

// contracts
import Create_contract from '@/views/pages/crm/contracts/create-contract.vue'
import Edit_contract from '@/views/pages/crm/contracts/edit-contract.vue'

// Activity
import Create_activity from '@/views/pages/crm/activities/create-activity.vue'
import Edit_activity from '@/views/pages/crm/activities/edit-activity.vue'

// territory
import Territory from '@/views/pages/settings/territory/territory.vue'
import Territory_list from '@/views/pages/settings/territory/territory-list.vue'
import Create_territory from '@/views/pages/settings/territory/create-territory.vue'
import component from 'vue3-tags-input';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgot_Password
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: Reset_Password
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/blank-page',
    name: 'blank-page',
    component: Blank_Page
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: Coming_Soon
  },
  {
    path: '/under-maintenance',
    name: 'under-maintenance',
    component: Under_Maintenance
  },
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      { path: '', redirect: '/dashboard/deals-dashboard' },
      { path: 'deals-dashboard', component: DealsDashboard },
    ]
  },
  {
    path: '/quoting',
    component: Quoting,
    children: [
      { path: '', redirect: '/quoting/get-quoting' },
      { path: 'get-quoting', component: Get_quoting },
    ]
  },
  {
    path: '/companies',
    component: Companies,
    children: [
      { path: '', redirect: '/companies/companies-list' },
      { path: 'companies-list', component: CompaniesAdminList },
      { path: 'create-company', component: Create_company }
      
    ]
  },
  {
    path: '/report',
    component: Crm_Report,
    children: [
      { path: '', redirect: '/report/lead-reports' },
      { path: "lead-reports", component: Lead_Report },
      { path: "deal-reports", component: Deal_Report },
      { path: "contact-reports", component: Contact_Report },
      { path: "company-reports", component: Company_Report },
      { path: "project-reports", component: Project_Report },
      { path: "task-reports", component: Task_Report },
    ]
  },
  {
    path: '/error',
    component: Crm_Error,
    children: [
      { path: '', redirect: '/error/error-404' },
      { path: "error-404", component: Error_404 },
      { path: "error-500", component: Error_500 },
    ]
  },
  {
    path: '/user',
    component: Crm_User,
    children: [
      { path: '', redirect: '/user/manage-users' },
      { path: "manage-users", component: Manage_Users },
      { path: "roles-permissions", component: Roles_Permissions },
      { path: "permission", component: Crm_Permission },
      { path: "delete-request", component: Delete_Request },
    ]
  },
  {
    path: '/settings',
    component: Crm_Settings,
    children: [
      { path: '', redirect: '/settings/profile' },
      { path: "profile", component: Crm_Profile },
      { path: "security", component: Crm_Security },
      { path: "company", component: Crm_Notifications },
      { path: "plan-types", component: PlanTypes },
      { path: "credentials", component: Credential },
    ]
  },
  {
    path: '/data-import',
    component: Website_Settings,
    children: [
      { path: '', redirect: '/data-import/import-data-status' },
      { path: "upload-file", component: Upload_file },
      { path: "import-data-status", component: Data_status },
      { path: "map-data", component: Map_data },
      { path: "assign-data", component: Assign_data },
      { path: "validate", component: Validate },
      { path: "appearance", component: Appearance },
      { path: "create-mode", component: Create_mode },
      { path: "error-logs", component: Error_logs },
    ]
  },
  {
    path: '/users',
    component: Users,
    children: [
      { path: '', redirect: '/users/users/users-list' },
      { path: "users-list", component: Users_list },
      { path: "create-user", component: Create_user },
    ]
  },
  {
    path: '/pipelines',
    component: PipeLines,
    children: [
      { path: '', redirect: '/pipelines/pipelines/pipelines-list' },
      { path: "pipelines-list", component: PipeLines_list },
      { path: "create-pipeline", component: Create_PipeLine },
    ]
  },
  {
    path: '/supplier',
    component: Supplier,
    children: [
      { path: '', redirect: '/supplier/supplier/supplier-list' },
      { path: "supplier-list", component: Supplier_list },
      { path: "create-supplier", component: Create_Supplier },
    ]
  },
  {
    path: '/territory',
    component: Territory,
    children: [
      { path: '', redirect: '/territory/territory-list' },
      { path: "territory-list", component: Territory_list },
      { path: "create-territory", component: Create_territory },
    ]
  },
  {
    path: '/drop-downs',
    component: Dropdown,
    children: [
      { path: '', redirect: '/drop-downs/dropdown-list' },
      { path: "dropdown-list", component: DropdownList },
    ]
  },
  {
    path: '/crm',
    component: CRM,
    children: [
      // Reports
      { path: '', redirect: '/crm/reports-list' },
      { path: 'reports-list', component: ReportsList },
      { path: 'report-edit', component: ReportEdit },
      { path: 'overview/report', component: ReportOverview },
      { path: 'report-chart', component: ReportChart },

      // organisation
      { path: 'organisation-list', component: CompaniesList },
      { path: 'create-organisation', component: CreateOrganisation },
      { path: 'companies-grid', component: CompaniesGrid },
      { path: 'organisation-details', component: OrganisationDetails },

      // person
      { path: 'person-list', component: PersonsList },
      { path: 'create-person', component: CreatePerson },

      // meter opportuities
      { path: 'meter-opportunities-list', component: OppLeadsList },
      { path: 'meter-opportunities-kanban', component: LeadsKanban },
      { path: 'meter-opportunities-edit', component: MeterOppEdit },
      { path: 'create-meter-opportunity', component: MeterOppCreate },
      { path: 'quoted-price-list', component: QuotedPrice },

      // activity
      { path: 'activities-list', component: Activities },
      { path: 'create-activity', component: Create_activity },
      { path: 'edit-activity', component: Edit_activity },

      // Leads
      { path: 'leads-list', component: LeadsList },

      // contracts
      { path: 'create-contract', component: Create_contract },
      { path: 'contracts-list', component: Contracts_List },
      { path: 'edit-contract', component: Edit_contract},
    ]
  }
];

export const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  routes,
});

// Global Navigation Guard
router.beforeEach((to, from, next) => {
  // Scroll to the top of the page on every route change
  window.scrollTo({ top: 0, behavior: 'smooth' });
  
  // Example: Ensure authentication for protected routes
  const token = localStorage.getItem('authToken');
  
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    // If the route requires authentication and there's no token, redirect to login
    next('/');
  } else {
    next(); // Proceed with the navigation
  }
});

export default router;