<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Search -->
          <div class="search-section">
            <div class="row">
              <div class="col-md-5 col-sm-4">
                <div class="row mx-0">
                  <div class="col-md-6">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input type="text" class="form-control" placeholder="Search opportunities"
                        v-model="searchOppBoardQuery" @input="searchOpportunitiesBoard" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <user-filters :from="'opportunity'" @filterData="searchOpportunitiesBoard"></user-filters>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-sm-8">
                <div class="export-list text-sm-end">
                  <ul>
                    <li>
                      <div class="view-icons">
                        <router-link to="/crm/meter-opportunities-kanban" class="active"><i
                            class="ti ti-layout-kanban"></i></router-link>
                        <router-link to="/crm/meter-opportunities-list"><i class="ti ti-list-tree"></i></router-link>
                      </div>
                    </li>
                    <li>
                      <div class="form-sorts dropdown">
                        <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="true">
                          <i class="ti ti-filter-share"></i>Filter
                        </a>
                        <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
                          <div class="filter-set-view">
                            <div class="filter-set-head">
                              <h4><i class="ti ti-filter-share"></i>Filter</h4>
                            </div>
                            <div class="filter-content-list mb-2">
                              <label for="search-status" class="mb-2">Next update Start Date - Next update End Date</label>
                              <date-time-picker v-model="date" range multi-calendars format="yyyy-MM-dd" />
                              <!-- <date-time-picker v-model="date" :range="true" :multiple="true" format="yyyy-MM-dd" /> -->
                            </div>
                            <div class="filter-reset-btns">
                              <div class="row">
                                <div class="col-6">
                                  <a href="javascript:void(0);" class="btn btn-light" @click="resetFilters">Reset</a>
                                </div>
                                <div class="col-6">
                                  <a href="javascript:void(0);" class="btn btn-primary" @click="applyFilters">Filter</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <router-link :to="{ path: '/crm/create-meter-opportunity', query: { kanban: true } }"
                        class="btn btn-primary"><i class="ti ti-square-rounded-plus"></i>Add Opportunity</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Search -->

          <!-- Leads Kanban -->
          <meter-opportunities-board :search-board-query="searchOppBoardQuery" :filters="date"
            ref="oppBoardTable"></meter-opportunities-board>

          <!-- /Leads Kanban -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <opportunities-list-modal></opportunities-list-modal>
</template>

<script>
import {
  ref,
} from 'vue';
export default {
  data() {
    return {
      title: "Meter Opportunities",
    };
  },
  setup() {
    const searchOppBoardQuery = ref(""); // Example: For holding search query input
    const oppBoardTable = ref(null);
    const date = ref(null);

    const searchOpportunitiesBoard = () => {
      if(oppBoardTable.value){
        oppBoardTable.value.fetchBoardOpportunities();
      }
    };

    const resetFilters = () => {
  date.value = null;
 
  setTimeout(() => {
    applyFilters();
  }, 0); 
};

    const applyFilters = () => {
      searchOpportunitiesBoard();
    };
    return {
      searchOppBoardQuery,
      date,
      oppBoardTable,
      resetFilters,
      searchOpportunitiesBoard,
      applyFilters,
    }
  }
};
</script>
