<template>
  <a-table class="table dataTable no-footer" :columns="filteredColumns" :data-source="data" :row-selection="rowSelection"
    :pagination="pagination" @change="handleTableChange">
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Star'">
        <div class="set-star rating-select"><i class="fa fa-star"></i></div>
      </template>
      <template v-else-if="column.key === 'number'">
        <div>
          <router-link
            :to="{ path: '/crm/meter-opportunities-edit', query: { territory_id: record.territory_id, org_Id: record.orgId, opp_Id: record.key } }"
            class="title-name">{{
              record.number
            }}</router-link>
        </div>
      </template>
      <template v-if="column.key === 'org_name'">
        <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: record.territory_id, org_Id: record.orgId, for: 'opportunities' } }" class="title-name">{{
          record.org_name
        }}</router-link>
      </template>
      <template v-else-if="column.key === 'responsible'">
      <div>
        <span class="text-capitalize">{{ record.responsible }}</span>
      </div>
    </template>
    <!-- <template v-else-if="column.key === 'contract_end_date_days'">
        {{ record.contract_end_date_days }}
      </template>
      <template v-else-if="column.key === 'contract_end_date'>
        {{ record.contract_end_date }}
      </template> -->
      <template v-else-if="column.key === 'action'">
        <div class="icon-field">
              <!-- kanban: true, -->
          <a :href="$router.resolve({
            path: '/crm/meter-opportunities-edit',
            query: {
              ter_Id: record.territory_id,
              org_Id: record.orgId,
              opp_Id: record.key,
            },
          }).href" target="_blank" title="Edit" class="btn table_action_btn">
            <i class="ti ti-edit"></i> Edit
          </a>

          <router-link :to="{ path: '/quoting', query: { opp_Id: record.key, for: record.product } }"
            v-if="record.is_validate !== 0" class="btn table_action_btn ms-2"> <i class="ti-bar-chart"></i> Get
            Quote</router-link>
          <router-link
            :to="{ path: '/crm/quoted-price-list', query: { ter_Id: record.territory_id, org_Id: record.orgId, opp_Id: record.key } }"
            v-if="record.quotes_count !== 0" class="btn table_action_btn ms-2"> <i class="ion-pricetags"></i> Quoted
            Prices</router-link>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import {
  ref,
  onMounted
} from 'vue';
import {
  useRouter
} from 'vue-router';
import { mapGetters, mapActions } from 'vuex';
import apiClient from '@/axios';

const columns = [{
  title: "Number",
  dataIndex: "number",
  key: "number",
  sorter: {
    compare: (a, b) => {
      a = a.number;
      b = b.number;
      return a > b ? -1 : b > a ? 1 : 0;
    },
  },
},
{
  title: "Org. Name",
  dataIndex: "org_name",
  key: "org_name",
  sorter: {
    compare: (a, b) => {
      a = a.org_name;
      b = b.org_name;
      return a > b ? -1 : b > a ? 1 : 0;
    },
  },
},
{
    title: "Responsible",
    dataIndex: "responsible",
    key: "responsible",
    sorter: {
      compare: (a, b) => {
        a = a.responsible;
        b = b.responsible;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
{
  title: "Pipeline Text",
  dataIndex: "pipe_line",
  sorter: {
    compare: (a, b) => {
      a = a.pipe_line;
      b = b.pipe_line;
      return a > b ? -1 : b > a ? 1 : 0;
    },
  },
},
{
  title: "Next Update Latest",
  dataIndex: "next_update_latest_date",
  sorter: {
    compare: (a, b) => {
      const dateA = new Date(a.next_update_latest_date);
      const dateB = new Date(b.next_update_latest_date);
      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    },
  },
},
{
  title: "Time",
  dataIndex: "next_update_latest_time",
  sorter: {
    compare: (a, b) => {
      a = a.next_update_latest_time;
      b = b.next_update_latest_time;
      return a > b ? -1 : b > a ? 1 : 0;
    },
  },
},
{
  title: "SC Forecast Annual Consumption",
  dataIndex: "annual_consumption",
  sorter: {
    compare: (a, b) => {
      a = a.annual_consumption;
      b = b.annual_consumption;
      return a > b ? -1 : b > a ? 1 : 0;
    },
  },
},
{
  title: "Contract End Date (Days)",
  dataIndex: "contract_end_date_days",
  key: "contract_end_date_days",
  sorter: {
    compare: (a, b) => {
      const dateA = new Date(a.contract_end_date_days);
      const dateB = new Date(b.contract_end_date_days);
      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    },
  },
},
{
  title: "Contract End Date",
  dataIndex: "contract_end_date",
  key: "contract_end_date",
  sorter: {
    compare: (a, b) => {
      const dateA = new Date(a.contract_end_date);
      const dateB = new Date(b.contract_end_date);
      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    },
  },
},
{
  title: "Action",
  dataIndex: "Action",
  key: "action",
},
];

export default {
  props: {
    searchOppQuery: String,
    date: String,
    pipeline: String
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => { },
        onSelect: () => { },
        onSelectAll: () => { },
      },
    };
  },
  // computed: {
  //   ...mapGetters('auth', ['company', 'user']), // 'auth' is the namespace of your Vuex module

  // },
  // methods: {
  //   ...mapActions('auth', ['loadCompanyFromLocalStorage', 'loadUserFromLocalStorage']),
  // },
  // mounted() {
  //   this.loadCompanyFromLocalStorage();
  // },
  setup(props) {
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchOpportunities = async () => {
      try {
        const response = await apiClient.get('/opportunities', {
          params: {
            search: props.searchOppQuery,
            page: pagination.value.current,
            pageSize: pagination.value.pageSize,
            start_date: formateDate(props.date?.[0]),
            end_date: formateDate(props.date?.[1]),
            pipeline_level: props.pipeline
          },
        });
        if (response.data.status) {
          data.value = response.data.data.data.map((opp) => ({
        key: opp.id,
        number: opp.meter_opportunity_nr_auto,
        pipe_line: opp.pipeline.name,
        contract_end_date: opp.contract_end_date,
        contract_end_date_days: opp.contract_end_date_days,
        territory_id: opp.territory_id,
        orgId: opp.organization.id,
        org_name: opp.organization.name,
        responsible: (opp.responsible_user?.first_name ?? '') + ' ' + (opp.responsible_user?.last_name ?? ''),
        next_update_latest_date: opp.next_update_latest_date ? new Date(opp.next_update_latest_date).toLocaleDateString() : null,
        next_update_latest_time: opp.next_update_latest_time ? opp.next_update_latest_time : null,
        annual_consumption: opp.forecast_commission_data?.sc_forecast_or_amended_annual_consumption || '0.00',
        is_validate: opp.is_valid,
        product: opp.main_production_service,
        quotes_count: opp.quotes_count,
      }));
      // Call to filter columns based on data availability
      filterColumns();
      pagination.value.total = response.data.data.total;
        } else {
          console.error("Failed to fetch opportunities:", response);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    };
    const filteredColumns = ref(columns);
    const filterColumns = () => {
  // Dynamically filter columns that have no data
  filteredColumns.value = columns.filter((column) => {
    if (column.dataIndex === "contract_end_date" || column.dataIndex === "contract_end_date_days") {
      return data.value.some((row) => row[column.dataIndex] !== null && row[column.dataIndex] !== undefined);
    }
    return true; // Keep other columns
  });
};
    // Function to convert date format
    const formatDate = (date) => {
      if(date){
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    }
    };
    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchOpportunities();
    };

    const formateDate = (dateString) => {
      let date
      if(!dateString){
        date = new Date(props.date?.[0])
      }else{
        date = new Date(dateString);
      }
      if (isNaN(date.getTime())) {
        return null;
      }

      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');

      const formattedDate = `${yyyy}-${mm}-${dd}`;
      return formattedDate;
    };


    const openEditModal = (id) => {
      router.push({
        path: "/crm/create-organisation",
        query: {
          org_Id: id
        },
      });
    };
    // onMounted(async () => {
    //  await fetchOpportunities();
    // });
   
    return {
      data,
      pagination,
      filterColumns,
      filteredColumns,
      formatDate,
      fetchOpportunities,
      handleTableChange,
      openEditModal,
      formateDate
    };
  },
};
</script>
