<template>
    <div  v-if="isMessage.status" class="loader">
        <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            <span class=""> {{"Import in progress......"}}</span>
        </button>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props:{
        isMessage:{
          type:String,
          default:false
        }
    }
}
</script>
<style>
.loader {
    z-index: 99999;
    position: fixed;
    bottom: 50px;
    right: 50px;
}
</style>