<template>
    <index-loader></index-loader>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <!-- Page Wrapper -->
    <div class="row">
        <div class="col-md-12">
            <div class="row position-relative m-0">
                <div class="organisation-data-form">
                    <Form @submit="sendLOA('org')">
                        <h4 class=" pb-4 text-center">LETTER OF AUTHORITY</h4>
                        <div class="row">
                            <div class="col-md-12 company-field">
                                <div class="form-wrap">
                                    <label class="col-form-label">Agent Email </label>
                                    <Field name="agent_email" as="input" disabled type="text" class="form-control"
                                        v-model="formData.AgentEmail" />
                                    <ErrorMessage name="agent_email" class="text-danger" />
                                </div>
                            </div>
                            <div class="col-md-12 company-field">
                                <div class="form-wrap">
                                    <label class="col-form-label">LOA Template </label>
                                    <Field as="select" name="loa_temp" class="form-select"
                                        v-model="formData.loa_template">
                                        <option value=""><b>DocuSign Templates</b></option>
                                        <option v-for="res in loaTempOptions" :key="res.value" :value="res.value">
                                            {{ res.text }}
                                        </option>
                                    </Field>
                                </div>
                            </div>
                            <div class="col-md-12 company-field">
                                <div class="form-wrap">
                                    <label class="col-form-label">LOA Start Date</label>
                                    <div class="icon-form">
                                        <date-picker textInput placeholder="" class="datetimepicker form-control"
                                            :editable="true" :clearable="true"
                                            v-model="formData.MainDetailsData.loa_start_date"
                                            :input-format="dateFormat" />
                                        <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 company-field">
                                <div class="form-wrap">
                                    <label class="col-form-label">LOA Expiry</label>
                                    <div class="icon-form">
                                        <date-picker textInput placeholder="" class="datetimepicker form-control"
                                            :editable="true" :clearable="true"
                                            v-model="formData.MainDetailsData.loa_end_date"
                                            :input-format="dateFormat" />
                                        <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-button text-end mb-3">
                            <button type="submit" class="btn btn-primary">
                                Send LOA
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
    <!-- /Page Wrapper -->
</template>

<script>
import {
    ref
} from "vue";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    onMounted,
    computed
} from "vue";
import {
    useStore,
    mapGetters
} from "vuex";
import {
    message
} from "ant-design-vue";
import {
    useToast
} from "vue-toast-notification";
import * as Yup from "yup";
import apiClient from "@/axios";
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import {
    changeFormate
} from '../../../../util'
export default {
    props: {
        orgPersons: {
            type: Array,
            default: () => [],
        },
        opportunityData: {
            type: Object,
            default: () => ({}),
        },
        sendFromOrg: {
            type: Boolean,
            default: () => false,
        },
        settingOptions: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
        };
    },
    setup(props, {
        emit
    }) {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();
        const transformedData = ref({});
        const loaTempOptions = ref([{
            value: "power",
            text: "POWER Standard LOA",
        },
        {
            value: "u4b",
            text: "U4B LOA",
        },
        {
            value: "sse",
            text: "SSE - LOA",
        },
        ]);
        const loaHeaderToUse = ref([{
            value: "Site Name",
            text: "Site Name",
        },
        {
            value: "Company Name",
            text: "Company Name",
        },
        ]);
        // const options = ref([]);

        const personEmail = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.email || ""
            );
        });
        const postCode = computed(() => {
            return (
                props.opportunityData?.supply_address?.postcode ||
                    props.opportunityData?.postcode ||
                ""
            );
        });
        const contactName = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.first_name + ' ' + props.opportunityData?.persons?.[0]?.last_name || ""
            )
        })
        const companyregNo = computed(() => {
            return (
                props.opportunityData?.organization?.company_reg_no || props.opportunityData?.company_reg_no || ""
            )
        })
        const companyAddress = computed(() => {
            return (
                props.opportunityData?.supply_address?.building_name || props.opportunityData?.address || ""
            )
        })
        const companyAddress2 = computed(() => {
            return (
                props.opportunityData?.supply_address?.street || props.opportunityData?.city || ""
            )
        })
        const companyAddress3 = computed(() => {
            return (
                props.opportunityData?.supply_address?.street || props.opportunityData?.state || ""
            )
        })
        const companyAddress4 = computed(() => {
            return (
                props.opportunityData?.supply_address?.city || props.opportunityData?.address_line_4 || ""
            )
        })
        const companyTelephone = computed(() => {
            return (
                props.opportunityData?.sales_responsible_data?.phone_number || props.opportunityData?.persons?.[0]?.mobile_telephone || ""
            )
        })
        const companyLandline = computed(() => {
            return (
                props.opportunityData?.organization?.telephone || props.opportunityData?.telephone || ""
            )
        })
        const personLandline = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.landline || ""
            )
        })
        const personJobTitle = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.job_title || ""
            )
        })
        const personMobile = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.mobile_telephone || ""
            )
        })
        const personTitle = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.salutation || "Mr"
            )
        })
        const personDirectLine = computed(() => {
            return (
                props.opportunityData?.persons?.[0]?.direct_line || ""
            )
        })
        const selectedContact = ref(props.opportunityData?.sales_contact || "");
        const handleContactChange = async () => {
            try {
                const res = await apiClient.get(
                    `/organisation/viewPerson/${selectedContact.value}`
                );
                const data = res.data.data;
                if (data) {
                    formData.value.MainDetailsData.title = data.salutation;
                    formData.value.MainDetailsData.contact_name =
                        data.first_name + " " + (data.last_name || "");
                    formData.value.MainDetailsData.job_title = data.job_title;
                    formData.value.MainDetailsData.landline = data.direct_telephone;
                    formData.value.MainDetailsData.mobile = data.mobile_telephone;
                    formData.value.MainDetailsData.email = data.email;
                }
            } catch (error) {
                console.error("Error fetching organization data:", error);
            }
        };

        const formData = ref({
            AgentEmail: props.opportunityData?.sales_responsible_data?.email || props.opportunityData?.agent_email || "", // sales responsible email
            service_type: props.opportunityData?.main_production_service || "",
            loa_template: "power",
            MainDetailsData: {
                premises_name: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
                email: personEmail, // person email
                contact_name: contactName, // person name
                company_number: companyregNo, // org Company Reg No:
                post_code: postCode, // opp supply addres post code
                company_landline: companyLandline, // org landline / phone number / mobile
                landline: personLandline, // person landline
                expiry_date: null, // TBD
                reg_no: companyregNo, // org Company Reg No:
                mobile: personMobile, // person mobile
                loa_start_date: null, // loa start date
                loa_end_date: null, // loa end date
                business_type: "LTD", //
                address_1: companyAddress, // opp supply building name
                address_2: companyAddress2, // opp street
                address_3: companyAddress3, // city
                address_4: companyAddress4,
                job_title: personJobTitle, // person job title
                title: personTitle, // person title
                fax: "", // person fax
                direct_line: personDirectLine,
            },
            AuxiliaryDetailsData: {
                BrokerAgentFullName: props.opportunityData?.sales_responsible_data?.first_name + props.opportunityData?.sales_responsible_data?.last_name || props.opportunityData?.responsible_name || "", // sales responsible name
                BrokerAgentEmail: props.opportunityData?.sales_responsible_data?.email || props.opportunityData?.persons?.[0]?.email || "", // sales responsible email
                BrokerAgentPhoneNumber: companyTelephone, // sales phone
                PremisesName: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
                PremisesAddress1: companyAddress, // opp supply add building name
                siteorcompanybusinessnumber: companyregNo,
                PremisesAddress2: companyAddress2, // opp street
                PremisesAddress3: companyAddress3, // opp street
                PremisesAddress4: companyAddress4, // opp city
                PremisesPostcode: postCode, // opp post code
                CompanyName: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
                CompanyAddress1: companyAddress, // opp supply add building name
                CompanyAddress2: companyAddress2, // opp street
                CompanyAddress3: companyAddress3, // opp street
                CompanyAddress4: companyAddress4, // opp city
                CompanyPostcode: postCode, // opp post code
                CompanyNumber: companyregNo, // org Company Reg No:
                siteorcompanyname: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
                siteorcompanyaddress1: companyAddress, // opp supply add building name
                siteorcompanyaddress2: companyAddress2, // opp street
                siteorcompanyaddress3: companyAddress3, // opp street
                siteorcompanyaddress4: companyAddress4, // opp city
                siteorcompanypostcode: postCode, // opp post code
                siteorcompanylandline: "", // person landline
                siteorcompanyemail: "", // person email
                siteorcompanycontactname: "", // person name
                siteorcompanyjobtitle: "", //person job title
                mobile: personMobile, // person mobile
                site_contact_mobile:personMobile
            },
        });
        const schema = Yup.object().shape({
            contact_name: Yup.string().required("Contact name is required"),
            job_title: Yup.string().required("Job title is required"),
            landline: Yup.string().required("Landline number is required"),
            person_email: Yup.string().required("Contact email is required"),
        });
        const mapMainToAuxiliary = (key) => {
            formData.value.AuxiliaryDetailsData.siteorcompanylandline =
                formData.value.MainDetailsData.landline;
            formData.value.AuxiliaryDetailsData.siteorcompanyemail =
                formData.value.MainDetailsData.email;
            formData.value.AuxiliaryDetailsData.siteorcompanycontactname =
                formData.value.MainDetailsData.contact_name;
            formData.value.AuxiliaryDetailsData.siteorcompanyjobtitle =
                formData.value.MainDetailsData.job_title;
            transformData(key);
        };
        const isNotvalidate = ref(false);
        const transformData = async (key) => {
            if (key === 'org') {
                if (!formData.value.MainDetailsData.post_code || !formData.value.MainDetailsData.email) {
                    isNotvalidate.value = true;
                    emit('valid-loa', isNotvalidate.value)
                    return;
                }
            }
            transformedData.value = {
                service_type: formData.value.service_type === 'Gas'?'gas' : 'elec',
                loa_template: formData.value.loa_template,
                AgentEmail: formData.value.AgentEmail,
                docusignDetails: {
                    MainDetailsData: Object.entries(formData.value.MainDetailsData).map(
                        ([key, value]) => {
                            if (key === 'loa_start_date' || key === 'loa_end_date') {
                                return ({
                                    Key: key.replace(/_/g, " "),
                                    Value: changeFormate(value),
                                })
                            } else {
                                return ({
                                    Key: key.replace(/_/g, " "),
                                    Value: value,
                                })
                            }
                        }
                    ),
                    AuxiliaryDetailsData: Object.entries(
                        formData.value.AuxiliaryDetailsData
                    ).map(([key, value]) => ({
                        Key: key,
                        Value: value,
                    })),
                    TemplateOptions: {
                        HalfHourly: false,
                        ChangeOfTenancy: false,
                        PlanType: "",
                    },
                },
            };

            try {
                const response = await apiClient.post(
                    "docusign/sendloa",
                    transformedData.value
                );
                if (response?.data?.response?.SendLOAResult?.Error === null) {
                    toast.success("Letter of Authority has been sent successfully");
                } else {
                    toast.error(response?.data?.response?.SendLOAResult?.Error?.Message)
                }
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        const sendLOA = async (key) => {
            mapMainToAuxiliary(key);
            // try {
            //   const response = await apiClient.post(
            //     "docusign/sendloa",
            //     transformedData.value
            //   );
            //   if (response?.data?.response?.SendLOAResult?.Error === null) {
            //     toast.success("Letter of Authority has been sent successfully");
            //   }
            //   else {
            //     toast.error(response?.data?.response?.SendLOAResult?.Error?.Message)
            //   }
            // } catch (error) {
            //   message.error(error.response.data.message);
            // }
        };

        // LOA
        // const fetchOptions = async () => {
        //   try {
        //     const response = await apiClient.get("settings/organisation-person");
        //     options.value = response.data.data.dropdowns;
        //   } catch (error) {
        //     console.error("Error fetching options data:", error);
        //   }
        // };

        // Assuming form is a reactive object
        const formattedTelephone = computed({
            get() {
                // Ensure form and form.value.telephone are defined
                if (!formData.value || !formData.value.MainDetailsData.mobile) {
                    return ''; // Return an empty string if there's no telephone number
                }

                const number = formData.value.MainDetailsData.mobile.replace(/\D/g, '');

                // Check if the number is valid (e.g., 10 digits)
                if (number.length === 10) {
                    // Format the number
                    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
                } else {
                    // Return the original value if the number is not valid
                    return formData.value.MainDetailsData.mobile;
                }
            },
            set(value) {
                // Clean the input value to store only digits
                const cleanedValue = value.replace(/\D/g, '');

                // If the cleaned value is empty, set form.telephone to an empty string
                formData.value.MainDetailsData.mobile = cleanedValue.length?cleanedValue : '';
            }
        });

        onMounted(async () => {
            // fetchOptions();
            if (selectedContact.value !== "") {
                handleContactChange();
            }
        });

        return {
            schema,
            transformedData,
            mapMainToAuxiliary,
            sendLOA,
            loaTempOptions,
            // options,
            // fetchOptions,
            companyTelephone,
            companyAddress,
            companyAddress2,
            companyAddress3,
            companyAddress4,
            contactName,
            loaHeaderToUse,
            companyregNo,
            companyLandline,
            postCode,
            personEmail,
            personLandline,
            personJobTitle,
            formData,
            isNotvalidate,
            personTitle,
            personDirectLine,
            store,
            route,
            router,
            personMobile,
            handleContactChange,
            selectedContact,
            formattedTelephone,
        };
    },
};
</script>

<style scoped>
.filter-panel {
    position: fixed;
    right: -400px;
    top: 57px;
    width: 290px;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 9999;
    height: 100%;
}

.filter-panel.open {
    right: 0;
}

.filter_btn {
    position: absolute;
    left: -20px;
}

.company-field {
    /* height: 100px; */
    padding: 0 !important;
    margin: 0 !important;
}
</style>
