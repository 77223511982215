<template>
  <a-table
    class="table dataTable no-footer"
    :columns="computedColumns"
    :data-source="data"
    :row-selection="rowSelection"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'product'">
        <p class="text-capitalize">{{ record.product }}</p>
      </template>
      <template v-if="column.key === 'annual_cost'">
        <h6 class="table-avatar d-flex align-items-center">
          {{ record.annual_cost }}
        </h6>
      </template>
    </template>
  </a-table>
</template>

<script>
import { ref, computed, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import apiClient from "@/axios";

export default {
  props: {
    searchOppQuery: String,
    filters: Object,
  },
   data() {
    return {
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchOpportunities = async () => {
      const id = route.query.opp_Id;
      try {
        const response = await apiClient.get(`/opportunity/view-quote-details/${id}`);
        if (response.data.status) {
          data.value = response.data.data.map((quote) => ({
            key: quote.id,
            uplift: quote.uplift,
            term: quote.term,
            supplier_name: quote.supplier_name,
            standing_charge: quote.standing_charge,
            day_unit_rate: quote.day_unit_rate,
            annual_cost: "£" + quote.price,
            night_rate: quote.night_unit_rate,
            weekend_rate: quote.evening_and_weekend,
            product: quote.energy,
            created_at: formatDate(quote.created_at),
          }));
          pagination.value.total = response.data.data.total;
        } else {
          console.error("Failed to fetch opportunities:", response);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      // fetchOpportunities();
    };

    const openEditModal = (id) => {
      router.push({
        path: "/crm/create-organisation",
        query: {
          org_Id: id,
        },
      });
    };

    // Define the base columns array
    const baseColumns = [
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
        sorter: {
          compare: (a, b) => a.product - b.product,
        },
      },
      {
        title: "Unit Rate Uplift",
        dataIndex: "uplift",
        key: "uplift",
        sorter: {
          compare: (a, b) => a.uplift - b.uplift,
        },
      },
      {
        title: "Standing Charge Uplift (pence/day)",
        dataIndex: "standing_charge",
        key: "standing_charge",
        sorter: {
          compare: (a, b) => a.standing_charge - b.standing_charge,
        },
      },
      {
        title: "Supplier Name",
        dataIndex: "supplier_name",
        key: "supplier_name",
        sorter: {
          compare: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
        },
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        sorter: {
          compare: (a, b) => a.created_at.localeCompare(b.created_at),
        },
      },
      {
        title: "Term",
        dataIndex: "term",
        key: "term",
        sorter: {
          compare: (a, b) => a.term - b.term,
        },
      },
      {
        title: "Day Rate (p/kWh)",
        dataIndex: "day_unit_rate",
        key: "day_unit_rate",
        sorter: {
          compare: (a, b) => a.day_unit_rate - b.day_unit_rate,
        },
      },
      {
        title: "Annual cost",
        dataIndex: "annual_cost",
        key: "annual_cost",
        sorter: {
          compare: (a, b) => a.annual_cost - b.annual_cost,
        },
      },
    ];

    const computedColumns = computed(() => {
      // Check if there is any non-null night_rate in data
      const hasNightRate = data.value.some((record) => record.night_rate !== null);
      const hasWeekendRate = data.value.some((record) => record.weekend_rate !== null);

      // Start with the base columns
      const dynamicColumns = [...baseColumns];

      // Conditionally add "Night Rate" column if any record has a night_rate
      if (hasNightRate) {
        dynamicColumns.push({
          title: "Night Rate (p/kWh)",
          dataIndex: "night_rate",
          key: "night_rate",
          sorter: {
            compare: (a, b) => a.night_rate - b.night_rate,
          },
        });
      }

      // Conditionally add "Weekend Rate" column if any record has a weekend_rate
      if (hasWeekendRate) {
        dynamicColumns.push({
          title: "Weekend Rate (p/kWh)",
          dataIndex: "weekend_rate",
          key: "weekend_rate",
          sorter: {
            compare: (a, b) => a.weekend_rate - b.weekend_rate,
          },
        });
      }

      return dynamicColumns;
    });
    // Function to convert date format
    const formatDate = (dateTime) => {
      // Create a Date object
      const date = new Date(dateTime);

      // Extract day, month, year, hours, and minutes
      const day = String(date.getUTCDate()).padStart(2, "0"); // Ensure 2-digit day
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, "0"); // UTC hours
      const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // UTC minutes

      // Return formatted date-time
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    watchEffect(() => {
      fetchOpportunities();
    });

    return {
      data,
      pagination,
      formatDate,
      fetchOpportunities,
      handleTableChange,
      openEditModal,
      computedColumns,
    };
  },
};
</script>
